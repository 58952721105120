
<div class="bills">
  
    <div>
     <div class="px-2">
         <ng-container *ngFor="let item of billsData">
             <div *ngIf="item?.label === 'Sales Invoice'" class="d-flex flex-column my-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column align-items-start">
                     <span class="disabled-font" style="font-size: 8px;">Paid</span>
                     <span class="f-10">{{item.paid.toFixed(authService.session.country.decimal_digit)}}</span>
                  </div>
         
                 <div class="d-flex gap-3">
                  <!-- <div class="d-flex flex-column align-items-end">
                     <span class="disabled-font" style="font-size: 6px;">Paid</span>
                     <span class="f-10">{{item.paid}}</span>
                  </div> -->
                  <div class="d-flex flex-column align-items-end">
                     <span class="disabled-font" style="font-size: 8px;">Total</span>
                     <span class="f-10">{{item.total.toFixed(authService.session.country.decimal_digit)}}</span>
                  </div>
                 </div>
                </div>
                <div class="bar">
                 <div [style.width.%]="item.total?(item.paid / item.total) * 100:0" class="value-indicator"  ></div>
                </div>
             </div>
             </ng-container>
     </div>
    </div>
 </div>
 