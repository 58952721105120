import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logo-loader',
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.css']
})
export class LogoLoaderComponent implements OnInit {
  @Input() state: Boolean;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.state)
  }

}
