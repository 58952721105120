export enum CompanyPartyType {
  Customer = 'Customer',
  Employee = 'employee',
  Labour = 'labour',
  Investor = 'investor',
  MaterialSupplier = 'material supplier',
  LabourContractor = 'labour contractor',
  EquipmentSupplier = 'equipment supplier',
  SubContractor = 'sub contractor',
  Vendor = 'vendor'
}

export enum payroll_level {
  Company = 'company',
  Project = 'project'
}

export enum PayrollType {
  staff = 'staff',
  labour = 'labour',
  labour_contractor = 'labour_contractor'
}

export enum deduction_type {
  deduction = 'deduction',
  preTaxDeduction = 'pre-tax'
}

type PayrollRecord = Record<PayrollType, string>;

export const payrollTypeRecord: PayrollRecord = {
  [PayrollType.staff]: 'Office Staff',
  [PayrollType.labour]: 'Site Staff',
  [PayrollType.labour_contractor]: 'Labour Contractor'
};

export enum ToCreateFromBoqList {
  SALESINVOICE = 'sales_invoice',
  OTHER = 'other'
}

export enum Subcategory_type {
  costCode = 'costcode',
  materialCategory = 'material_category',
  TODO = 'todo'
}

export const SubcategoryTypeRecord: Record<Subcategory_type, { key: string; name: string }> = {
  [Subcategory_type.costCode]: { key: 'costCode', name: 'Cost Code' },
  [Subcategory_type.materialCategory]: { key: 'materialCategory', name: 'Material Category' },
  [Subcategory_type.TODO]: { key: 'TODO', name: 'To Do' }, // Update as needed
};

export const CostComponentTypeAndIcon = {
  material: {
    type: 'material',
    icon: 'featherTruck'
  },
  labour: {
    type: 'labour',
    icon: 'featherUsers'
  },
  fees: {
    type: 'fees',
    icon: 'featherCreditCard'
  }
} as const;

export enum ViewRetentionSource {
  companyLevelParty = 'company_level_party',
  viewSalesInvoice = 'view_sales_invoice',
}

export enum ForReductionDeduction {
  RETENTION = 'retention',
  DEDUCTION = 'deduction'
}



