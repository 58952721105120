import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexGrid,
  ApexTooltip
} from "ng-apexcharts";
import { Project } from 'src/app/interfaces/interfaces';
import { ChartService } from 'src/app/project/service/chart.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  toolTip:ApexTooltip;
};

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() project = {} as Project;

  public chartOptions: Partial<ChartOptions>;
  
  public values = [] as number[];
  public labels = [] as string[];
  public dateLabels = [] as string[];
  constructor(
    private chartService:ChartService,
    private date:DatePipe
  ){
    //
  }

  ngOnInit(): void {
    this.getAttaindanceData();
    
  }

  getAttaindanceData(){
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);

    this.chartService.getAttendanceData(qp).subscribe({
      next:res=>{
        this.dateLabels = [];
        res.data?.map(item=>{
          const shortDay = item.label.slice(0,3);
          const datelable = this.date.transform(item.date, 'd MMM');
          this.labels.push(shortDay);
          this.values.push(item.value_y);
          this.dateLabels.push(datelable);
          
          this.generateChart(); 
        });
      },
      error:err=>{
        console.log(err)
      }
    })
  }

  generateChart(){
    this.chartOptions = {
      series: [
        {
          data: this.values,
        },
      ],
      chart: {
        height: 150,
        type: "bar",
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },

      colors: ['#5BAF33'],
      plotOptions: {
        bar: {
          columnWidth: "55%",
          distributed: true,
        },
      },

      
      toolTip:{
        x:{
          show:false
        },
        y: {
          formatter: function(value, { dataPointIndex, w}) {
            // return  `${w.globals.labels[dataPointIndex]}       ${value}`
            return  `  ${value} Present`
          },
        title: {
           
          formatter: function () {
            return ''
          }
        }
      }
    },
      dataLabels: {
        enabled: true,
        
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },

      yaxis:{
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },

        labels: {
          style: {
            fontSize: "0px",
          },
        },

      },

      xaxis: {
        categories: this.dateLabels,
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    };
  }

}
