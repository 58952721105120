import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Project } from 'src/app/interfaces/interfaces';
import { ChartService } from 'src/app/project/service/chart.service';

interface Bills {
  label:string,
  paid:number,
  total:number
}

@Component({
  selector: 'app-sales-invoice',
  templateUrl: './sales-invoice.component.html',
  styleUrls: ['./sales-invoice.component.css']
})
export class SalesInvoiceComponent {
  @Input() project = {} as Project;
  public billsData = [] as Bills[];

  constructor(
    private chartService:ChartService,
    public authService: AuthService
  ){
    //
  }

  ngOnInit(): void {
    this.getChartBillData();
  }

  getChartBillData(){
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);
   this.chartService.getChartBills(qp).subscribe({
    next:res=>{
      this.billsData = res.data;
    },
    error:err=>{
      console.log(err);
    }
   })
  }

}
