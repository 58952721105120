<div class="w-100 d-flex flex-column gap-2">
    <ng-container *ngFor="let customField of customFieldsList">
        <ng-container *ngIf=" customField.data_type === CustomFieldDataType.TEXT">
            <ng-container *ngTemplateOutlet="text; context: {customField: customField}"></ng-container>
        </ng-container>
        <ng-container *ngIf="customField.data_type === CustomFieldDataType.DATE">
            <ng-container *ngTemplateOutlet="date; context: {customField: customField}"></ng-container>
        </ng-container>
        <ng-container *ngIf="customField.data_type === CustomFieldDataType.NUMBER">
            <ng-container *ngTemplateOutlet="number; context: {customField: customField}"></ng-container>
        </ng-container>

    </ng-container>
</div>

<ng-template #text let-customField="customField">
    <div class="custom-div text-area-wrapper w-100">
        <label for="custom-text-{{customField.id}}">{{customField.name}}</label>
        <textarea rows="3" name="custom-field-text-{{customField.id}}" id="custom-text-{{customField.id}}"
            [(ngModel)]="customField.default_value" class="w-100 f-14 fw-normal"></textarea>
    </div>
</ng-template>

<ng-template #number let-customField="customField">
    <div class="custom-input">
        <label for="custom-number">{{customField.name}}</label>
        <input class="f-14 fw-normal" *ngIf="customField.data_type === 'number'" type="text" tabindex="0"
            [(ngModel)]="customField.default_value" (input)="customField.default_value = validatorService.numberFormat($event, {
          type: 'string',
          decimal: true,
          fieldType:IPOP_FIELD_TYPE.OtherStatic
        })" name="custom-number" id="custom-number">
    </div>
</ng-template>

<ng-template #date let-customField="customField">
    <div (click)="openDatePicker(customField)" class="custom-div">
        <label for="custom-date">{{customField.name}}</label>
        <div class="w-100 d-flex align-items-center gap-2">
            <ng-icon name="featherCalendar" class="d-flex align-items-center disabled-font"></ng-icon>
            <span *ngIf="customField.default_value" class="f-14 fw-normal">{{customField.default_value | date:'dd MMMM
                yyyy'}}</span>
        </div>

        <button *ngIf="customField.default_value" class="btn p-0 icon-btn" type="button" (click)="$event.stopPropagation();clearDate(customField)">
            <ng-icon name="featherX" class="d-flex align-items-center"></ng-icon>
        </button>

    </div>
</ng-template>