import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../../../services/admin.service';
import { IAlert, Project, FileUploadOptions } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  @Input() data: Project;
  public alerts: Array<IAlert> = [];
  public tempTags;
  public tempImg;
  public tempCats;
  public loading = false;
  constructor(
    public activeModal: NgbActiveModal,
    private adminService: AdminService,
    public utilService: UtilService
  ) { }
  ngOnInit() {
    // this.tempTags = this.data.tags ? this.data.tags.join(',') : '';
    this.data.photos = this.data.photos ? this.data.photos : [];
  }
  replaceImg(event, model) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      this.loading = true;
      const fileName = files[0].name;
      const fileType = files[0].type;
      const extn = fileName.substring(fileName.lastIndexOf('.'));
      const fileUploadOptions = {
        files: files,
        contentType: fileType,
        extension: extn,
        folder: 'project_bg',
        project_id: this.data.id
      } as FileUploadOptions;
      this.utilService.s3attachAnyFIle(fileUploadOptions).subscribe(res => {
        this.loading = false;
        model.featured_image = res.url;
      }, err => {
        this.loading = false;
      });
    }
  }
  add() {
    this.adminService.addProject(this.data).subscribe(res => {
      this.activeModal.close(res);
    }, err => {
      this.loading = false;
    });
  }
  edit() {
    this.adminService.editProject(this.data).subscribe(res => {
      this.activeModal.close(res);
    }, err => {
      this.loading = false;
    });
  }
  save() {
    if (this.data.name) {
      this.loading = true;
      if (this.data.id) {
        this.edit();
      } else {
        this.add();
      }
    } else {
      alert('Check name!');
    }
  }
  dismiss(str) {
    this.activeModal.dismiss('Hi');
  }
  close(str) {
    this.activeModal.close({ data: str });
  }
  closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

}
