import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {
  DownloadJSResolverService, InputScroll,
  ZohoChat
} from './core/services/resolvers.service';
import { SupervisorGuard, LoginGuard } from './core/services/loginguard.service';
import { ZohoRedirectUrlComponent } from './components/other-components/zoho-redirect-url.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'login',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard]},
  { path: 'register', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'reset', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
  { path: 'profile',  loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    resolve: [DownloadJSResolverService], canActivate: [SupervisorGuard]},
  { path: 'admin',  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    resolve: [DownloadJSResolverService],
   canActivate: [SupervisorGuard]},
   {
    path: 'book-demo',
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule),
    data: {
      queryParams: ['token', 'company_id']
    }
  },
  { path: 'c/:id',  loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), canActivate: [SupervisorGuard], resolve:[InputScroll, ZohoChat]},
  {path:'upgrade-plan', loadChildren:()=>import('./upgrade-plan/upgrade-plan.module').then(m=>m.UpgradePlanModule), canActivate:[SupervisorGuard]},
  { path: 'zoho-callback',  component: ZohoRedirectUrlComponent, pathMatch: 'full'},
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled'
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
