import { Component, Input, OnInit, Output } from '@angular/core';
import { FileUploadOptions } from 'src/app/interfaces/interfaces';
import { UtilService } from '../../services/util.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageSliderModalComponent } from '../modal/image-slider-modal/image-slider-modal.component';
interface FileUrl {
  url?: string;
  type?: string;
  is_old?: boolean;
  name?: string;
}
interface Parent {
  viewOnly?: boolean;
}
@Component({
  selector: 'app-attachment-view',
  templateUrl: './attachment-view.component.html',
  styleUrls: ['./attachment-view.component.css']
})
export class AttachmentViewComponent implements OnInit {
  @Input() parent = {} as Parent;
  @Input() hasTitle: string;
  @Input() folderName: string;
  @Input() uniqueComponentReferenceId = "";
  @Input() accept: string;
  @Input() id: string;
  @Output() child = {};
  public fileString = [];
  public fileListArray: any[] = [];
  public uniqueId:string;
  public uniqueIdSm:string;

  fileListUrls = [] as FileUrl[];
  imagesUrlArr = [] as string[];
  imageCount = 0;
  fileCount = 0;

  public loading = false;
  public formType: 'edit' | 'view';
  constructor(
    private utilService: UtilService,
    private ngxImageCompressService: NgxImageCompressService,
    private ngbModal: NgbModal,
  ) {
    //
  }

  ngOnInit(): void {
    this.uniqueId = 'file'+this.id;
    this.uniqueIdSm = 'fileSm'+this.id;
  }

  getFiles(uri: string[]) {
    if(uri){
      for (const url of uri) {
        const temp = {} as FileUrl;
        temp.is_old = true;
        temp.type = url.endsWith('pdf') ? 'pdf' : 'img';
        temp.url = url;
        this.imagesUrlArr.push(url);
        this.fileListUrls.push(temp);
        this.fileCounter();
      }
    }
  }
  ngAfterViewInit(): void {
    // this.loading = false;
  }
  navigateToPdf(link: string) {
    window.open(link, '_blank');
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  filesFromChild() {
    const uris = this.fileListUrls.map(i => i.url) || []
    return uris;
  }

  onFileSelected(e) {
    const files: FileList = e.target.files;
    for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ngxImageCompressService.compressFile(reader.result.toString(), -1, 50, 50).then((res) => {
          const compressedBlob = this.dataURItoBlob(res);
          const newFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
          const fileUploadOptions = {
            files: [newFile],
            contentType: file.type,
            extension: file.name.substring(file.name.lastIndexOf('.')),
            folder: this.folderName || 'bills'
          } as FileUploadOptions;
          this.uploadToBucket(fileUploadOptions);
        }, (rej) => {
          //
        })
      }
    }else if (file.type.startsWith('application/')) {
      let extension, contentType;
      if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
        extension = '.pdf';
        contentType = 'application/pdf';
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.XLSX')) {
        extension = '.xlsx';
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (file.name.endsWith('.docx') || file.name.endsWith('.DOCX')) {
        extension = '.docx';
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      const fileUploadOptions = {
        files: [file],
        contentType,
        extension,
        folder: this.folderName || 'bills'
      } as FileUploadOptions;
      this.uploadToBucket(fileUploadOptions);
    }else if (file.name.endsWith('.DWG') || file.name.endsWith('.dwg')) {
      const fileUploadOptions = {
        files: [file],
        contentType: 'image/x-dwg',
        extension: '.DWG',
        folder: this.folderName || 'cad'
      } as FileUploadOptions;
      this.uploadToBucket(fileUploadOptions);
    }
    }
    // this.uploadImages();
    e.target.value = '';
  }

  removeFile(index: number) {
    this.fileListUrls.splice(index, 1);
    this.fileCounter()
  }


  uploadToBucket(file: FileUploadOptions) {
    this.utilService.s3attachAnyFIle(file).subscribe({
      next: (res) => {
        const fileTemp = {} as FileUrl;
        fileTemp.is_old = false;
        fileTemp.type = file.extension.split('.').pop();
        fileTemp.name = file.files[0].name;
        fileTemp.url = res.url;
        this.fileListUrls.push(fileTemp);
        this.fileCounter();
      }
    })
  }

  fileCounter() {
    this.fileCount = 0;
    this.imageCount = 0;
    this.fileListUrls.map(file => {
      if (file.url.endsWith('pdf')) {
        this.fileCount++
      } else {
        this.imageCount++
      }
    })
  }

  openFile(firstIndex?: number) {
    if (this.fileListUrls[firstIndex].url.endsWith('pdf')) {
      this.navigateToPdf(this.fileListUrls[firstIndex].url)
    } else {

      const imgStrs = [];
      this.fileListUrls.forEach(i => imgStrs.push(i.url))
      const sliderModal = this.ngbModal.open(ImageSliderModalComponent,{fullscreen:true, backdropClass:'dark-backdrop', modalDialogClass:'image-modal-dialog'});
      sliderModal.componentInstance.parent = {
        imagesArray:imgStrs,
        imageIndex:firstIndex
      };
    }
  }
  fileExtensionExtract(fileName){
    const srcPath = '../../../../assets/svg/';
    if(this.utilService.fileExtensionExtract(fileName).isImage){
      return this.utilService.fileExtensionExtract(fileName).file;
    }else{
      return srcPath+this.utilService.fileExtensionExtract(fileName).file
    }
  }
}
