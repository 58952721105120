import { HttpParams } from '@angular/common/http';
import { Component,Input } from '@angular/core';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
import { DeductionEntry, DeductionItem, SalesInvoiceDetails } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';
interface Parent {
  salesInvoiceId?: string;
  feature_id?: string;
  pre_tax?: number;
  featureTitle?: string;
  prefix?: string;
  sequence?: number;
}
@Component({
  selector: "app-view-deduction",
  templateUrl: "./view-deduction.component.html",
  styleUrls: ["./view-deduction.component.css"],
})
export class ViewDeductionComponent {
  @Input() parent = {} as Parent;
  public deductionEntryList = [] as DeductionEntry[];
  public salesOrderInvoice = {} as SalesInvoiceDetails;
  public busy = false;
  public valueStack = { page: { next_url: "" } };
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    if (this.parent.salesInvoiceId) {
      this.getSalesInvoiceDeductionList();
    } else {
      this.getGenericDeductionList();
    }
  }
  getSalesInvoiceDeductionList() {
    let qp = new HttpParams();
    qp = qp.set("salesorder_invoice_id", this.parent.salesInvoiceId);
    qp = qp.set("company_id", this.authService.session.company.id);
    this.adminService.deductionEntryList(qp).subscribe({
      next: (res: any) => {
        this.valueStack = res;
        this.deductionEntryList = res.data || ([] as DeductionEntry[]);
      },
      error: (err) => {
        //
      },
    });
  }
  getGenericDeductionList() {
    let qp = new HttpParams();
    qp = qp.set("feature_id", this.parent.feature_id);
    qp = qp.set("company_id", this.authService.session.company.id);
    this.adminService.genericListDeductionEntries(qp).subscribe({
      next: (res: any) => {
        this.valueStack = res;
        this.deductionEntryList = res.data || ([] as DeductionEntry[]);
      },
      error: (err) => {
        //
      },
    });
  }

  onScroll(): void {
    console.log(this.valueStack, "test");
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe({
        next: (res) => {
          this.valueStack = res;
          this.deductionEntryList = this.deductionEntryList.concat(res.data);
          // this.updateDeductionListWithOldItems(res.deductionItems);
          // this.deductionList = this.deductionList.concat(res.deductionItems);
          this.busy = false;
        },
        error: () => {
          this.busy = false;
        },
      });
    }
  }
}
