<div class="canvas-height">
  <app-logo-loader [state]="loading"></app-logo-loader>
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <div class="pointer m-0 p-0">
        <app-back-button></app-back-button>
      </div>
      <div class="canvas-title">
        <span>Select Cost Code</span>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="canvas-cancel-btn" (click)="ngbActiveOffcanvas.dismiss()">
        Cancel
      </div>
      <div class="canvas-save-btn" (click)="saveCostCode()">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div style="padding-top:30px; height: calc(100% - 71px); overflow-y: auto" class="d-flex flex-column gap-3">

    <div style="margin-inline: 32px;" class="custom-material-search-input hstack align-items-center gap-1">
      <span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M20.9999 20.9984L16.6499 16.6484" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <input autocomplete="off" type="text"
      class="search_input"
      [(ngModel)]="tradeSearchString"
      [ngbTypeahead]="tradeSearchFn"
      placeholder="Search Cost Code" />
    </div>

    <!-- add new cost code -->
    <div style="padding-inline: 32px;" class="d-flex justify-content-end" *ngIf="authService.hasAccess(authService.companyUserPolicy.Add_new_trade)">
      <span class="pointer" *ngIf="parent.is_add_new" (click)="addNewCostCodeCanvas()">+ New Cost Code</span>
    </div>

    <!-- list of cost code -->

      <div style="height: calc(100% - 128px ); overflow-y: auto; scroll-behavior: smooth;"  infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
      (scrolled)="onScroll()" [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page.next_url == ''">
      <!-- <div (scroll)="valueStack.page.next_url ? onScroll($event) : ''" class="tree-container"> -->

        <ul class="tree">
          <ng-container *ngFor="let node of costCodeNodes, index as i">
             <li *ngIf="node.children">
              <div [ngClass]="costCodeNode.id==node.id?'cost-code-active':''"  class="pointer d-flex gap-3" (click)="setCostCode(node.id,node.name )">
                <span class="f-14" style="white-space: nowrap">{{i+1}}. {{node.name}}</span>
              </div>
              <ng-container *ngIf="node.children.length>0 && parent.height > 0">
                <ul>
                  <li style="padding-right: 0px; padding-bottom: 0px;" *ngFor="let item of node.children, index as j">
                    <div [ngClass]="costCodeNode.id==item.id?'cost-code-active':''"  class="pointer d-flex gap-3" (click)="setCostCode(item.id,item.text_en )">
                      <span class="f-14" style="white-space: nowrap">{{item?.text_en}}</span>
                    </div>
                  </li>
                </ul>
              </ng-container>
             </li>

             <li class="no-bullets" *ngIf="!node.children">
              <div [ngClass]="costCodeNode.id==node.id?'cost-code-active':''"  class="pointer d-flex gap-3" (click)="setCostCode(node.id,node.name )">
                <span class="f-14" style="white-space: nowrap">{{i+1}}. {{node.name}}</span>
              </div>
             </li>
          </ng-container>
        </ul>
      </div>
  </div>
</div>
