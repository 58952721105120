<div class="p-4 d-flex flex-column gap-3">
  <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
    <span class="f-16 fw-bold">Set Project Location</span>
    <div class="d-flex gap-2 align-items-center">
      <button (click)="ngbActiveModal.dismiss()" class="project-btn cancel">Cancel</button>
      <button (click)="save()" class="project-btn save">Save</button>
    </div>
  </div>
  <div class="d-flex align-items-center gap-3">
    <div style="position: relative; width: 50%;">
      <div class="custom-input f-14">
        <input
          [(ngModel)]="searchLocation"
          [ngbTypeahead]="tradeSearchFn"
          #searchInput
          id="search-location"
          style="border: none;"
          type="text"
          placeholder="Search Address"
          class="form-control"
        />
      </div>
      <div *ngIf="suggestions?.length > 0" class="autocomplete-suggestions">
        <div class="list-item f-14" *ngFor="let suggestion of suggestions" (click)="onSelectSuggestion(suggestion)">
          {{ suggestion.description }}
        </div>
      </div>
    </div>
    <div class="custom-input f-14 w-50">
      <input
        [(ngModel)]="distance"
        (ngModelChange)="updateCircleRadius()"
        #distanceInput
        type="number"
        placeholder="Enter Distance (m)"
        class="form-control"
      />
      <label>Distance (m)</label>
    </div>
  </div>
</div>

<!-- Map and Loading Spinner -->
<div class="position-relative">
  <app-logo-loader [state]="isLoading"></app-logo-loader>
  <div id="map" style="height: 400px;"></div>
</div>
