import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          visibility: 'hidden',
          left: '-400px',
        })
      ),
      state(
        'open',
        style({
          left: '280px',
        })
      ),
      transition('open <=> closed', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class ToastsComponent implements OnInit {
  notificationStatus = false;
  notificationType= "";
  notificationTitle = "";
  notificationSub = "";


  constructor(public notificationService : NotificationsService){

    this.notificationService.open.subscribe((data)=>{
      if(data.show){
        this.notificationStatus = data.show
        this.notificationTitle = data.title
        this.notificationSub = data.content
        this.notificationType = data.type.toString();
        this.countdown()
      }
     })

  }

  ngOnInit(): void {
  //
  }


  countdown(){
    setTimeout(()=>{
      this.notificationService.hide();
      this.notificationStatus = this.notificationService.data.show
    }, 3000)
  }









}
