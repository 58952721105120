import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  set(k:string, v:any) {
    v = JSON.stringify(v);
    localStorage.setItem(k, v);
    return;
  }

  get(k:string) {
    const v = localStorage.getItem(k);
    return JSON.parse(v);
  }

  remove(k?: string) {
    localStorage.removeItem(k);
    return;
  }

  clearAll() {
    localStorage.clear();
    return;
  }
}
