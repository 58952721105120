import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(fullName: string): any {
    if(!fullName) return
    fullName = fullName.trim()
    const stringArr = fullName.split(' ');
    if (stringArr.length > 2) {
      stringArr.length = 2;
    }
    const output = stringArr.map(m => m[0]);
    return output.join('');
  }

}
