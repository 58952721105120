/**
 * Created by tarun on 14/7/17.
 */
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from '../../config/config.config';

import {CompanyFileUploadOptions, FileUploadOptions} from 'src/app/interfaces/interfaces';
import { LocalStorageService } from './local-storage-service.service';
import { Router } from '@angular/router';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { AdminService } from 'src/app/core/services/admin.service';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public digitCount = 2;
  public editorConfig = {} as any;
  public editorConfigInline = {} as any;
  public editorConfigHindi = {} as any;

  private partyCompanyUserId = new BehaviorSubject('');
  public partyIdObservable = this.partyCompanyUserId.asObservable();

  private projectId = new BehaviorSubject('');
  public projectIdObservable = this.projectId.asObservable();

  private taskId = new BehaviorSubject('');
  public taskIdObservable = this.taskId.asObservable();

  public memberCount = new BehaviorSubject(0);
  public memberCountObservable = this.memberCount.asObservable();

  constructor(
    private http: HttpClient,
    private config: Config,
    private ngbModal: NgbModal,
    private localStorageService: LocalStorageService,
    private router: Router,
    private notificationService: NotificationsService,
    private rendererFactory: RendererFactory2,
    private adminService: AdminService
  ) {
    this.digitCount = localStorageService.get('country')?.decimal_digit || 2;
  }

  setThemeVariable(cssVariableName: string, cssVariableValue: string) {
    const renderer = this.rendererFactory.createRenderer(null, null);
    const root = document.documentElement;
    renderer.setStyle(root, `--${cssVariableName}`, cssVariableValue);
  }

  getByUrl(url): Observable<any> {
    return this.http.get(
      url,
      {}
    );
  }

  s3attachAnyFIle(fileUploadOptions: FileUploadOptions): Observable<any> {
    const fileList: FileList = fileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', fileUploadOptions.contentType);
    formData.append('folder', fileUploadOptions.folder);
    formData.append('extension', fileUploadOptions.extension);
    formData.append('project_id', fileUploadOptions.project_id);
    formData.append('task_id', fileUploadOptions.task_id);
    formData.append('expense_id', fileUploadOptions.expense_id);
    formData.append('material_id', fileUploadOptions.material_id);
    formData.append('drawing_id', fileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload`,
      formData,
      {}
    );
  }

  s3attachCompanyFIle(companyFileUploadOptions: CompanyFileUploadOptions): Observable<any> {
    const fileList: FileList = companyFileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', companyFileUploadOptions.contentType);
    formData.append('parent_dir', companyFileUploadOptions.parent_dir);
    formData.append('name', companyFileUploadOptions.name)
    formData.append('extension', companyFileUploadOptions.extension);
    formData.append('project_id', companyFileUploadOptions.project_id);
    formData.append('task_id', companyFileUploadOptions.task_id);
    formData.append('expense_id', companyFileUploadOptions.expense_id);
    formData.append('material_id', companyFileUploadOptions.material_id);
    formData.append('drawing_id', companyFileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload/companyfile`,
      formData,
      {}
    );
  }

  sendPartyId(partyCompanyUserId: string) {
    this.partyCompanyUserId.next(partyCompanyUserId);
  }

  sendProjectId(projectId: string) {
    this.projectId.next(projectId);
  }

  sendTaskId(taskId: string) {
    this.taskId.next(taskId);
  }

  public fileExtension = new Map([
    ['DWG','dwg.svg'],
    ['dwg','dwg.svg'],
    ['pdf','pdf.svg'],
    ['PDF','pdf.svg'],
    ['docx','docx.svg'],
    ['DOCX','docx.svg'],
    ['xlsx','xlsx.svg'],
    ['XLSX','xlsx.svg'],
  ])

  fileExtensionExtract(fileName:string):{file:string, isImage:boolean}{
   const nameArr = fileName.split('.') || [];
    const extn = nameArr[nameArr.length-1];
    if(this.fileExtension.has(extn)){
      return {file:this.fileExtension.get(extn), isImage:false}
    }else{
      return {file:fileName, isImage:true}
    }
  }

  getGstPercentage() {
    return new Promise((resolve, reject) => {
    try {
      const country = this.localStorageService.get('country');
      const taxSlabArray = country?.tax_slabs || [];
      if (Array.isArray(taxSlabArray)) {
        resolve(taxSlabArray);
      } else {
        reject(new Error('Data in localStorage is not an array.'));
      }
    } catch (error) {
      reject(error);
    }
    });
  }

  shouldSplitGst(gst_A: string | null | undefined, gst_B: string | null | undefined): boolean {
    if (!gst_A || !gst_B) return true; // Return false for null, undefined, or empty string
    const str1Lower = gst_A.toLowerCase();
    const str2Lower = gst_B.toLowerCase();
    if(str1Lower.substring(0, 2) == str2Lower.substring(0, 2)){
      return true;
    }else{
      return false;
    }
  }
  
  getGstStatus(owner_id_A: string, owner_id_B: string): Observable<boolean> {
      return forkJoin({
        address_A: this.adminService.getPrimaryAddressByOwnerId(owner_id_A).pipe(catchError(() => of(null))),
        address_B: this.adminService.getPrimaryAddressByOwnerId(owner_id_B).pipe(catchError(() => of(null)))
      }).pipe(
        map(({ address_A, address_B }) => {
          if (!address_A?.address_gst || !address_B?.address_gst) {
            return true; // Return true if either address is missing or has no GST // default view is split gst
          }
          return this.shouldSplitGst(address_A.address_gst, address_B.address_gst);
        }),
        catchError(() => of(true)) // Handle any unexpected errors gracefully
      );
    }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.notificationService.initiate({ title: 'Copied to clipboard!', type: toastTypes.success });
      console.log('Copied to clipboard!');
    }).catch(err => {
      this.notificationService.initiate({ title: 'Failed to copy!', type: toastTypes.error });
    });
  }

  addCdnInDomain(domain: string): string {
    if (domain.includes('digitaloceanspaces.com')) {
        return domain.replace('digitaloceanspaces.com', 'cdn.digitaloceanspaces.com');
    }
    return domain; // Return the original if no change needed
  }
}