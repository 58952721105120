import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyUserTypeDetails } from 'src/app/enum/company_user_type';
import { Project } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-duplicate-project-modal',
  templateUrl: './duplicate-project-modal.component.html',
  styleUrls: ['./duplicate-project-modal.component.css']
})
export class DuplicateProjectModalComponent implements OnInit {
  @Input() parent = {} as Project;
  public newProject = {} as Project;
  CompanyUserTypeDetails = CompanyUserTypeDetails
  constructor(
    public ngbActiveModal:NgbActiveModal,
    public adminService: AdminService,
    public router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.newProject.name = 'copy of '+this.parent.name;
  }

  save(){
    this.adminService.duplicateProject({dest_project_name:this.newProject.name, src_project_id: this.parent.id}).subscribe(res => {
      this.router.navigate([`/../company/project/${res.id}/${this.authService.getInitialTab()}`]);
      this.ngbActiveModal.close()
    })
  }
}
