<div class="">
  <div class="modal-body pricing">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="float-right btn btn-link pricing__close" aria-label="Close" (click)="dismiss()">
          <span class="icon icon-close"></span>
        </button>
      </div>
      <!--<div class="col-md-2 text-center"></div>-->
      <div class="col-md-12 text-center">
        <div class="embed-responsive embed-responsive-16by9">
          <div id="player"></div>
        </div>
      </div>
      <!--<div class="col-md-2 text-center"></div>-->
      <!--<div class="col-md-12">-->
        <!--<div class="pricing__body">-->
          <!--<div class="row align-items-center">-->
            <!--<div class="col-md-4">-->
              <!--<button class="btn btn-block btn-primary" (click)="play()">Play</button>-->
            <!--</div>-->
            <!--<div class="col-md-4">-->
              <!--<button class="btn btn-block btn-primary" (click)="pause()">Pause</button>-->
            <!--</div>-->
            <!--<div class="col-md-4">-->
              <!--<button class="btn btn-block btn-primary" (click)="goToTime(30)">GoTo 30 sec</button>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </div>
</div>
