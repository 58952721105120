import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { LocalStorageModule } from 'angular-2-local-storage';
import { DatePipe } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { NgProgressModule } from '@ngx-progressbar/core';
// import { NgProgressRouterModule } from '@ngx-progressbar/router';

import { Config } from '../config/config.config';
import { MyInterceptor } from '../interceptors/MyInterceptor';
import { AuthService } from './services/auth.service';
import { LoginGuard, AdminGuard, SupervisorGuard } from './services/loginguard.service';
import { ProfileResolverService, ImageResolverService, TawkToResolverService, RazorpayResolverService,
  JQueryResolverService,  DownloadJSResolverService, PDFJSResolverService, LoginStatus, CompanyResolverService, InputScroll,
 } from '../core/services/resolvers.service';

import { PaidCompanyGuard } from './services/paidcompanyguard.service';
import { NotificationsService } from './services/notifications.service';
import { ResponsiveService } from './services/responsive.service';

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider('278257299223-9gdra1riqfdp3r2a5f13eejnio7jpgtp.apps.googleusercontent.com')
//   }
// ]);

// export function provideConfig() {
//   return config;
// }

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        HttpClientModule,
        // LocalStorageModule.forRoot({
        //     prefix: 'ls',
        //     storageType: 'localStorage'
        // }),
        // NgProgressModule.withConfig({
        //     /*spinnerPosition: 'left',*/
        //     color: '#ff4d00',
        //     thick: true
        // }),
        // NgProgressRouterModule.withConfig({}),
        // SocialLoginModule,
        InfiniteScrollModule
    ],

    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS,
            useClass: MyInterceptor,
            multi: true
        },
        Config,
        AuthService,
        NotificationsService,
        LoginStatus,
        LoginGuard,
        PaidCompanyGuard,
        AdminGuard,
        SupervisorGuard,
        ProfileResolverService,
        ImageResolverService,
        TawkToResolverService,
        RazorpayResolverService,
        JQueryResolverService,
        DownloadJSResolverService,
        ResponsiveService,
        CompanyResolverService,
        InputScroll,
        PDFJSResolverService,
        // {
        //     provide: AuthServiceConfig,
        //     useFactory: provideConfig
        // }
    ],
    exports: [
        RouterModule,
        HttpClientModule,
        // NgProgressModule,
        // NgProgressRouterModule,
    ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
