import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent implements OnInit {
  public scrollBtnShow = false;
  constructor() { }

  ngOnInit() {
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset < 500) {
      this.scrollBtnShow = false;
    } else {
      this.scrollBtnShow = true;
    }
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
