import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare const pdfjsLib;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit, AfterViewInit {
  @Input() public config: any;
  public pdfDoc = null;
  public pageNum = 1;
  public pageRendering = false;
  public pageNumPending = null;
  public scale = window.innerWidth < 768 ? 0.4 : .8;
  public showControl = 0;
  @ViewChild('myCanvas', { static: true }) canvasEleemtView: ElementRef;
  public canvas: HTMLCanvasElement;
  public ctx: CanvasRenderingContext2D;
  public totalPageCount;
  public currentPageNo;
  public loading = false;
  constructor() {
    //
   }

  ngOnInit() {
    this.scale = this.config.scale || this.scale;
    this.showControl = this.config.showControl || 0;
  }
  ngAfterViewInit() {
    this.canvas = this.canvasEleemtView.nativeElement as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.loading = true;
    pdfjsLib.getDocument(this.config.url).promise.then( pdfDoc => {
      this.loading = false;
      this.pdfDoc = pdfDoc;
      this.totalPageCount = this.pdfDoc.numPages;

      // Initial/first page rendering
      this.renderPage(this.pageNum);
    });
  }
  renderPage(num) {
    this.pageRendering = true;
    // Using promise to fetch the page
    this.pdfDoc.getPage(num).then((page) => {
      const viewport = page.getViewport({scale: this.scale});
      this.canvas.height = viewport.height;
      this.canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: this.ctx,
        viewport
      };
      const renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          // New page rendering is pending
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      });
    });
    this.currentPageNo = num;
  }

  queueRenderPage(num) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.queueRenderPage(this.pageNum);
  }

  onNextPage() {
    if (this.pageNum >= this.pdfDoc.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }

}
