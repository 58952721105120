<div style="padding: 30px 40px 0px 40px;" class="d-flex flex-column">
  <div class="d-flex" style="margin-bottom:40px;">
    <div>
      <span class="heading">
        Duplicate Project
      </span>
      <br>
      <!-- <span class="sub-heading">
          Duplicating this project will make a copy of the project in its current team.
        </span> -->
    </div>
    <div class="ms-auto">
      <svg class="pointer" (click)="ngbActiveModal.dismiss()" width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
  <div>
    <div class="custom-input " style="width: 270px;">
      <label>New Project Name</label>
      <input type="text" autocomplete="off" [(ngModel)]="newProject.name" />
    </div>
  </div>
  <div class="mt-3">
    <div>This action include</div>
    <div class="sub-heading">
      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.50305" cy="4" r="4" fill="#248B00"/>
      </svg> Labour Force <br>

      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.50305" cy="4" r="4" fill="#248B00"/>
      </svg> Team <br>

      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.50305" cy="4" r="4" fill="#248B00" />
      </svg> Task
    </div>
  </div>
  <div class=" d-flex justify-content-between" style="margin: 30px 0px !important;">
    <div class="ms-auto align-self-center">
      <span class="project-btn save" (click)="save()">Save</span>
    </div>
  </div>
</div>
