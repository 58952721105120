<div class="modal-header d-flex align-items-center">
    <div class="modal-header-left d-flex align-items-center gap-4">
        <div class="modal-back-arrow-btn">
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 12.5L0.5 6.5L6.5 0.5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <div class="modal-title">
            <!-- <span>Company Name</span><br> -->
            <span style="font-weight: 700;font-size: 14px;line-height: 21px;">Add material</span>
        </div>
    </div>

    <div class="d-flex gap-4 ">
        <div (click)="close()" class="modal-cancel-btn">Cancel</div>
        <div (click)="save()" class="modal-save-btn">Save</div>
    </div>

</div>


<div class="modal-body">

    <div class="custom-input m-4">
        <label>Material Name</label>
        <input type="text" name="partyName"  [(ngModel)]="materialItem.name" autocomplete="off" />
    </div>

    <div class="custom-input m-4">
        <label>Unit</label>
        <select name="" id="" (change)="setMaterialUnit($event)">
          <option class="select-type" value=''>Select Unit</option>

            <ng-container *ngFor="let cat of categories">
                <option class="select-type" value={{cat.id}}>{{cat.text_en}}</option>
            </ng-container>
        </select>

    </div>

    <!-- <div class="custom-input m-4">
        <label>Trade</label>
        <select name="" id="" [(ngModel)]="materialItem.sub_category_id">
            <ng-container *ngFor="let subcat of subCategories">
                <option class="select-type" value="{{subcat.id}}">{{subcat.text_en}}</option>
            </ng-container>

        </select>

    </div> -->
    <div class="m-4">
      <app-trade-input [parentComponent]="{hasTradeId:null, src:'modal'}" (onSubCategorySelect)="setSubcategory($event)" #companySubCategory></app-trade-input>
    </div>
</div>

<div class="modal-footer"></div>
