import { createReducer, on } from '@ngrx/store';
import { setCompany, reset } from './purchase-order.actions';
import { initialState } from './purchase-order.state';
const _counterReducer = createReducer(
  initialState,
  on(setCompany, (state) => {
    return {
        company: localStorage.getItem('company')
    };
  }),
  on(reset, (state) => {
    return {
        company: {}
    };
  })
);

export function counterReducer(state, action){
    return _counterReducer(state,action)
}