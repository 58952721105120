import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Project } from 'src/app/interfaces/interfaces';
import { ChartService } from 'src/app/project/service/chart.service';

interface ChartDataList{
  label?:string,
  color?:string,
  value?:number
}

@Component({
  selector: 'app-category-expense-list',
  templateUrl: './category-expense-list.component.html',
  styleUrls: ['./category-expense-list.component.css']
})
export class CategoryExpenseListComponent implements OnInit {

  public colors = [] as string[];
  private project = {} as Project;
  public chartDataList = [] as ChartDataList[];


  constructor(
    private chartService:ChartService,
    private authService:AuthService
  ){
    this.project = this.authService.session.project;
  }
  ngOnInit(): void {
    this.getExpenseData();
  }

  getExpenseData() {
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);
  
    this.chartService.getExpenseData(qp).subscribe({
      next: (res) => {
        // Clear existing data in chartDataList before populating with new data
        this.chartDataList = [];
  
        res.data?.forEach(item => {
          // Push individual items to chartDataList
          this.chartDataList.push({
            label: item?.label,
            value: item?.value_y
          });
        });
  
  
        // Extract labels and generate colors outside the loop for efficiency
        const arrayOfLabels = this.chartDataList.map(obj => obj.label);
        this.generateColor(arrayOfLabels);
      },
      error: (err) => {
        console.log('Error:', err);
      }
    });
  }
  

  generateColor(labels:string[]){
    this.chartService.generateColors(labels).subscribe({
      next:res=>{
        this.colors = res as string[];
        this.colors.map((obj, index)=>{
          this.chartDataList[index] = {...this.chartDataList[index], color:obj}
        })
        
      },
      error:err=>{
        console.log(err);
      }
    })
  }



  

}
