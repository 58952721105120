import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, args: string): string {
    const limit = args ? parseInt(args, 10) : 30;
    const trail = '...';
    if (value) {
      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    return null;
  }

}
