import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import {BankAccount, Company, CompanyBank } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';

interface Parent {
  hasRequestType: 'add'|'edit',
  hasCompanyBank: CompanyBank
}

@Component({
  selector: 'app-add-new-account',
  templateUrl: './add-new-account.component.html',
  styleUrls: ['./add-new-account.component.css']
})
export class AddNewAccountComponent implements OnInit {
  @Input() parent = {} as Parent;
  public bankDetails = {} as BankAccount;
  public companyBank = {} as CompanyBank;
  public company = {} as Company;
  public bankLogoUrl:string;

  constructor(
    private companyService: CompanyService,
    public authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private notificationsService: NotificationsService
  ) {
  }
  ngOnInit(): void {
    this.company = this.authService.session.company;
    if (this.parent.hasRequestType === 'edit') {
      this.companyBank = this.parent.hasCompanyBank ? JSON.parse(JSON.stringify(this.parent.hasCompanyBank)) : {} as CompanyBank;
      this.bankLogoUrl = this.getBankUrl(this.companyBank.monkey_patch_bank_account.bank_code);
    }else{
      this.companyBank = {} as CompanyBank;
      this.companyBank.monkey_patch_bank_account = {} as BankAccount;
      this.companyBank.monkey_patch_bank_account.upi_ids = [''];
    }
  }

  getBankUrl(bank_code:string):string{
    let bancCode= this.getBankCode(bank_code);
    return `https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/${bancCode.toUpperCase()}_LOGO.jpg`
  }

  getBankCode(bank_code: String): String {
    var bankCode = ""
    if (bank_code.length > 4) {
        bankCode = bank_code.substring(0, 4)
    }
    return bankCode
  }

  onValidateBankcode(e) {
    const regexIfsc = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    const ifscCode = regexIfsc.test(e);
    if (ifscCode) this.getDetailsFromIfsc(e);
    if(e.length < 4) return;
    let bankCode = '';
    bankCode = e.slice(0,4);
    this.bankLogoUrl = `https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/${bankCode.toUpperCase()}_LOGO.jpg`;
  }

  getDetailsFromIfsc(ifsc: string) {
    this.companyService.getBankDetails(ifsc).subscribe({
      next: (res) => {
        this.companyBank.monkey_patch_bank_account.bank_name = res.BANK;
        this.companyBank.monkey_patch_bank_account.bank_address = res.ADDRESS;
      },
      error: (err) => {
      }
    })
  }

  saveBankAccount() {
    if(!this.companyBank.monkey_patch_bank_account.account_name 
    || this.companyBank.monkey_patch_bank_account.account_name.trim()==""
    || !this.companyBank.monkey_patch_bank_account.account_number){
      return this.notificationsService.initiate({
            title: 'Account name & number can\'t be empty',
            type: toastTypes.error
          })
    }

    if (this.parent.hasRequestType === 'edit') {
      this.companyBank.monkey_patch_bank_account.id = this.companyBank.id; // company bank is realtion with bank
      this.companyService.editBankAccount(this.companyBank.monkey_patch_bank_account).subscribe({
        next: res => {
          this.notificationsService.initiate({
            title: 'Bank details save!',
            type: toastTypes.success
          })
          this.ngbActiveOffcanvas.close();
        },
        error: err => {
          console.log(err);
          this.notificationsService.initiate({
            title: 'Unable to save!',
            type: toastTypes.error
          });
          this.ngbActiveOffcanvas.dismiss();
        }
      })
    }

    if (this.parent.hasRequestType === 'add') {
      this.companyService.addBankAccount({ ...this.companyBank.monkey_patch_bank_account, 'company_id': this.company.id }).subscribe({
        next: res => {
          this.notificationsService.initiate({
            title: 'New Bank details save!',
            type: toastTypes.success
          })
          this.ngbActiveOffcanvas.close(res);
        },
        error: err => {
          console.log(err);
          this.notificationsService.initiate({
            title: 'Unable to save!',
            type: toastTypes.error
          });
          this.ngbActiveOffcanvas.dismiss();
        }
      });
    }
  }
  closeBankCanvas() {
    this.ngbActiveOffcanvas.dismiss();
  }
}
