import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import {takeWhile} from 'rxjs/operators';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  public mobileMenus = [];
  public livebtn = {} as any;
  private alive = true;
  public examType = '';
  constructor(
    public authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.examType = this.localStorageService.get('examType') || '';
    this.authService.getCachedJSONByUrl('assets/data/mobileMenu.json').pipe(takeWhile(() => this.alive)).subscribe(data => {
      this.mobileMenus = data.menus || [];
      this.livebtn = data.livebtn || {};
    });
  }

  ngOnInit() {
    //
  }
  openNav() {
    document.getElementById('myNav').style.width = '80%';
  }
  closeNav() {
    document.getElementById('myNav').style.width = '0%';
  }
  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }
  ngOnDestroy() {
    this.alive = false;
  }
}
