<div class="row">
  <div *ngIf="showControl" class="col-12 text-center mb-4" style="font-size: 20px; color:#fff">
    <!-- <i class="bi bi-chevron-double-left" (click)="onPrevPage()"></i> -->
    <button (click)="onPrevPage()" class="btn btn-icon" type="button">
      <ng-icon class="d-flex justify-content-center align-items-center " name="featherChevronLeft" >
      </ng-icon>
    </button>
    &nbsp;
    <button (click)="onNextPage()" class="btn btn-icon" type="button">
      <ng-icon class="d-flex justify-content-center align-items-center" name="featherChevronRight" >
      </ng-icon>
    </button>
    <!-- <i class="bi bi-chevron-double-right" ></i> -->
    &nbsp;
    <span>Page: <span>{{currentPageNo}}</span> / <span>{{totalPageCount}}</span></span>
    &nbsp;
  </div>
  <div class="col-12 text-center">
    <canvas #myCanvas></canvas>
  </div>
</div>
