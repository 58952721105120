<div style="padding: 30px 40px 0px 40px;">
    <div class="d-flex flex-column">
        <div class="d-flex" style="margin-bottom:20px;">
            <div class="f-18" style="font-weight: 500;">Warehouse Setting</div>
            <div class="ms-auto">
                <svg class="pointer" (click)="activeModal.dismiss()" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>

        <div class="tabs_container">
            <div class="tabs_container_content p-0" style="margin-left: 0 !important;">
                <span class="cursor single_tab text-muted custom-text"
                    [ngClass]="warehouseTab=='warehouseDetails'?'active':''" (click)="warehouseTab='warehouseDetails'">
                    Warehouse Details
                    <div class="active_tab_line"></div>
                </span>

                <span class="cursor single_tab text-muted custom-text" *ngIf="warehouse.id" [ngClass]="warehouseTab=='member'?'active':''"
                    (click)="warehouseTab='member'">
                    Members
                    <div class="active_tab_line"></div>
                </span>

            </div>
        </div>

    </div>
</div>
<div style="border-top: 1px solid #E5E7F3; margin-bottom: 36px;"></div>
<div style="padding: 0px 40px 30px 40px;">
    <div class="d-flex flex-column gap-1">
        <ng-container *ngIf="warehouseTab === 'warehouseDetails'">
            <div class="row gx-1">
                <div class="col-3 col-lg-2 d-flex justify-content-start align-items-start p-0">
                    <div class="project-photo">
                        <div class="project-name">{{'W H' | shortName}}</div>
                    </div>
                </div>
                <div class="col-9 col-lg-10 p-0">
                    <div class="d-flex gap-3 align-items-center">
                        <div class="custom-input mb-3">
                            <label class="custom-text">Address</label>
                            <input class="custom-text" type="text" autocomplete="off"
                            [(ngModel)]="warehouse.address" />
                        </div>
                        <div class="custom-input mb-3">
                            <label class="custom-text">City</label>
                            <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="warehouse.city" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="warehouseTab ==='member'">
            <div class="modal-container" style=" max-height: 60vh !important;">
                <div class="d-flex flex-column pt-2">
                    <app-party-input [parentComponent]="{pageFrom:'warehouse-setting'}"
                        (onCompanyUserSelect)="setCompanyUser($event)"></app-party-input>
                </div>

                <div class="vstack" style="overflow-x:hidden; overflow-y: auto; height: 30vh;">
                    <div>
                        <span class="member-count">
                            <!-- 2 Members, 1 Pending -->
                        </span>
                    </div>
                    <div *ngFor="let item of members; let i = index;">
                        <ng-container>
                            <div class="d-flex flex-column" style="position: relative !important;">
                                <div class="d-flex my-1 pointer member-card p-2"
                                    [ngClass]="member.id===item.id?'member-card-active':''"
                                    (click)="openAccessBox(item); companySearchBox=false" id="dropdownManual">
                                    <div class="align-self-center">
                                        <div [ngClass]="item.role=='manager'?'avatar-manager':'avatar-admin'"
                                            class="avatar" style="font-size:16px !important;">
                                            {{item.monkey_patch_company_user.name | shortName}}
                                        </div>
                                    </div>
                                    <div class="ms-4">
                                        <span class="member-name">
                                            {{item.monkey_patch_company_user.name}}
                                            <span *ngIf="warehouse.contractor_company_user_id==item.company_user_id"
                                                class="fs-8">
                                                (Super Admin)
                                            </span>
                                        </span>
                                        <br>
                                        <span class="member-mobile">
                                            {{item.monkey_patch_company_user.mobile}}
                                        </span>
                                    </div>
                                    <div class="ms-auto">
                                        <span class="member-mobile">
                                            {{item.monkey_patch_company_user.monkey_patch_company_role.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>


            <div *ngIf="accessBox" class="access-box-close" (click)="accessBox=false"></div>
            <div class="access-box p-1" *ngIf="accessBox" [ngClass]="companyUser.role=='admin'? 'top-38-percent':''">

                <div *ngFor="let role of companyRoles">
                    <div class="d-flex access-box-items pointer py-2" (click)="setMemberAccess(role); accessBox=false">
                        <div class="d-flex justify-content-center align-items-center" style="width: 50px">
                            <div *ngIf="companyUser.company_role_id==role.id" class="active-tick"></div>
                        </div>
                        <div class="d-flex flex-column ">
                            <span style="font-weight: 500;" class="access-title mb-1">{{role.name}}</span>
                            <span class="btn-desc" *ngIf="role.id=='id1'">Admin has complete
                                access.</span>
                            <span class="btn-desc" *ngIf="role.id=='id2'"> Manager has
                                limited access.</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="warehouse.creator_company_user_id!=companyUser.id"
                        class="d-flex access-box-items pointer py-2" (click)="deleteTeamMember()">
                        <div style="width: 50px;" class="p-2">
                        </div>
                        <div class="d-flex flex-column">
                            <span style="font-weight: 500;" class="remove mb-1">
                                Remove
                            </span>

                            <span class="btn-desc">
                                Remove from warehouse.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


        <div class="mt-auto d-flex justify-content-between" style="margin-top: 40px !important;"
            *ngIf="warehouseTab=='warehouseDetails'">

            <div class="ms-auto align-self-center">
                <span class="project-btn save" (click)="addEditWarehouseDetails()">{{warehouse?.id ? 'Save' : 'Activate'}}</span>
            </div>

        </div>
    </div>
</div>