<div class="picker shadow-lg p-3 mb-5 bg-white rounded">
  <div class="input-box">
    <div class="custom-input">
      <label for="qtn-name">Start Date</label>
      <input
        type="date"
        [(ngModel)]="fromInput"
        (change)="changeInp()"
      />
    </div>
    <div class="custom-input">
      <label for="qtn-name">End Date</label>
      <input
        type="date"
        [(ngModel)]="toInput"
        (change)="changeInp()"
      />
    </div>
  </div>
  <ngb-datepicker
    #dp
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
  <div class="more-actions">
    <button
      (click)="setDate()"
      type="button"
      class="btn f-14 demo button apply"
    >
      Apply
    </button>
  </div>
</div>
