<!-- <ng-container *ngIf="isNotPaid"> -->

<!-- <div class="close-btn">Close</div> -->
<div *ngIf="isNotPaid"
  style="background-color: rgba(0, 0, 0, 0.371); height: 100%; width: 100%; position: fixed; top: 0; left: 0;">
</div>
<div class="unpaid" [ngClass]="isNotPaid?'bottom-f-state':'bottom-i-state'">

  <div class="unpaid-content">
    <div class="d-flex flex-column mt-5 gap-4">
      <div>
        <div>Buy Premium account for</div>
        <div (click)="openMenu = !openMenu" class="dropdown">
          <div class="select" [ngClass]="{'select-clicked':openMenu}">
            <div class="selected f-22">{{activeTab?activeTab:company.name | uppercase}}</div>
            <div class="caret" [ngClass]="{'caret-rotate':openMenu}" ></div>
          </div>
          <ul class="menu" [ngClass]="{'menu-open':openMenu}">
            <ng-container *ngFor="let item of authService.session.companies">
              <li (click)="activeTab = item.name; selectCompany(item)" [ngClass]="{'active':  !activeTab?company.name === item.name:activeTab === item.name}">{{item.name}}</li>
            </ng-container>
          </ul>
        </div>

      </div>
      <div class="d-flex flex-column" style="font-weight: 300; font-size: 16px; line-height: 24px; color: #000000;">
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Access to Onsite Web
          </span>
        </div>
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Combined Party Balances for All Projects
          </span>
        </div>
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Download Excel Reports
          </span>
        </div>
      </div>
      <div class="hstack justify-content-between" style="flex-wrap: wrap;">
        <div *ngIf="!trialExhausted" class="start-trial-btn text-center" (click)="trialSubcription();">
          <span class="start-trial-title">Start Trial</span>
          <span class="start-trial-subtitle"> (3 Days)</span>
        </div>
        <div *ngIf="trialExhausted" class="start-trial-btn text-center">
          <span class="start-trial-title">Trial exhausted</span>
        </div>
        <a [href]="bookDemo" target="_blank" style="text-decoration: none;color: #fff;" class="upgrade-btn">
          <span class="upgrade-title">Book Demo</span>
        </a>
      </div>

      <div class="d-flex gap-3 align-items-center" style="font-weight: 400;font-size: 14px;">
        <div>1,00,000+ <span>Happy customers</span></div>
        <div
        ngbTooltip="Chat with Support Team"
        style="border-left: dotted 1px #000;" 
        class="ps-2 pointer" (click)="sendWhatsAppMsg()">
          <img src="../../../../assets/svg/whatsapp.svg" alt="">
        </div>
        <div style="border-left: dotted 1px #000;" class="ps-2">
          <ng-icon style="transform: scale(1.1);" name="featherPhone"></ng-icon>
          <span class="ps-2"> Call us: 9560209605</span>
        </div>

        <div style="border-left: dotted 1px #000;" class="ps-2">
          <span class="ps-2 pointer" (click)="logout()">
            <ng-icon style="transform: scale(1.1);" name="featherLogOut"></ng-icon>
            <span class="ps-2"> Logout </span> 
          </span>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- </ng-container> -->
