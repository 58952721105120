import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import {
  Project,
  MaterialItem,
  Subcategory,
  Category
} from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-materialItem-modal',
  templateUrl: './materialItem-modal.component.html',
  styleUrls: ['./materialItem-modal.component.css'],
})
export class MaterialItemModalComponent implements OnInit {
  @Input() project: Project;
  public materialItem = {} as MaterialItem;
  public subCategories = [] as Subcategory[];
  public categories = [] as Category[];
  constructor(
    private activeModal: NgbActiveModal,
    private adminService: AdminService,
    modalConfig: NgbModalConfig,
  ) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit(): void {
    this.getSubcategory();
    this.getCategory();
  }
  close() {
    this.activeModal.dismiss();
  }
  save() {
    if(!this.materialItem.name){
      return alert('Material name required');
    }
    this.materialItem.company_id = this.project.company_id;
    this.adminService.addMaterialItem(this.materialItem).subscribe((res_mat_item) => {
      this.activeModal.close(res_mat_item);
    });
  }
  getSubcategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    this.adminService.subcategoryList(qp).subscribe((res) => this.subCategories = res.subcategories || [] as Subcategory);
  }

  getCategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    qp = qp.set('type', 'unit');
    this.adminService.categoryList(qp).subscribe((res) => this.categories = res.categories || [] as Subcategory);
  }

  setMaterialUnit(e) {
    const cat_id = e.target.value || {};
    for (const cat of this.categories) {
      if (cat.id === cat_id) {
        this.materialItem.unit = cat.text_en;
        this.materialItem.unit_id = cat.id;
      }
    }
  }

  setSubcategory(sub_cat?: Subcategory) {
    this.materialItem.sub_category_id = sub_cat.id;
}
}
