<div class="modal-wrapper">
    <div style="padding: 30px 20px 10px 20px">
        <div class="d-flex flex-column">
            <div class="d-flex">
                <div class="f-16" style="font-weight: 500;">{{source?.fromPage == 'add-subcon-workorder' ? 'Sub-Con Workorder' : 'Sub-Contractor Expense Details'}}</div>
                <div class="ms-auto" (click)="activeModal.dismiss()">
                    <ng-icon name="featherX" class="pointer disabled-font" style="transform: scale(1.5);"></ng-icon>
                </div>
            </div>
        </div>  
    </div>
    <div class="d-flex flex-column gap-3 " style="padding: 20px">
        <div class="d-flex justify-content-end">
            <div class="f-14 datepicker-hide-input calender d-flex align-items-center justify-items-center" *ngIf="source?.fromPage === 'edit-subcon-expense-prefix'">
                <svg (click)="d.toggle()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                        stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3 10H21" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <input class="pointer" style="background-color: #fff" placeholder="Select Date" [(ngModel)]="model"
                    [maxDate]="today" ngbDatepicker #d="ngbDatepicker" />
                <svg (click)="d.toggle()" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        <app-party-input [parentComponent]="{pageFrom:'create-subcon'}" (onCompanyUserSelect)="selectedUser($event)" [class.custom-disable]="source?.fromPage == 'edit-subcon-expense-prefix' || (source?.fromPage == 'edit-subcon-workorder' && source.subconWorkorder?.invoiced_amount>0)"></app-party-input>
        <div class="custom-input" [class.custom-disable]="source?.fromPage == 'edit-subcon-workorder'" *ngIf="source?.fromPage !== 'edit-subcon-expense-prefix'">
            <label>Work Order Title</label>
            <input type="text" [(ngModel)]="subConWorkOrder.name" />
        </div>
        <div class="d-flex gap-2" *ngIf="source?.fromPage == 'edit-subcon-workorder'">
            <div class="custom-input w-50">
                <label>Prefix</label>
                <input type="text" [(ngModel)]="subConWorkOrder.prefix"/>
            </div>
            <div class="custom-input w-50">
                <label>Number</label>
                <input type="number" [(ngModel)]="subConWorkOrder.sequence"/>
            </div>
        </div>
        <div *ngIf="source.fromPage !== 'edit-subcon-expense-prefix'" class="d-flex flex-column align-items-end w-100 ">
            <div *ngIf="tncToggle" (click)="tncToggle = false" class="d-flex justify-content-start w-100">
                <button (click)="tncToggle = true" type="button" class="btn f-14 p-0" style="border: none; color: #5F44CE;">
                    close
                </button>
            </div>
            <div *ngIf="tncToggle" class="custom-div py-2 my-2" style="height: fit-content; width: 100%;">
                <textarea [(ngModel)]="tnc" name="tnc" id="" rows="5" style="border: none; background-color: transparent; width: 100%; resize: none;" class="f-12"></textarea>
            </div>
            <button *ngIf="!tncToggle" (click)="tncToggle = true" type="button" class="btn f-14" style="border: none; color: #5F44CE;">+ Add Terms & Conditions</button>
           <div style="width: 100%;" >
            <app-attachment-view #child folderName="workorder" hasTitle="Attach Media" ></app-attachment-view></div>
        </div>
        <button (click)="updateWorkOrder()" class="btn f-14 action-button">{{source.fromPage ==  'add-subcon-workorder' ? 'Create Workorder' : 'Save'}}</button>
    </div>
</div>