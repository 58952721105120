<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-2">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-column align-items-start">
        <span class="f-12">Sales Invoice</span>

      </div>
    </div>

    <div class="d-flex justify-content-end gap-4">

      <div (click)="saveDeductionItem()" class="canvas-save-btn" type="button"
      > Save </div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div class="canvas-body p-4">
    <div class="custom-input">
      <label for="name">Item Name</label>
      <input [(ngModel)]="deductionItemName" type="text" class="form-control" id="name" name="name"
        placeholder="Enter Item Name">
    </div>
  </div>
</div>