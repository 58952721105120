import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.css']
})
export class CalendarModalComponent implements OnInit {
  @Input() model: NgbDateStruct;
  date: { year: number; month: number };
  constructor(
    private calendar: NgbCalendar,
    private ngbActiveModal: NgbActiveModal
  ){

  }

  ngOnInit(): void {
    this.model.month++
  }
  setDate(e){
    this.model.month--
    this.ngbActiveModal.close(this.model);
  }

  navigate(datepicker: NgbDatepicker, number: number, e) {
    e.stopPropagation();
		const { state, calendar } = datepicker;
		datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
	}

	today(datepicker: NgbDatepicker, e) {
    e.stopPropagation();
		const { calendar } = datepicker;
		datepicker.navigateTo(calendar.getToday());
	}
}
