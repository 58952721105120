import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent {
@Input() width:number;
@Input() heightInRem:number;
@Input() color:string;

progressValue = 0;

constructor(){
  this.updateProgress();
}

updateProgress() {
  setInterval(() => {
    this.progressValue = Math.min(this.progressValue + 10, 100);
  }, 500);
}
}
