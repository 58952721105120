import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit, OnDestroy {
  public eventEndTime = new Date();
  public timeLeft;
  public timeOver = false;
  public daysLeft;
  public hoursLeft;
  public minsLeft;
  public secsLeft;
  public __timer_interval_fn: any;
  @Input() config = {year: 2019, month: 8, day: 7, hideText: false, hideTimer: false};
  constructor() { }
  ngOnInit() {
    this.eventEndTime.setFullYear(this.config.year, this.config.month, this.config.day);
    this.eventEndTime.setHours(23, 59, 59);
    this.calcTime();
    this.__timer_interval_fn = setInterval(this.calcTime, 1000);
  }
  calcTime = () => {
    const now =  new Date();
    this.timeLeft = this.eventEndTime.getTime() - now.getTime();
    if (this.timeLeft >= 0) {
      const min = 60 * 1000; // ms
      const hour = 60 * min; // ms
      const day = 24 * hour; // ms
      this.daysLeft = Math.floor(this.timeLeft / day);
      this.hoursLeft = Math.floor((this.timeLeft % day) / hour);
      this.minsLeft =  Math.floor((this.timeLeft % hour) / min);
      this.secsLeft =  Math.floor((this.timeLeft % min) / 1000);
    } else {
      this.timeOver = true;
      clearInterval(this.__timer_interval_fn);
    }
  }
  ngOnDestroy() {
    clearInterval(this.__timer_interval_fn);
  }
}
