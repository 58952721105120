export enum CompanyPartyType {
  Customer = 'Customer',
  Employee = 'employee',
  Labour = 'labour',
  Investor = 'investor',
  MaterialSupplier = 'material supplier',
  LabourContractor = 'labour contractor',
  EquipmentSupplier = 'equipment supplier',
  SubContractor = 'sub contractor',
  Vendor = 'vendor'
}

export enum payroll_level {
  Company = 'company',
  Project = 'project'
}

export enum payroll_type {
  staff = 'staff',
  labour = 'labour',
  labour_contractor = 'labour_contractor'
}
