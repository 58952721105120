<div class="d-flex gap-2 justify-content-center align-items-cente f-12" *ngIf="showElementBeforeExpirePlain">
  <div class="align-self-center">
    <a [href]="playStoreUrl" target="_blank">
      <img src="/../../assets/images/play-store.svg" alt="play store" style="width: 24px; height: 24px;">
    </a>
  </div>
  <div class="align-self-center">
    <a [href]="appStoreUrl" target="_blank">
      <img src="/../../assets/images/app-store.svg" alt="play store" style="width: 24px; height: 24px;">
    </a>
  </div>
  <div class="align-self-center">
    <button (click)="openViewPlansUrl()" class="btn btn-sm onsite-btn-primary text-white f-12 p-0 px-1">View Plans</button>
  </div>
  <app-premium-tag class="align-self-center" [small]="false"></app-premium-tag>

</div>
