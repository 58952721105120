import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportModalComponent } from '../modal/report-modal/report-modal.component';
import { Parent } from 'src/app/interfaces/interfaces';
@Component({
  selector: 'app-download-report-button',
  templateUrl: './download-report-button.component.html',
  styleUrls: ['./download-report-button.component.css']
})
export class DownloadReportButtonComponent implements OnInit {
@Input() parent_page:Parent;
  constructor(
    public modalService :NgbModal
  ) { }

  ngOnInit(): void {
    //
  }

  downloadReport(){
    const reportModal = this.modalService.open(ReportModalComponent, { centered: true, backdrop: 'static', size: '1000px' });
    reportModal.componentInstance.parent= {'project':this.parent_page.project?this.parent_page.project:null, 'page':this.parent_page};
  }
}
