/**
 * Created by tarun on 19/7/17.
 */

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { Company } from 'src/app/interfaces/interfaces';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/company/services/company.service';
// import { NotPaidOffcanvasComponent } from 'src/app/shared/components/offcanvas/NotPaidOffcanvas/NotPaidOffcanvas.component';

@Injectable()
export class PaidCompanyGuard  {
private company = {} as Company;
  constructor(
    private localStorageService: LocalStorageService,
    private companyService:CompanyService
  ) {
    this.company = localStorageService.get('company') || {};
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) {
    return this.checkSubscription();
  }

  checkSubscription(): boolean {
    if (this.company.is_paid) {
      return true;
    }

    this.companyService.premiumUserPopupBehaviorSubject.next(true)
    // this.ngbOffcanvas.open(NotPaidOffcanvasComponent, {position:'bottom'})
    return false;
  }
}
