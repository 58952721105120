import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { BillingActivity, Company, CompanyUser, Project, Task } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { UtilService } from '../../services/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
interface ParentData {
  project_id?: string;
  pageFrom?: 'partyEarning'| 'party-payment' | 'materialPurchase'| 'materialInventory' | 'withdraw'| 'deposit' | 'attendance' | 'project-setting' | 'sales-invoice' | 'AddQuotationComponent' | 'equipment' | 'warehouse-setting' | 'create-subcon'| 'purchase-order' | 'material-return' | 'task-assignee' | 'project-creation' | 'asset-modal' | 'todo';
  party_company_user_id?: string;
  autoFocus?: boolean;
  autoClick?: boolean;
  inputLabel?: string;
}
@Component({
  selector: 'app-task-input',
  templateUrl: './task-input.component.html',
  styleUrls: ['./task-input.component.css']
})
export class TaskInputComponent {
  @ViewChild('taskName') taskNameEle: ElementRef;
  @Input() parentComponent: ParentData;
  @Output() onTaskSelect = new EventEmitter<BillingActivity>(true);
  @Input() required: boolean;
  public formInputError = '';
  public task = {} as BillingActivity;
  public tasks = [] as BillingActivity[];
  public project_id: string;
  public company = {} as Company;
  public taskSearchBox = false;
  constructor(
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    public authService:AuthService,
    private utilService: UtilService
  ) {
  }
  
  ngOnInit(): void {
    this.project_id = this.parentComponent.project_id || this.authService.session.project.id;
    this.getTaskList();
  
  }

  ngAfterViewInit() {
    if(this.parentComponent.autoFocus){
      setTimeout(() => {
        this.taskNameEle.nativeElement.focus();
      }, 100);
    }
  }
  
  ngOnDestroy() {
    //
  }
  
  getTaskList() {
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project_id);
    this.adminService.getTaskList(qp).subscribe((res) => {
      this.tasks = res.data || ([] as Project[]);
      this.localStorageService.set('tasks', this.tasks);
      if(this.parentComponent.autoClick){
        this.taskNameEle.nativeElement.click();
      }
      this.utilService.taskIdObservable.subscribe(tak_id => {
        if (tak_id) {
          this.task = this.tasks.find(project => project.id == tak_id);
          if(this.task){
            this.setCompanyTask(this.task);
          }else{
            this.task = {}
            this.sendTask(this.task)
          }
        }
      })
  
    });
  }
  
  filterTasks() {
    let len = 0;
    this.task.name  = this.task.name?.trimStart()
    this.task.name ? len = this.task.name?.length : 0;
  
    if (len > 0) {
      this.tasks = this.localStorageService.get('tasks') || [];
      const pattern = new RegExp(this.task.name, 'i');
      this.tasks = this.tasks.filter((f) =>
        f.name.match(pattern)
      );
      this.taskSearchBox = true;
    }
    if (len == 0) {
      this.tasks = this.localStorageService.get('tasks');
      this.taskSearchBox = true;
    }
  }
  
  closeSearchBox(event?) {
    event?.stopPropagation();
    this.task = {};
    this.filterTasks();
    this.taskSearchBox = false;
    this.onTaskSelect.emit()
  }
  
  setCompanyTask(item: BillingActivity) {
    this.task = item;
    this.formInputError = '';
    this.taskSearchBox = false;
    this.sendTask(item);
  }
  
  checkName(taskName: string) {
    const localTask = this.localStorageService.get('tasks') as BillingActivity[];
    const findTask = localTask.filter(item => item.name === taskName);
    if (findTask.length <= 0) {
      // this.sendProject()
    } else {
      return this.task;
    }
  }
  
  sendTask(item?: BillingActivity) {
    this.formInputError = '';
    this.onTaskSelect.emit(item)
  }
  setError(value: boolean) {
    value == true ? this.formInputError = 'taskName' : this.formInputError = '';
  }
  
  }