/**
 * Created by tarun on 19/7/17.
 */

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuard  {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    if (!this.authService.session.isActive) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class AdminGuard  {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate() {
    if (this.authService.session.isActive && this.authService.session.profile.admin == 1) {
      return true;
    }
    return false;
  }
}

@Injectable()
export class SupervisorGuard  {
  constructor(
    private authService: AuthService
  ) {}
  canActivate() {
    return this.authService.session.isActive?true:false
  }
}
