<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-2">
      <app-back-button></app-back-button>
      <div class="canvas-title">
        <span>Add Item </span>
      </div>
    </div>

    <div class="d-flex gap-4">
      <!-- <button class="save-download-btn">Save & DownLoad</button> -->
      <div class="canvas-cancel-btn" *ngIf="parent.debit_note_item" (click)="deleteBtnClicked()">Delete</div>
      <div class="canvas-save-btn" (click)="saveBtnClicked()">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div class="canvas-body">
    <div class="custom-input mb-3">
      <input type="text" id="itemName" [(ngModel)]="debitNoteItem.name"/>
      <label for="itemName">Item Name</label>
    </div>
    
    <div style="width: 100%" class="item-per-unit gap-3 mb-4">
      <div class="custom-input input-width">
        <input type="text" id="estimate-quantity" (input)="debitNoteItem.quantity = validatorService.numberFormat($event, { type: 'number', decimal: true})" [(ngModel)]="debitNoteItem.quantity" />
        <label for="estimate-quantity">Estimate quantity</label>
      </div>
    
      <div class="custom-input d-flex align-items-center" style="width: 35%;">
        <label>Unit</label>
        <select (change)="setActivityUnit($event)">
          <ng-container *ngFor="let cat of categories">
            <option class="select-type" value="{{ cat.id }}" [selected]="debitNoteItem.unit_id==cat.id">
              {{ cat.text_en }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>

    <div style="width: 100%" class="item-per-unit gap-3 mb-4">
      <div class="custom-input input-width">
        <input type="text" id="ratePerUnit" (input)="debitNoteItem.unit_price = validatorService.numberFormat($event, { type: 'number', decimal: true})" [(ngModel)]="debitNoteItem.unit_price" />
        <label for="ratePerUnit">Rate Per Unit</label>
      </div>

      <div class="custom-input d-flex align-items-center" style="width: 35%;">
        <label>{{authService.session.country.tax_value_display_name?authService.session.country.tax_value_display_name:'Tax'}} %</label>
        <select name="" id="" (change)="setActivityGst($event)">
          <ng-container *ngFor="let tax of gst">
            <option class="select-type" value="{{ tax }}" [selected]="debitNoteItem.gst_percent==tax">{{ tax }}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div style="margin-top: 16px" class="d-flex flex-column gap-3">
      <div *ngIf="addHSN" class="d-flex align-items-center gap-2">
        <div (click)="addHSN = false; debitNoteItem.hsn_code = ''" class="close-circle-btn">
          <img src="../../../../assets/svg/cross.svg" alt="cross-btn" />
        </div>

        <div class="custom-input">
          <input type="text" id="hsn" [(ngModel)]="debitNoteItem.hsn_code" />
          <label for="hsn">HSN/SAC</label>
        </div>
      </div>

      <div [ngClass]="addDescription ? 'd-flex' : 'd-none'" style="position: relative; margin-top: 20px">
        <textarea class="note_component" [(ngModel)]="debitNoteItem.notes">
          </textarea>
        <div class="note_component_abs_title">Note</div>
      </div>
      <div [ngClass]="addDescription ? 'd-flex' : 'd-none'" class="hstack align-items-center gap-2">
        <span class="pointer" (click)="addDescription = false">
          <ng-icon name="featherXCircle" class="disabled-font"></ng-icon>
        </span>
        <div class="flex-shrink-1 align-self-center f-16 ">Remove</div>
      </div>

      <div *ngIf="addTrade" class="d-flex align-items-center gap-2">
        <div (click)="addTrade = false; debitNoteItem.sub_category_id = ''" class="close-circle-btn">
          <img src="../../../../assets/svg/cross.svg" alt="cross-btn" />
        </div>

        <div class="custom-input input-width d-flex align-items-center pointer" (click)="openCostCodeCanvas()">
          <label>Cost Code</label>
          <div class="ps-3">
            {{subCategory.text_en?subCategory.text_en:'Add Cost Code'}}
          </div>
        </div>
      </div>

      <div style="margin-top: 16px" class="d-flex flex-column align-items-end gap-3">
        <span (click)="addHSN = true" *ngIf="!addHSN" class="f-12 text-buttons">+ HSN/SAC</span>
        <span (click)="addDescription = true" *ngIf="!addDescription" class="f-12 text-buttons">+ Description</span>
        <span (click)="addTrade = true" *ngIf="!addTrade" class="f-12 text-buttons">+ Cost Code</span>
      </div>
    </div>
  </div>
</div>
