import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../../core/services/auth.service';
import {Token, Profile, Anon, IAlert} from '../../../interfaces/interfaces';

declare var gtag: any;

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent implements OnInit {
  @Input() data: any;
  public formName = '';
  public profileOptionshow = false;
  public sidebarShow = false;
  public anon = {} as Anon;
  public alerts: Array<IAlert> = [];
  public loading = false;
  public profile: Profile;
  public validation_err = false;
  public form_err: any = {} as any;
  public promoCode: any;
  public showPromo = false;
  public showReferral = false;
  public desableResenBTN = false;
  constructor(
    public authService: AuthService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.formName = this.data.type || 'login';
  }
  login() {
    this.validation_err = false;
    this.mobileValidation();
    if (this.validation_err) {
      return;
    }
    this.loading = true;
    this.authService.login(this.anon).subscribe(data => {
      // gtag('event', 'login', {'method': 'MyResumeFormat-Web'});
      const token: Token =  {token: data.token, expire: data.expire} as Token;
      this.authService.startSession(token).then(session => {
        location.reload();
      }).catch(err => {
        // console.log(err);
      });
    }, err => {
      this.loading = false;
      if (err.status < 500) {
        this.alerts = [];
        this.alerts.push({id: 1, type: 'danger', message: err.error.message});
      } else {
        this.alerts = [];
        this.alerts.push({id: 1, type: 'danger', message: 'Oops something went wrong!'});
      }
    });
  }
  register() {
    this.validation_err = false;
    this.mobileValidation();
    if (this.validation_err) {
      return;
    }
    this.loading = true;
    this.authService.signupAndGetOTP(this.anon).subscribe(data => {
      this.loading = false;
      // gtag('event', 'signup', {'method': 'MyResumeFormat-Web'});
      this.alerts = [];
      this.alerts.push({ id: 1, type: 'success', message: data.response });
      this.formName = 'login';
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err.status < 500) {
        this.alerts = [];
        this.alerts.push({id: 1, type: 'danger', message: err.error.message});
      } else {
        this.alerts = [];
        this.alerts.push({id: 1, type: 'danger', message: 'Oops something went wrong!'});
      }
    });
  }
  sendOTP() {
    this.validation_err = false;
    this.mobileValidation();
    if (this.validation_err) {
      return;
    }
    this.loading = true;
    this.disableResend();
    this.authService.sendOTP(this.anon).subscribe(data => {
      // gtag('event', 'signup', {'method': 'Web'});
      this.alerts = [];
      this.alerts.push({ id: 1, type: 'success', message: data.response });
      this.formName = 'login';
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err.status < 500) {
        this.alerts = [];
        this.alerts.push({ id: 1, type: 'danger', message: err.error.message });
      } else {
        this.alerts = [];
        this.alerts.push({ id: 1, type: 'danger', message: 'Oops something went wrong!' });
      }
    });
  }
  dismiss(str) {
    this.activeModal.dismiss('Hi');
  }
  close(str) {
    this.activeModal.close({data: str});
  }
  closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
  loginOnKeyUp(evt: KeyboardEvent) {
    if (evt.code === 'Enter') {
      this.login();
    }
  }
  registerOnKeyUp(evt: KeyboardEvent) {
    if (evt.code === 'Enter') {
      this.register();
    }
  }
  mobileValidation() {
    if (this.anon.mobile < 1000000000 || this.anon.mobile === undefined) {
      this.validation_err = true;
      this.form_err.mobile = true;
    } else {
      this.form_err.mobile = false;
    }
  }
  disableResend() {
    const _this = this;
    _this.desableResenBTN = true;
    setTimeout(() => {
      _this.desableResenBTN = false;
    }, 180 * 1000);
  }
}
