import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { NgbOffcanvas, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-canvas-bottom-bar',
  templateUrl: './canvas-bottom-bar.component.html',
  styleUrls: ['./canvas-bottom-bar.component.css']
})
export class CanvasBottomBarComponent {
  @Input() id:string;
  @Input() approval_flag:string;
  @Input() transactionType:string;
  @Input() approved_by:string;
  @Input() show_more_option:boolean;
  @Input() approval_comment:string;
  @Input() is_editable:boolean;
  @Output() isDirty: EventEmitter<boolean> = new EventEmitter();
  openOptionsStatus = false;

  constructor(
    private adminService:AdminService,
    private notificationService:NotificationsService,
    private authService:AuthService,
    private modalService:NgbModal

  ){
    //
  }
  
  approveRejectTransaction(status:'rejected' | 'approved' | 'pending'){
    if(status == 'rejected' || status == 'pending'){
      if(!this.is_editable){
        return this.notificationService.initiate({title:'Status can not be changed, because it is paid or partially paid!', type:toastTypes.error});
      }
    }
    if(this.transactionType === 'cashbook'){
      this.adminService.approveRejectCashbook({id:this.id, approval_flag:status}).subscribe({
        next:res=>{
          this.approval_flag = res.approval_flag;
          this.authService.myCustomTransactionStatus.next(true);
          this.notificationService.initiate({title:'Status has been changed Successfully', type:toastTypes.success});
          this.isDirty.emit(true);
        },
        error:err=>{
          console.log(err)
        }
      })
    }
    if(this.transactionType === 'invoice'){
      this.adminService.approveRejectReimbursement({id:this.id, approval_flag:status}).subscribe({
        next:res=>{
          this.approval_flag = res.approval_flag;
          this.authService.myCustomTransactionStatus.next(true);
          this.approved_by = res.monkey_patch_approved_by?.name;
          this.notificationService.initiate({title:'Status has been changed Successfully', type:toastTypes.success});
          this.isDirty.emit(true);
        },
        error:err=>{
          console.log(err)
        }
      })
    }

  }
  approveRejectComment(comment:string, is_rejected){
    if(this.transactionType === 'cashbook'){
      this.adminService.updateCashbookApprovalComment({id:this.id, approval_comment:comment}).subscribe({
        next:res=>{
          this.approval_comment = res.approval_comment;
          this.notificationService.initiate({title:'Comment has been changed Successfully', type:toastTypes.success})
          if(is_rejected){
            this.approveRejectTransaction('rejected');
          }
        },
        error:err=>console.log(err)
      })
    }
    if(this.transactionType === 'invoice'){
      this.adminService.updateReimbursementApprovalComment({id:this.id, approval_comment:comment}).subscribe({
        next:res=>{
          this.approval_comment = res.approval_comment;
          this.notificationService.initiate({title:'Comment has been changed Successfully', type:toastTypes.success})
         if(is_rejected){
          this.approveRejectTransaction('rejected');
         }
        },
        error:err=>console.log(err)
      })
    }
  }

  editComment(is_rejected?:boolean){
    if(is_rejected){
      if(!this.is_editable){
        return this.notificationService.initiate({title:'Status can not be changed, because it is paid or partially paid!', type:toastTypes.error});
      }
    }
    const createInvoiceModal = this.modalService.open(AddCommentComponent, { size: 'md', backdrop: 'static'});
    createInvoiceModal.componentInstance.comment = this.approval_comment;
    createInvoiceModal.componentInstance.emitComment.subscribe((updatedComment) => {
      createInvoiceModal.close();
      if(is_rejected){
        this.approveRejectComment(updatedComment, is_rejected);
      }else if(this.approval_comment !== updatedComment && !is_rejected){
        this.approval_comment = updatedComment;
        this.approveRejectComment(updatedComment, false);
      }
    })
  }
  openOptions(){
     this.openOptionsStatus = !this.openOptionsStatus
  }

}
