<div>
  <div class="modal-body">
    <div *ngFor="let alert of alerts">
      <ngb-alert [type]="alert.type" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert>
    </div>
    <div>
      <div class="modal-top">
        <div class="row form-group align-item-right">
          <div class="col-sm-11 col-11 text-right">
            <button type="button" class="modal-close" aria-label="Close" (click)="dismiss('Cross click')">
              <span class="icon icon-close"></span>
            </button>
          </div>
          <div class="col-sm-1 col-1 text-right"></div>
        </div>
        <div class="row form-group align-items-center">
          <div class="col-md-3">
            Name
          </div>
          <div class="col-md-9">
            <input class="form-control" type="text" [(ngModel)]="data.name">
          </div>
        </div>
        <div class="row form-group align-items-center">
          <div class="col-md-3">
            City
          </div>
          <div class="col-md-9">
            <input class="form-control" type="text" [(ngModel)]="data.city">
          </div>
        </div>
        <div class="row form-group align-items-center">
          <div class="col-md-3">
            Address
          </div>
          <div class="col-md-9">
            <input class="form-control" type="text" [(ngModel)]="data.address">
          </div>
        </div>
        
        <!-- <div class="row form-group align-items-center">
          <div class="col-md-3">
            Status
          </div>
          <div class="col-md-9">
            <select [(ngModel)]="data.status">
              <option [ngValue]="'Ongoing'">Ongoing</option>
              <option [ngValue]="'Complete'">Complete</option>
            </select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <button [disabled]="loading" class="btn btn-lg btn-block btn-primary" (click)="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
