<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-2">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-column align-items-start">
        <span class="f-12">{{parent.pre_tax == 1 ? 'Pre Tax Deductions' : 'Post Tax Deductions'}}</span>
        <span class="f-12 disabled-font">{{this.parent.featureTitle}}: #{{this.parent?.prefix +
          this.parent?.sequence}}</span>
      </div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div class="canvas-body py-4">
    <div class="f-16 fw-bold mb-3 d-flex justify-content-start px-4"><span>Items</span></div>
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
      [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page.next_url == ''"
      class="d-flex flex-column mb-3 items-wrapper px-4">
      <ng-container *ngFor="let deductionEntry of deductionEntryList; let i=index;">
        <div *ngIf="deductionEntry.id && deductionEntry.pre_tax == parent.pre_tax" class="d-flex py-3 align-items-start justify-content-center me-2">
          <div class="d-flex me-3 fs-8 align-items-start">

            <div class="my-checkbox-active"></div>

          </div>
          <div class="d-flex flex-column align-items-start f-12 disabled-font">
            <span class="">{{deductionEntry.monkey_patch_deduction_item?.name}}</span>
          </div>
          <div class="ms-auto text-end">
            <div class="f-12">{{deductionEntry?.amount | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }} </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div style="border-bottom: 1px dashed grey;" class="mb-3 mx-3"></div>
  <div class="d-flex justify-content-between title my-4 mx-3" style="
        background: #E1D8FB;
        border-radius: 8px;
        padding: 15px 8px;
        ">
    <div class="f-14">Total</div>
    <div class="f-14">{{parent.pre_tax == 1 ? this.salesOrderInvoice?.pre_tax_deduction_amount : this.salesOrderInvoice?.post_tax_deduction_amount}}</div>
  </div>
</div>
