  <div class="d-flex flex-column p-3 gap-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <span class="f-14 fw-bold">{{title || 'Comment'}}</span>
            <svg (click)="closeModal()"
                    class="close-icon"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22" r="22" fill="white" />
                    <path
                      d="M28 17L16 29"
                      stroke="#3F3F3F"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 17L28 29"
                      stroke="#3F3F3F"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
            </svg>
    </div>
  <textarea rows="5" [(ngModel)]="comment"></textarea>
  <button (click)="saveComment()" class="f-14 py-2 rounded" style="background: #5F44CE; box-shadow: none; border: none; color: #fff;" type="button">Save</button>
  </div>