<div style="height: 100%; width: 100%; flex-shrink: 0;" class="d-flex justify-content-center align-items-center pt-4 ">
  

  <div class="d-flex flex-column justify-content-center align-items-center gap-2">
    <div class="icons-wrapper">
      <img height="40px" src="../../../../assets/svg/no-task.svg" alt="">
    </div>
    <span class="f-14">No Data {{data}}</span>
  </div>
</div>
