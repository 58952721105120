import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-offer-slide',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, OnDestroy {
  public bannerData = {} as any;
  private alive = true;
  constructor(
    public authService: AuthService
  ) {
    this.authService.getCachedJSONByUrl('assets/data/banner.json').pipe(takeWhile(() => this.alive)).subscribe(data => {
      this.bannerData = data || {};
    });
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.alive = false;
  }

}
