<div class="canvas-bottom-bar">
  <ng-container *ngIf="approval_flag === 'pending'">
    <div class="d-flex align-items-center gap-4">
      <div (click)="approveRejectTransaction('approved')" class="d-flex align-items-center f-14 gap-2 cursor">
        <img src="../../../../assets/svg/thumps-up.svg" alt="approve" />
        <span class="approve">Approve</span>
      </div>
      <div (click)="editComment(true)"
        class="d-flex align-items-center f-14 gap-2 cursor">
        <img src="../../../../assets/svg/thumps-down.svg" alt="approve" />
        <span class="reject">Reject</span>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex flex-column">
        <div class="d-flex gap-2 align-items-center">
          <span *ngIf="approval_flag === 'auto_approved' || approval_flag === 'approved'" class="approve f-14">Approved</span>
          <span *ngIf="approval_flag === 'rejected'" class="reject">Rejected</span>
          <ng-container *ngIf="approval_flag !== 'pending'">
            <span *ngIf="approved_by" class="f-10 approved_by">By {{ approved_by }}</span>
            <span *ngIf="!approved_by" class="f-10 approved_by">Auto Approved</span>
          </ng-container>
        </div>
        <span class="comment" *ngIf="approval_flag !== 'pending' && approval_comment">{{ approval_comment }}</span>
      </div>
      <div *ngIf="openOptionsStatus" (click)="openOptionsStatus ? (openOptionsStatus = false) : ''"
        style="position: fixed; height: 100%; width: 100%; top: 0; left: 0;"></div>
      <div *ngIf="approval_flag !== 'pending' && show_more_option" class="cursor options-button">
        <img (click)="$event.stopPropagation(); openOptions()" src="../../../../assets/svg/3-dot-vertical-grey.svg"
          alt="options" />
        <div *ngIf="openOptionsStatus" class="options" (click)="openOptionsStatus=false;">
          <span (click)="editComment()" class="cursor f-12">
            {{approval_comment ? "Edit Comment" : "Add Comment"}}
          </span>
          <hr *ngIf="approval_flag !== 'pending'" />
          <span (click)="approveRejectTransaction('pending')" *ngIf="approval_flag !== 'pending'"
            class="cursor f-12">Mark Pending</span>
          <hr *ngIf="approval_flag !== 'auto_approved' && approval_flag !== 'approved'" />
          <span (click)="approveRejectTransaction('approved')" *ngIf="
              approval_flag !== 'auto_approved' && approval_flag !== 'approved'
            " class="curosr f-12">Mark Approved</span>
          <hr *ngIf="approval_flag !== 'rejected'" />
          <span (click)="editComment(true)" *ngIf="approval_flag !== 'rejected'"
            class="cursor f-12">Mark Rejected</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>