import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexLegend,
  ApexDataLabels,
} from "ng-apexcharts";
import { Project } from "src/app/interfaces/interfaces";
import { ChartService } from "src/app/project/service/chart.service";
import { CategoryExpenseListComponent } from "../category-expense-list/category-expense-list.component";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend:ApexLegend
  dataLabels: ApexDataLabels;
  colors:string[],
};

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() project = {} as Project;
  
  public chartOptions: Partial<ChartOptions>;
  public colors = [] as string[];
  public labels = [] as string[];
  public values = [] as number[];
 


  constructor(
    private chartService:ChartService,
    private ngbOffcanvas:NgbOffcanvas
  ) {
   //
  }


  ngOnInit(): void {
    this.getExpenseData();
  }

  getExpenseData(){
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);
    this.chartService.getExpenseData(qp).subscribe({
      next:res=>{
       res.data?.map(item=>{
        this.labels.push(item?.label);
        this.values.push(item?.value_y);
        this.generateColor(this.labels);
       })
      },
      error:err=>{
        console.log('err', err)
      }
    })
  }

  genrateChart(){
    this.chartOptions = {
      series: this.values,
      chart: {
        width: 220,
        type: "pie"
      },
      legend: {
        show:false
      },
      dataLabels:{
        enabled:false,
        style:{
          fontSize:'0px',
        }
      },
      labels: this.labels,
      colors: this.colors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 150
            },

            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  generateColor(labels:string[]){
    this.chartService.generateColors(labels).subscribe({
      next:res=>{
        this.colors = res as string[];
        this.genrateChart();
      },
      error:err=>{
        console.log(err);
      }
    })
  }

  openExpense(){
     const ngbOffCanvasRef =  this.ngbOffcanvas.open(CategoryExpenseListComponent,{
      position: 'end',
      backdrop:'static',
      panelClass:'offcanvas-sm'
    })
  }





}
