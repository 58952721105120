import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingProjectComponent } from '../shared/components/modal/setting-project/setting-project.component';
import { Member, Project } from '../interfaces/interfaces';
import { UtilService } from '../shared/services/util.service';
import { AuthService } from '../core/services/auth.service';
import { HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit {

  public member = [] as Member[];
  public memberCount = 0;
  public project = {} as Project;
  public photos:string[] = [];

  constructor(
    public modalService: NgbModal,
    public utilService: UtilService,
    public authService:AuthService,
    public title: Title
  ){
    this.project = this.authService.session.project;
    this.photos = this.project.photos;
    title.setTitle("Overview");
  }

  ngOnInit(): void {
    this.utilService.memberCountObservable.subscribe(count => {
      this.memberCount = count;
    });

    this.getMember(this.project.id);

    
  }

  getMember(project_id: string) {

    let qp = new HttpParams();
    qp = qp.set("project_id", project_id);
    this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      this.member = res.data || ([] as Member);
      this.memberCount = 0;
      this.member.forEach(n => {
        if (n.role == 'admin' || n.role == 'manager') {
          this.memberCount++;
        }
      })

    });
  }

  addTeamMember(item: any) {
    const teamModal = this.modalService.open(SettingProjectComponent, { size: 'lg', backdrop: 'static',backdropClass:'sales-invoice-modal-backdropClass', windowClass: 'sales-invoice-modal-windowClass'});
    teamModal.componentInstance.data = { 'fromPage': item }
  }
}
