import { Component, Input, Renderer2, OnDestroy } from '@angular/core';
export interface Parent{
  display?: boolean,
  urls?: string[]
}
@Component({
  selector: 'app-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.css']
})
export class PhotoSliderComponent implements OnDestroy{
  @Input() parent = {} as Parent;
  displayComponent = false;
  mySlider = false;
  currentIndex = 0;
  constructor(private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.disableScroll(false)
  }
  disableScroll(status: boolean) {
    if (status) {
      window.scrollTo({top:0, left:0})
      this.renderer.addClass(document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(document.body, 'no-scroll')
    }

    // const scrollTop = window.scrollY || document.documentElement.scrollTop;
    // const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    //     // // if any scroll is attempted, set this to the previous value
    //     window.onscroll = function() {
    //         window.scrollTo(scrollLeft, scrollTop);
    //     };
  }

  slideOption(e, behave: 'next' | 'prev' | 'down') {
    e.stopPropagation();
    if (behave == 'next') {
      if (this.currentIndex == (this.parent.urls?.length - 1)) {
        this.currentIndex = -1;
      }
      this.currentIndex++;
    }
    if (behave == 'prev') {
      if (this.currentIndex == 0) {
        this.currentIndex = (this.parent.urls?.length)
      }
      this.currentIndex--;
    }
    if (behave == 'down') {
      this.downloadFile(this.parent.urls[this.currentIndex])
    }
  }

  downloadFile(url: string) {
    const request = new XMLHttpRequest();
    request.open('GET', url);
    request.send();
    request.responseType = 'blob'
    request.onreadystatechange = function () {
      if (request.readyState == 4 && request.status == 200) {
        const imgUrl = window.URL.createObjectURL(request.response);
        const anchor = document.createElement("a");
        anchor.href = imgUrl;
        anchor.download = '';
        document.body.appendChild(anchor);
        anchor.click();
      }
    }
  }

}

