<div class="w-100" ngbDropdown >
    <div ngbDropdownToggle class="custom-div">
        <label for="bank-acount">Bank Account</label>
        <div *ngIf="companyBankAccount?.id" class="w-100 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
                <span class="f-14">{{companyBankAccount?.account_name | truncate:'35'}}</span>
                <span class="f-12 disabled-font">
                    {{companyBankAccount?.account_number}}
                </span>
            </div>
    
          
            <div  class="d-flex justify-content-center align-items-center gap-2">
                <img height="28px" width="28px" [src]="companyBankAccount.monkey_patch_logo_url" onerror="this.src='https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/DEFAULT_LOGO.jpg'" alt="">
                <div (click)="$event.stopPropagation(); removeBankAccount()" class="pointer">
                    <ng-icon name="featherX"></ng-icon>
                </div>
            </div>
            
        </div>
        <span *ngIf="!companyBankAccount?.id" class="f-14 disabled-font" >Select a Bank Account</span>
    </div>

    <div ngbDropdownMenu class="w-100 px-4 dropdown-menu" >

        <div infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="onScroll()"
        [infiniteScrollDisabled]="valueStack.page.next_url==''"  class="bank-lists w-100 mb-2 px-2">

            <ng-container *ngFor="let account of companyBankAccounts" >
                <div ngbDropdownItem (click)="selectBankAccount(account)" class="bank-tile my-3  w-100 d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column">
                        <span class="f-14">{{account.monkey_patch_bank_account?.account_name | truncate:'35'}}</span>
                        <span class="f-12 disabled-font">{{account.monkey_patch_bank_account?.account_number}}</span>
                    </div>
    
                    <div >
                        <img height="28px" width="28px" [src]="account.monkey_patch_bank_account.monkey_patch_logo_url" alt="">
                    </div>
                </div>
            </ng-container>

        </div>

        <button (click)="AddNewCompanyBankAccount()" ngbDropdownItem class="w-100 create-btn py-2 btn f-14">
            + Add New Account
        </button>
    </div>
</div>