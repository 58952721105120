<div  class="img__edit--modal p-2 d-flex flex-column justify-content-between">
  <div class="d-flex align-items-center justify-content-end">
    <button type="button" class="btn btn-link" aria-label="Close" (click)="dismiss('Cross click')">
      <img src="../../../../../assets/svg/cross.svg" alt="">
    </button>
  </div>
  <div  class="modal-body d-flex align-items-center">
    <app-angular-cropper style="height: 100%; display: flex;" #angularCropper [imageUrl]="data" [cropperOptions]="{zoomable: true, aspectRatio}" (export)="handleImport($event)"></app-angular-cropper>
  </div>
  <button class="btn btn-block btn-success mx-3 mb-3" (click)="done()">Upload Photo</button>
</div>
