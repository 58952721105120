<div class="modal-header d-flex align-items-center">
    <div class="modal-header-left d-flex align-items-center gap-4">
        <div class="modal-back-arrow-btn">
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 12.5L0.5 6.5L6.5 0.5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <div class="modal-title">
            <span>Company Name</span><br>
            <span style="font-weight: 700;font-size: 14px;line-height: 21px;">Add New Party Member</span>
        </div>
    </div>

    <div class="d-flex gap-4 ">
        <div (click)="close()" class="modal-cancel-btn">Cancel</div>
        <div (click)="save()" class="modal-save-btn">Save</div>
    </div>

</div>


<div class="modal-body">

    <div class="custom-input m-4">
        <label>Party Name</label>
        <input type="text" name="partyName"  [(ngModel)]="companyUser.name" autocomplete="off" [disabled]="this.companyUser.name" />

    </div>

    <div class="custom-input m-4" [ngClass]="formError.mobile==true?'error':''">
        <label>Phone Number</label>
        <input type="text" name="partyName" (keyup)="validateMobile($event)" [(ngModel)]="mobileString" autocomplete="off" />
    </div>
<!--
    <div class="custom-input m-4">
        <label>Select Type</label>
        <select name="" id="">
            <option class="select-type" value="">add d</option>
            <option class="select-type" value="">add d</option>
            <option class="select-type" value="">add d</option>
            <option class="select-type" value="">add d</option>
            <option class="select-type" value="">add d</option>
        </select>

    </div> -->
</div>

<div class="modal-footer"></div>
