import { Component, Input, OnInit } from '@angular/core';

import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-lottie-player',
  templateUrl: './lottie-player.component.html',
  styleUrls: ['./lottie-player.component.css']
})
export class LottiePlayerComponent implements OnInit{
  @Input() lottieUrl = '';
  @Input() width = '250px';
  @Input() height = '250px';

  public options: AnimationOptions = {};

  ngOnInit(): void {
   this.options = {
      path: this.lottieUrl,
      renderer: 'svg',
      autoplay: true,
      loop: true,
      
    };
  }

}
