<app-logo-loader [state]=loading></app-logo-loader>

<div *ngIf="!fileListUrls.length && !parent?.viewOnly" class="upload-images">
  <input (change)="onFileSelected($event)" multiple = "false" type="file" id="file" accept=".jpg, .jpeg, .png, .pdf, .DWG, .dwg, .XLSX, .xlsx, .DOCX, .docx" />
  <label class="file-input-label" for="file">
    {{hasTitle || 'Upload Bills'}}
    <img class="mx-2" src="../../../../assets/svg/upload.svg" alt="" />
  </label>
</div>
<ng-container *ngIf="fileListUrls.length">
  <div class="vstack">
    <div class="hstack justify-content-between">
      <span class="media-count">Media ({{imageCount}} Images, {{fileCount}} File)</span>
      <div class="sm-upload-images" *ngIf="!parent?.viewOnly">
        <input type="file" (change)="onFileSelected($event)" multiple = "false" id="smFile"
          accept=".jpg, .jpeg, .png, .pdf, .DWG, .dwg, .XLSX, .xlsx, .DOCX, .docx" />
        <label for="smFile" class="file-sm-input-label">
          {{hasTitle || 'Upload Bills'}}
          <img class="mx-2" src="../../../../assets/svg/upload.svg" alt="" /></label>
      </div>
    </div>
  </div>

  <div class="hstack gap-2 py-1" style="overflow-x: auto; flex-shrink: 0">
    <div *ngFor="let item of fileListUrls; let i=index"
      class="media-box my-1 mx-1 d-flex justify-content-center align-items-center" style="
        height: 60px;
        width: 60px;
        overflow: hidden;
        border-radius: 8px;
        flex-shrink: 0;
        background-color: #FCFCFC;  
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      " (click)="openFile(i)">
      <img class="img-fluid" [src]="fileExtensionExtract(item.url)" alt="" />
      <!-- <div *ngIf="item.type=='pdf'" class="vstack align-items-center justify-content-center"
        style="flex-shrink: 0;">
        <img height="40px" src="../../../../assets/svg/pdf.svg" alt="pdf">
        <span
          style="font-size: 9px; text-align: center; overflow: hidden; text-overflow: ellipsis; max-width: 80px; white-space: nowrap;">{{item.name}}</span>
      </div> -->
      <div *ngIf="!parent?.viewOnly" class="d-flex justify-content-center align-items-center pointer" (click)="removeFile(i)" style="
          position: absolute;
          top:4px;
          right: 4px;
          padding: 4px 6px;
          border-radius: 4px;
          background: #f1f1f1;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        ">
        <span style="font-size: 10px">╳</span>
      </div>
    </div>
  </div>
  <!-- <button (click)="uploadImages()">upload</button> -->
</ng-container>
