<ng-template #c>
	<div class="p-2">
		<div class="btn-group d-flex justify-content-end mb-2" role="group">
			<button type="button" class="btn btn-sm onsite-calendar-navigation" (click)="navigate(dp, -1, $event);">Prev</button>
			<button type="button" class="btn btn-sm onsite-calendar-navigation" (click)="today(dp, $event)">Today</button>
			<button type="button" class="btn btn-sm onsite-calendar-navigation" (click)="navigate(dp, 1, $event)">Next</button>
		</div>
		<div class="custom-month-grid">
			<div *ngFor="let month of dp.state.months">
				<div class="onsite-text p-1 fw-bold">{{ dp.i18n.getMonthShortName(month.month) }} {{ month.year }}</div>
				<ngb-datepicker-month class="border rounded" [month]="month" navigation="none"></ngb-datepicker-month>
			</div>
		</div>
	</div>
</ng-template>

<ngb-datepicker #dp [displayMonths]="1" [contentTemplate]="c" (click)="setDate($event)" [(ngModel)]="model" navigation="none"></ngb-datepicker>
