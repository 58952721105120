import { Pipe, PipeTransform } from '@angular/core';
import { BillingActivity } from 'src/app/interfaces/interfaces';

@Pipe({
  name: 'sortByOrder'
})
export class SortByOrderPipe implements PipeTransform {

  transform(value: BillingActivity[], ...args: unknown[]): BillingActivity[] {
    return value.sort((n1, n2) => {
      return n1.index - n2.index;
    });
  }

}
