import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NewItemDeductionComponent } from '../new-item-deduction/new-item-deduction.component';
import { HttpParams } from '@angular/common/http';
import { DeductionItem } from 'src/app/interfaces/interfaces';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';

interface Parent {
  salesorder_invoice_id?: string;
  old_deductionList?: DeductionItem[];
  invoice_total_amount?: number;
  totalDeductionAmount?: number;
}

interface AddDeductionItem {
  deduction_item_id?: string;
  amount?: number;
  salesorder_invoice_id?: string;
  project_id?: string;
}

interface EditDeductionItem {
  id?: string;
  amount?: number;
  delete?: number;
}

@Component({
  selector: 'app-add-edit-deduction',
  templateUrl: './add-edit-deduction.component.html',
  styleUrls: ['./add-edit-deduction.component.css']
})
export class AddEditDeductionComponent implements OnInit {
  @Input() parent = {} as Parent;
  private companyId: string;
  public deductionList: DeductionItem[] = [];
  public selectedDeductionItem: DeductionItem[] = [];
  public addDeductionItem: DeductionItem[] = [];
  public editDeductionItem: DeductionItem[] = [];
  public isChecked = false;
  public totalDeductionAmount = 0;
  public busy = false;
  public valueStack = { page: { next_url: '' } };

  constructor(
    private ngbOffcanvas: NgbOffcanvas,
    private adminService: AdminService,
    public authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private changeDetector: ChangeDetectorRef,
    public validatorService: ValidatorService,
    public utilService: UtilService,
    private notificationService: NotificationsService
  ) {
    this.companyId = this.authService.session.company.id;
  }

  ngOnInit(): void {
    if(this.parent.salesorder_invoice_id){
      this.listSalesInvoiceDeductionEntries();
    }else{
      this.getDeductionsList();
    }
  }

  toggleUnCheckbox(item: DeductionItem): void {
    const selectedListIndex = this.selectedDeductionItem.findIndex(ded => ded.id === item.id);
    if (selectedListIndex !== -1) {
      this.selectedDeductionItem.splice(selectedListIndex, 1);
    }

    if (item.monkey_patch_deduction_entry.id) {
      item.monkey_patch_deduction_entry.delete = 1;
      this.editDeductionItem.push(item);
    } else {
      const index = this.addDeductionItem.findIndex(ded => ded.id === item.id);
      if (index !== -1) this.addDeductionItem.splice(index, 1);
    }

    item.monkey_patch_deduction_entry.amount = 0;
    this.calculateTotalDeductionAmount();
  }

  openAddNewDeductionItem(): void {
    const ngbOffcanvasRef = this.ngbOffcanvas.open(NewItemDeductionComponent, {
      position: 'end',
      panelClass: 'offcanvas-sm',
      backdrop: 'static'
    });

    ngbOffcanvasRef.result.then((res) => {
      this.deductionList.unshift(res);
    });
  }

  getDeductionsList(): void {
    const params = new HttpParams().set('company_id', this.companyId);
    this.adminService.listDeductions(params).subscribe((res) => {
      this.updateDeductionListWithOldItems(res.deductionItems);
      this.valueStack = res;
      this.deductionList = res.deductionItems;
      this.selectedDeductionItem = this.deductionList.filter(item => item.monkey_patch_deduction_entry.amount > 0);
      this.calculateTotalDeductionAmount();
    });
  }

  

  listSalesInvoiceDeductionEntries(): void {
    const params = new HttpParams()
      .set('company_id', this.companyId)
      .set('salesorder_invoice_id', this.parent.salesorder_invoice_id || '');

    this.adminService.listSalesInvoiceDeductionEntries(params).subscribe((res: { deductionItems: DeductionItem[], page: any }) => {
      this.updateDeductionListWithOldItems(res.deductionItems);
      this.valueStack = res;
      this.deductionList = res.deductionItems;
      this.selectedDeductionItem = this.deductionList.filter(item => item.monkey_patch_deduction_entry.amount > 0);
      this.calculateTotalDeductionAmount();

    });
  }

  numberValidate(e: any): void {
    this.changeDetector.detectChanges();
  }

  onValueChange(e: any, item: DeductionItem): void {
    const formattedValue = this.validatorService.numberFormat(e, { type: 'string', decimal: true, digit:this.authService.session.country.decimal_digit || 2 });
    item.monkey_patch_deduction_entry.amount = parseFloat(formattedValue) || 0;

    if (item.monkey_patch_deduction_entry.id) {
      item.monkey_patch_deduction_entry.delete = 0;
     
      this.updateDeductionItemList(this.editDeductionItem, item);
    } else {

      this.updateDeductionItemList(this.addDeductionItem, item);
    }

    this.updateDeductionItemList(this.selectedDeductionItem, item);
    this.calculateTotalDeductionAmount();
  }

  updateDeductionItemList(list: DeductionItem[], item: DeductionItem): void {
    const index = list.findIndex(ded => ded.id === item.id);
    if (index === -1) {
      list.push(item);
    } else {
      list[index] = item;
    }
  }

  save(): void {
    if(this.parent.salesorder_invoice_id && (this.parent.invoice_total_amount && this.parent.invoice_total_amount < this.totalDeductionAmount)){
      this.notificationService.initiate({title:'Total amount should be greater than deduction amount',type: toastTypes.error});
      return;
    }
    const deductionAddEntryObjectList: AddDeductionItem[] = this.addDeductionItem.map(item => ({
      deduction_item_id: item.id,
      amount: item.monkey_patch_deduction_entry.amount,
      salesorder_invoice_id: this.parent.salesorder_invoice_id,
      project_id: this.authService.session.project.id
    }));
 
    const deductionEditEntryObjectList: EditDeductionItem[] = this.editDeductionItem.map(item => ({
      id: item.monkey_patch_deduction_entry.id,
      amount: item.monkey_patch_deduction_entry.amount,
      delete: item.monkey_patch_deduction_entry.delete,
    }));

    if (!this.parent.salesorder_invoice_id) {
      this.ngbActiveOffcanvas.close({totalDeductionAmount: this.totalDeductionAmount, deductionList: this.addDeductionItem});
    } else {
      if (deductionAddEntryObjectList.length) {
        this.addBulkDeduction(deductionAddEntryObjectList);
  
      }
      if (deductionEditEntryObjectList.length) {
        this.editBulkDeduction(deductionEditEntryObjectList);

      }
    }

    this.ngbActiveOffcanvas.close({totalDeductionAmount: this.totalDeductionAmount});
  }

  addBulkDeduction(itemList: AddDeductionItem[]): void {
   
    this.adminService.addBulkDeductionEntry(itemList).subscribe({
      next: () => this.ngbActiveOffcanvas.dismiss({totalDeductionAmount: this.totalDeductionAmount}),
      error: (err) => console.error(err)
    });
  }

  editBulkDeduction(itemList: EditDeductionItem[]): void {

    
    this.adminService.editBulkDeductionEntry(itemList).subscribe({
      next: () => this.ngbActiveOffcanvas.dismiss({totalDeductionAmount: this.totalDeductionAmount}),
      error: (err) => console.error(err)
    });
  }

  calculateTotalDeductionAmount(): void {
    this.totalDeductionAmount = this.selectedDeductionItem.reduce((total, item) => {
      return total + (item.monkey_patch_deduction_entry.amount || 0);
    }, 0);
  }

  onScroll(): void {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe({
        next: (res) => {
          this.valueStack = res;
          this.updateDeductionListWithOldItems(res.deductionItems);
          this.deductionList = this.deductionList.concat(res.deductionItems);
          this.selectedDeductionItem = this.deductionList.filter(item => item.monkey_patch_deduction_entry.amount > 0);
          this.calculateTotalDeductionAmount();
          this.busy = false;
        },
        error: () => {
          this.busy = false;
        }
      });
    }
  }

  private updateDeductionListWithOldItems(newDeductionItems: DeductionItem[]): void {
    if (this.parent.old_deductionList?.length) {
      const itemsToRemove = [];

      for (let i = 0; i < this.parent.old_deductionList.length; i++) {
        const oldItem = this.parent.old_deductionList[i];

        for (let j = 0; j < newDeductionItems.length; j++) {
          const newItem = newDeductionItems[j];

          if (oldItem.id === newItem.id) {
            newItem.monkey_patch_deduction_entry.amount = oldItem.monkey_patch_deduction_entry.amount;
            this.addDeductionItem.push(newItem);
            itemsToRemove.push(i);
          }
        }
      }

      // Remove items from old_deductionList in reverse order
      for (let i = itemsToRemove.length - 1; i >= 0; i--) {
        this.parent.old_deductionList.splice(itemsToRemove[i], 1);
      }
    }
  }

 

  goBack(){
    if(this.parent.salesorder_invoice_id){
      this.ngbActiveOffcanvas.close({totalDeductionAmount: this.parent.totalDeductionAmount, deductionList:this.parent.old_deductionList})
    }else{
      this.ngbActiveOffcanvas.close({totalDeductionAmount: this.totalDeductionAmount, deductionList: this.addDeductionItem});
    }
  }
}
