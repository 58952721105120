import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { CompanyUser } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/shared/services/validator.service';

interface GstDetails {
  gstin?: string;
  state_of_supply?: string;
  legal_business_name?: string;
  billing_address?: string
}

interface Parent {
  companyUser?: CompanyUser
}

@Component({
  selector: 'app-party-gst',
  templateUrl: './party-gst.component.html',
  styleUrls: ['./party-gst.component.css']
})
export class PartyGstComponent implements OnInit {
  @Input() parent: Parent = {
    companyUser: {} as CompanyUser
  }
  public gst_details = {} as GstDetails;
  public validGst = true;
  constructor(
    public ngbOffActiveCanvas: NgbActiveOffcanvas,
    private notificationService: NotificationsService,
    private adminService: AdminService,
    public authService:AuthService,
    public validatorService:ValidatorService
  ) {
    //
  }

  ngOnInit(): void {
    this.gst_details.gstin = this.parent.companyUser.gstin || '';
    this.gst_details.billing_address = this.parent.companyUser.billing_address || '';
    this.gst_details.legal_business_name = this.parent.companyUser.legal_business_name || '';
    this.gst_details.state_of_supply = this.parent.companyUser.state_of_supply || '';
  }
  checkGST(e) {
    if(this.authService.session.country?.tax_display_name === 'GST' && e.target.value !== '') {
      this.validGst = this.validatorService.validateGSTIN(e.target.value);
      return this.validGst;
    }else if(e.target.value === ''){
      this.validGst = true;
      return this.validGst;
    }else{
      return true;
    }
  }
  save() {
    if(!this.validGst && this.authService.session.company.gstin !== ''){
      return this.notificationService.initiate({title:`Please enter valid ${this.authService.session.country.tax_display_name} number`, type:toastTypes.error});
    }
    if (this.parent.companyUser.id) {
      this.parent.companyUser.gstin = this.gst_details.gstin;
      this.parent.companyUser.billing_address = this.gst_details.billing_address;
      this.parent.companyUser.legal_business_name = this.gst_details.legal_business_name;
      this.parent.companyUser.state_of_supply = this.gst_details.state_of_supply;
      this.adminService.editCompnayUserList(this.parent.companyUser).subscribe({
        next: res => this.ngbOffActiveCanvas.close(res),
        error: err =>console.log(err)
      })
    } else {
      this.ngbOffActiveCanvas.close(this.gst_details);
    }
  }
}
