<div style="position: relative">
  <button class="onsite-btn onsite-btn-primary">
    <span class="btn-text d-none d-sm-block">
      {{buttonState.text}}
    </span>
    <svg class="btn-plus img-fluid"  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 4.16797V15.8346" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.6665 10H16.3332" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span class=" btn-text d-sm-none"> {{buttonState.smText}}</span>
  </button>
</div>
