<div class="canvas-height">
  <app-logo-loader [state]="loading"></app-logo-loader>
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-wrap flex-column">
        <span class="f-14">New Address</span>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="canvas-cancel-btn" (click)="cancelBtn()">Cancel</div>
      <div class="canvas-save-btn" (click)="saveBtn()">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>

  <div class="canvas-body d-flex flex-column gap-3">
    <div class="custom-input">
      <label for="address_title">Address Heading</label>
      <input id="address_title" name="address_title" class="search_input" [(ngModel)]="brancchAddress.address_title" />
    </div>
    <div class="custom-input">
      <label for="gst">{{authService.session.country.tax_display_name}}</label>
      <input id="gst" name="gst" class="search_input" [(ngModel)]="brancchAddress.address_gst" />
    </div>
    <span class="f-14 fw-bold mt-2">Address Details</span>
    <div class="custom-input">
      <label for="address_line_1">Address Line 1</label>
      <input id="address_line_1" name="address_line_1" class="search_input" [(ngModel)]="brancchAddress.address_line_1" />
    </div>
    <div class="custom-input">
      <label for="address_line_2">Address Line 2</label>
      <input id="address_line_2" name="address_line_2" class="search_input" [(ngModel)]="brancchAddress.address_line_2" />
    </div>
    <div class="custom-input">
      <label for="city">City</label>
      <input id="city" name="city" class="search_input" [(ngModel)]="brancchAddress.city" />
    </div>
    <div class="custom-input">
      <label for="state">State / Province</label>
      <input id="state" name="state" class="search_input" [(ngModel)]="brancchAddress.state" />
    </div>
    <div class="custom-input">
      <label for="postal_code">ZIP / Postal Code</label>
      <input id="postal_code" name="postal_code" class="search_input" [(ngModel)]="brancchAddress.postal_code" />
    </div>
    <div class="custom-div">
        <label for="country">Country</label>
        <div ngbDropdown class="form-control" style="border: none; background: transparent;">
          <div ngbDropdownToggle class="d-flex justify-content-start align-items-center gap-2"
            (click)="selectingCountry?selectingCountry=false:selectingCountry=true">
            <div class="align-self-center"><img [src]="country.flag" width="15" alt=""></div>
            <div class="fs-8 align-self-center">{{country.country_name}}</div>
          </div>
          <div ngbDropdownMenu class="custom-menu">
            <div ngbDropdownItem class="d-flex justify-content-between p-2 gap-3 pointer dropdown-list"
              (click)="setCountry(item)" *ngFor="let item of countries">
              <div class="align-self-center w-10"><img [src]="item.flag" loading="lazy" width="20" alt=""></div>
              <div class="align-self-center text-right flex-grow-1 fs-8">{{item.country_name}}</div>
              <div class="align-self-center fs-8">+{{item.country_code}}</div>
            </div>
          </div>
        </div>
        <div>
            <ng-icon name="featherChevronDown" ></ng-icon>
        </div>
    </div>
  </div>
</div>