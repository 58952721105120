import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import {HomeComponent} from './components/home/home.component';
import { CoreModule } from './core/core.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';





import { SharedModule } from "./shared/shared.module";

import { CategoryExpenseListComponent } from './project-overview/components/category-expense-list/category-expense-list.component';
import { counterReducer } from './purchase-order/store/purchase-order.reducers';
import { AddEditDeductionComponent } from './deduction/add-edit-deduction/add-edit-deduction.component';
import { ViewDeductionComponent } from './deduction/view-deduction/view-deduction.component';
import { AddDebitNoteComponent } from './debit-note/components/add-debit-note/add-debit-note.component';
import { ViewDebitNoteComponent } from './debit-note/components/view-debit-note/view-debit-note.component';
import { AddDebiteNoteItemComponent } from './debit-note/components/add-debite-note-item/add-debite-note-item.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { ZohoRedirectUrlComponent } from './components/other-components/zoho-redirect-url.component';
const config: SocketIoConfig = { url: 'https://testweb.onsiteteams.com', options: {} };

// const config: SocketIoConfig = { url: 'http://localhost:4000', options: {} };

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CategoryExpenseListComponent,
        AddEditDeductionComponent,
        ViewDeductionComponent,
        AddDebitNoteComponent,
        ViewDebitNoteComponent,
        AddDebiteNoteItemComponent,
        ZohoRedirectUrlComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        CoreModule,
        SocketIoModule.forRoot(config),
        AppRoutingModule,
        InfiniteScrollModule,
        NgbModule,
        
        SharedModule,
        StoreModule.forRoot({ company: counterReducer }),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        GoogleMapsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule
    ]
})
export class AppModule { }
