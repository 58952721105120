  import { HttpParams } from '@angular/common/http';
  import { Component, Input, OnInit } from '@angular/core';
  import {
    NgbActiveModal,
    NgbModal,
    NgbOffcanvas,
  } from '@ng-bootstrap/ng-bootstrap';
  import { AdminService } from 'src/app/core/services/admin.service';
  import { AuthService } from 'src/app/core/services/auth.service';
  import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
  import { Bank, Company, CompanyBank, CompanyConfig, CompanyUser, FileUploadOptions, Member, Project } from 'src/app/interfaces/interfaces';
  import { UtilService } from 'src/app/shared/services/util.service';
  import { ImgEditModalComponent } from '../img-edit-modal/img-edit-modal.component';
  import { AddNewAccountComponent } from 'src/app/company/components/add-new-account/add-new-account.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';



  @Component({
    selector: 'app-setting-company',
    templateUrl: './setting-company.component.html',
    styleUrls: ['./setting-company.component.css'],

  })
  export class SettingCompanyComponent implements OnInit {

    @Input() data;
    public inputDisableBank = -1;
    public inputDisableUPI = -1;
    public tempImg;
    public componentTab = 'companyDetails';
    public loading = false;
    public companySearchBox = false;
    public manageAccessOptionsShow = -1;
    newManageAccessOptionsShow = false;
    allowNewEntries = false;
    allowEditingOldEntries = false;
    public accessBtnActive = '';
    public accessBox = false;
    public members = [] as Member[];
    public member = {} as Member;
    public project = {} as Project;
    public companyUsers = [] as CompanyUser[];
    public companyUser = {} as CompanyUser;
    public projectStatusBox = false;
    public paymentDetailType = '' //upi or bank
    public allowed_features = [];
    public edit_locked_hours:number;
    public add_locked_hours:number;
    public company = {} as Company;
    public companyBankAccount = {} as CompanyBank;
    public companyBankAccounts = [] as CompanyBank[];
    public companyBanks = [] as Bank[];
    public busy = false;
    public busybank = false;
    public bank = {} as Bank;
    public editUpi:string;
    public selectedBoxIndex:number;
    openBoxStatus = false;
    categoryList = [];
    companyConfig = {} as CompanyConfig;
    selectedConstructionType = [];
    isSelectOpen = false;
    public valueStack = {
      page: { next_url: "" },
    };
    constructor(
      public activeModal: NgbActiveModal,
      public modalService: NgbModal,
      public authService: AuthService,
      private adminService: AdminService,
      public utilService: UtilService,
      public notificationService: NotificationsService,
      public ngbOffcanvas: NgbOffcanvas,
      public title: Title,
      public ngbModal: NgbModal,    
      private router: Router
      ) {
        title.setTitle("Company Setting");

    }

    getBankUrl(bank_code:string):string{
      let bancCode= '';
      if(bank_code?.length >= 4){
        bancCode = bank_code.slice(0, 4);
      }
      return `https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/${bancCode?.toUpperCase()}_LOGO.jpg`
    }

    ngOnInit(): void {
      if(this.authService.hasAccess(this.authService.companyUserPolicy.Comapny_details)){
        this.getCompanyDetails();
        this.listCompanyBank();
        this.edit_locked_hours = this.company.edit_locked_hours;
        this.add_locked_hours = this.company.add_locked_hours;
      }
    }
    companyOverview() {
      this.loading = true;
      let getConstructionType, getCompanyCOnfig;
      let stopExecution = () => {
        if (this.companyConfig.construction_category_ids?.length > 0 && this.categoryList?.length > 0) {
          this.selectedConstructionType = this.companyConfig.construction_category_ids.map(id => this.categoryList.find(obj => obj.id === id));
        }
        this.loading = false;
      };

      let qp = new HttpParams();
      qp = qp.set('type', 'construction_type');
      this.adminService.categoryList(qp).subscribe(res => {
        this.categoryList = res.categories || [];
        getConstructionType = true;
        if (getCompanyCOnfig) stopExecution();
      }, err => console.log(err));

      this.adminService.getCompanyConfiguration(this.company.id).subscribe({
        next: (res) => {
          this.companyConfig = res as CompanyConfig || {};
          getCompanyCOnfig = true;
          if (getConstructionType) stopExecution();
        }
      })

    }

    getCompanyDetails() {
      this.company = JSON.parse(JSON.stringify(this.authService.session.company));
    }
    

    handleSelectedType(e, construction_type) {
      if (e.target.checked) {
        this.selectedConstructionType.push(construction_type);
        if(this.companyConfig.construction_category_ids == null){
          this.companyConfig.construction_category_ids = []
        }
        this.companyConfig.construction_category_ids.push(construction_type.id);
      } else {
        this.removeConstructionType(construction_type.id);
      }
    }

    removeConstructionType(id) {
      const index = this.companyConfig.construction_category_ids.indexOf(id);
      if (index > -1) {
        this.selectedConstructionType.splice(index, 1);
        this.companyConfig.construction_category_ids.splice(index, 1);
      }
    }

    listCompanyBank() {
      let qp = new HttpParams();
      qp = qp.set('company_id', this.company.id);
      this.adminService.listCompanyBank(qp).subscribe(res => {
        this.companyBanks = res.data || [] as Bank;
        this.valueStack = res;
      }, err => console.log(err))
    }

    editCompanyUpi(flag, item?: any, e?: any) {
      const prev_upi = item;
      if(prev_upi !== e.target.value){
        const index = this.company.upi_ids.indexOf(prev_upi);
        this.company.upi_ids[index] = e.target.value;
        this.editCompany();
      }
    }

    editCompany() {
      if(this.busy) return
      this.adminService.editCompany(this.company).subscribe(res => {
        this.authService.session.company = res || {} as Company;
        this.inputDisableUPI = -1;
        this.paymentDetailType = '';
        this.activeModal.close();
      }, err => console.log(err))
    }

    editCompanyConfig() {
      if (this.busy) return
      this.adminService.editCompanyBusinessOverview(this.companyConfig).subscribe(res => {
        this.activeModal.close();
      }, err => console.log(err))
    }

    editCompanyBackLock(){

      this.adminService.editCompanyBackLock({...this.company, edit_locked_hours:this.edit_locked_hours
      }).subscribe(res =>{
        this.authService.saveCompany({...this.company, edit_locked_hours:this.edit_locked_hours});
        this.notificationService.initiate({title:"Successfully Added", type:toastTypes.success});
        this.activeModal.dismiss();
        this.allowEditingOldEntries = false;

      }, err => {
        this.notificationService.initiate({title:"Something went wrong!", type:toastTypes.error})
      })
    }

    AddCompanyBackLock(){

      this.adminService.addCompanyBackLock({...this.company, add_locked_hours:this.add_locked_hours}).subscribe({
        next:(res)=>{
          this.authService.saveCompany({...this.company, add_locked_hours:this.add_locked_hours});
          this.notificationService.initiate({title:"Successfully Added", type:toastTypes.success});
          this.activeModal.dismiss();
          this.allowNewEntries = false;
        },
        error:(error)=>{
          this.notificationService.initiate({title:"Something went wrong!", type:toastTypes.error})
        }
      })
    }

    setUpi_id(e) {
      const upi_id = e.target.value;
      const check_upi_id_exist = this.company.upi_ids.includes(upi_id);
      if (check_upi_id_exist) {
        return alert('UPI Id already exist');
      }
      if (upi_id) {
        this.company.upi_ids.push(upi_id);
      }

      e.target.value = '';
    }

    setLockBackDate(e) {
      this.edit_locked_hours = e.target.value * 24;
    }

    setLockBackAddDate(e){
      this.add_locked_hours = e.target.value*24;
    }


    replaceImg(event) {
      this.busy = true;
      const files: FileList = event.target.files;
      if (files.length > 0) {
        const fileName = files[0].name;
        const fileType = files[0].type;
        const extn = fileName.substring(fileName.lastIndexOf('.'));
        const fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
          const target = fileLoadedEvent.target as { result?: string };
          const modalRef = this.modalService.open(ImgEditModalComponent, { backdrop: 'static' });
          modalRef.componentInstance.data = target.result;
          modalRef.result.then(data => {
            const newfile = new File([data.blob], fileName, { type: fileType, lastModified: Date.now() });
            const fileUploadOptions = {
              files: [newfile],
              contentType: fileType,
              extension: extn,
              folder: 'company'
            } as FileUploadOptions;
            this.utilService.s3attachAnyFIle(fileUploadOptions).subscribe(res => {
              this.loading = false;
              this.company.logo = res.url;
              this.busy = false;
            }, err => {
              this.loading = false;
            });
          }).catch(err => {
            if(err){
              location.reload();
            }
          });
        };
        fileReader.readAsDataURL(files[0]);
      }
    }

    selectFileInput() {
      document.getElementById('picInput').click();
    }

    addAccountCanvas(hasRequestType: 'add' | 'edit', bank?:Bank){
      this.openBoxStatus = false;
      const offcanvasRef = this.ngbOffcanvas.open(AddNewAccountComponent,{
        ariaLabelledBy:"offcanvas-basic-title",
        position:'end',
        panelClass:'offcanvas-sm bank-canvas',
        backdropClass:'bank-canvas-backdrop'
      });

      offcanvasRef.componentInstance.parent = {
        hasRequestType:hasRequestType,
        hasCompanyBank:bank
      }


      offcanvasRef.result.then(res=>{
        this.listCompanyBank();
      }, rej=>{console.log(rej)})

    }

    openOptionsBox(index:number){
      if(this.selectedBoxIndex != index && this.openBoxStatus){
        return this.selectedBoxIndex = index;
      }
      this.openBoxStatus = !this.openBoxStatus;
      this.selectedBoxIndex = index;
    }

    setPrimaryCompanyBankAccount(id){
      this.adminService.setPrimaryCompanyBankAccount({id:id}).subscribe({
        next:res=>{

          for(const item of this.companyBanks){
            if(item.primary == 1){
              item.primary = 0
            }
            if(item.id == id){
              item.primary = 1
            }
          }
           this.openBoxStatus = false;
        },
        error:err=>{
           console.log(err)
        }
      })
    }

    deleteCompanyBankAccount(id: string) {
      const deleteConfirmationModal = this.modalService.open(ConfirmModalComponent);
      deleteConfirmationModal.componentInstance.data = { modalTitle: "Warning", modalContent: "Are you sure to delete this item?" };
      deleteConfirmationModal.result.then(fulfilled => {
        this.adminService.deleteCompanyBankAccount(id).subscribe({
          next:ref=>{
            this.openBoxStatus = false;
            this.companyBanks = this.companyBanks.filter(obj=>obj.id !== ref.id);
          },
          error:err=>{
            console.log(err);
          }
        })
      }, reject => {
        //
      })
    }

    leaveCompany() {
      const confirmModal = this.ngbModal.open(ConfirmModalComponent);
      confirmModal.componentInstance.data = { modalTitle: 'Delete Confirmation', modalContent: 'Are you sure?' };
      confirmModal.result.then(onfulfiled => {
        this.loading = true;
        this.adminService.leaveCompany(this.authService.session.company.id).subscribe(res => {
          this.getWorkableCompanyList()
        }, err => {
          this.loading = false;
          console.log(err);
        })
      }, onrejected => console.log(onrejected));
    }

    getWorkableCompanyList(){
      this.authService.getWorkableCompanyList().subscribe({
        next:(res)=>{
          this.loading = false;
          this.authService.session.companies = res?.Companies || [] as Company[];
          this.authService.companyListUpdated.next(true);
          this.router.navigate([`/c/${this.authService.session.companies[0].id}/d/home`]);
          this.activeModal.dismiss();
        },
        error:(error)=>{
          this.loading = false;
          console.log('error',error);
        }
      })
    }

    onScroll() {
      const url = this.valueStack.page.next_url;
      if (!this.busybank) {
        this.busybank = true;
        this.utilService.getByUrl(url).subscribe({
          next: (res) => {
            this.valueStack = res;
            // this.images = this.images.concat(res?.images);
            // this.busyImages = false;
            this.companyBanks = this.companyBanks.concat(res?.data)
            this.busybank = false;
          },
          error: (err) => {
            this.busybank = false;
            this.valueStack = {
              page: { next_url: "" },
            };
          },
        });
      }
    }





  }
