import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'globalCurrency'
})
export class GlobalCurrencyPipe implements PipeTransform {
constructor(
  private authService:AuthService
  ){}
  transform(amount: any, ...args: unknown[]) {
    const currency = this.authService.session.company.currency || '₹';
    const decimalRegex = new RegExp(`^-?\\d+(?:\\.\\d{0,${this.authService.session.country.decimal_digit || 2}})?`);
    amount = parseFloat(amount) || null;
    if (amount == null) return currency + ' 0';
    amount = args[0] == 'exact-value' ? amount : Math.abs(amount);
    amount = Number(amount.toString().match(decimalRegex)[0]);
    return currency + ' ' + (amount ? new Intl.NumberFormat(currency === '₹' ? 'en-IN' : undefined).format(amount) : '');
  }
}
