<div class=" {{ authService.session.isActive ? '' : 'header__guest' }} float-end">
  <div *ngIf="authService.session.isActive" ngbDropdown class="">
    <div class="d-flex d-flex-rows align-items-center pointer" id="dropdownBasic1" ngbDropdownToggle>
      <div style="height: 19px; width: 19px" class="mt-1 img-wrapper">
        <img src="{{ authService.session.profile.profile_pic }}"
          onError="this.src='../../../assets/images/profile.png';" />
      </div>
    </div>

    <div ngbDropdownMenu class="animate slideIn">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center gap-2 pb-2">
          <div class="mt-1 img-wrapper">
            <img src="{{ authService.session.profile.profile_pic }}"
              onError="this.src='../../../assets/images/profile.png';" />
          </div>
          <div style="height: 38px; width: 38px" class="d-flex flex-column mt-1">
            <span style="white-space: nowrap" class="f-14">
              {{ authService.session.profile.name | truncate : "20 " }}
            </span>
            <span class="f-12 disabled-font">
              {{
              authService.session.profile.mobile
              ? authService.session.profile.mobile
              : "--"
              }}
            </span>
          </div>
        </div>
      </div>
      <hr class="my-1" />
      <div class="d-flex flex-column gap-2 mt-2">
        <button *ngIf="authService.session.profile.admin == 1" routerLink="/admin/home"
          class="text-icon-btn w-100 dropdown-item">
          <div>
            <ng-icon class="ngIcon" name="featherAirplay"></ng-icon>
          </div>
          <span class="f-14 mt-1">Admin Control</span>
        </button>
        <button [hidden]="authService.session.profile.admin == 1" *ngIf="primaryProfile.admin == 1"
          (click)="switchAccount()" class="text-icon-btn w-100 dropdown-item">
          <div>
            <ng-icon class="ngIcon" name="featherShuffle"></ng-icon>
          </div>
          <span class="f-14 mt-1">Switch Acc</span>
        </button>
        <button *ngIf="!authService.session.profile.is_access_granted" (click)="toggleAccess()"
          class="text-icon-btn w-100 dropdown-item">
          <div>
            <ng-icon class="ngIcon" name="featherUserCheck"></ng-icon>
          </div>
          <span class="f-14 mt-1">Grant access to Onsite support</span>
        </button>
        <button *ngIf="authService.session.profile.is_access_granted" (click)="toggleAccess()"
          class="text-icon-btn w-100 dropdown-item">
          <div>
            <ng-icon class="ngIcon" name="featherUserX"></ng-icon>
          </div>
          <span class="f-14 mt-1">Block Access</span>
        </button>

        <button (click)="logout()" *ngIf="authService.session.isActive" class="text-icon-btn w-100 dropdown-item">
          <div>
            <ng-icon class="ngIcon" name="featherLogOut"></ng-icon>
          </div>
          <span class="f-14 mt-1">Log Out</span>
        </button>
      </div>
    </div>
  </div>
</div>