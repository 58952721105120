<div class="d-flex flex-column" style="min-height:350px ;">
  <div class="filter-header d-flex justify-content-between">
    <div>
      <img src="../../../../../assets/svg/filter.svg" alt="">
    </div>
    <div>
      <img (click)="activeModal.dismiss()" class="pointer" src="../../../../../assets/svg/cross.svg" alt="">
    </div>
  </div>

  <div style="border-bottom: 1px solid #E5E7F3;"></div>

  <div class="filter-body d-flex">

    <div [ngSwitch]="selectedFilterSwitch" class="d-flex flex-column w-50" style="height: 350px; overflow-y: scroll; overflow-x: hidden;">
      <div class="report-selector pointer" [ngClass]="selectedFilterSwitch=='date'?'report-selector-active':''" (click)="selectOption('date')" *ngIf="this.companyReport.filterTypes.includes('date')">
        <div>
          <span class="report-selector-subtitle" *ngIf="!startDate && !endDate">Date not selected</span>
          <span class="report-selector-subtitle">{{startDate | date : 'MMM d'}} <span *ngIf="endDate">-</span> {{endDate |
            date : 'MMM d, y'}}</span><br>
          <div class="report-selector-title">{{report_duration_title}}</div>
        </div>
      </div>

      <div class="report-selector pointer" [ngClass]="selectedFilterSwitch=='project'?'report-selector-active':''" (click)="selectOption('project')" *ngIf="this.companyReport.filterTypes.includes('project')">
        <div>
          <span class="report-selector-subtitle" *ngIf="project.id">{{project.name}}</span>
          <span class="report-selector-subtitle" *ngIf="!project.id">Project not selected</span>
          <br>
          <div class="report-selector-title">Project</div>
        </div>
      </div>

      <div class="report-selector pointer" [ngClass]="selectedFilterSwitch=='party'?'report-selector-active':''" (click)="selectOption('party')" *ngIf="this.companyReport.filterTypes.includes('party')">
        <div>
          <span class="report-selector-subtitle" *ngIf="companyUser.id">{{companyUser.name}}</span>
          <span class="report-selector-subtitle" *ngIf="!companyUser.id">Company not selected</span>
          <br>
          <div class="report-selector-title">Party/Company User</div>
        </div>
      </div>

    </div>

    <div class="w-50" [ngSwitch]="selectedFilterSwitch" style="height: 350px; overflow-y: scroll; overflow-x: hidden; padding: 0 0 0 20px;">
      <ng-template [ngSwitchCase]="'date'">
        <ng-container *ngFor="let d of dateSelector">
          <button class="dropdown-item" (click)="dateRangeSet(d.key)">{{d.value}}</button>
        </ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'project'">
        <ng-container *ngFor="let project of projects">
          <button class="dropdown-item" (click)="setProject(project)">{{project.name}}</button>
        </ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'party'">
        <ng-container *ngFor="let companyUser of companyUsers">
          <button class="dropdown-item" (click)="setCompanyUser(companyUser)">{{companyUser.name}}</button>
        </ng-container>
      </ng-template>
    </div>

  </div>

  <div>
  </div>
  <div class="mt-auto" style="border-bottom: 1px solid #E5E7F3;"></div>
  <div class="filter-footer d-flex justify-content-between align-items-center ">
    <div>
      <span class="modal-btn btn-clear pointer" (click)="clearFilter()">
        Clear Filter
      </span>
    </div>
    <div>
      <span (click)="save()" class="modal-btn btn-apply pointer">
        Apply
      </span>
    </div>
  </div>
</div>
