<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-2">
      <button (click)="goBack()" class="btn p-0" style="background-color: transparent; border: none; color: #fff;">
        <ng-icon class="d-flex justify-content-center align-items-center" name="featherChevronLeft">
        </ng-icon></button>
      <div class="canvas-title d-flex flex-column align-items-start">
        <span class="f-12">Sales Invoice</span>

      </div>
    </div>

    <div class="d-flex justify-content-end gap-4">

      <div (click)="save()" class="canvas-save-btn" type="button"
      > Save </div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div style="height: calc(100dvh - 69px);" class="canvas-body d-flex flex-column py-4">
    <div *ngIf="authService.hasAccess(authService.companyUserPolicy.Add_Deduction_Item)"
      class="d-flex justify-content-between align-items-center w-100 px-4">
      <span class="f-14">Items</span>
      <button (click)="openAddNewDeductionItem()" class="btn f-12" style="border: none; color: #5F44CE;">+ Add
        Deductions</button>
    </div>
    <!-- <div class="deduction-items-container py-2">
            <div class="d-flex flex-column">
              
            </div>
            <div class="empty-item-container d-flex justify-content-center align-items-center" >
              <span class="f-14 disabled-font">No Deductions added</span>
            </div>
        </div> -->
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
      [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page.next_url == ''" class="px-4"
      style="flex: 1;  overflow-y: auto;">
      <ng-container *ngFor="let item of deductionList">
        <div class="deduction-item-tile d-flex justify-content-between my-3">
          <div class="d-flex align-items-center gap-4">
            <input type="checkbox" class="checkbox-custom" [checked]="item?.monkey_patch_deduction_entry.amount > 0"
              [disabled]="!(item?.monkey_patch_deduction_entry.amount > 0)" (change)="toggleUnCheckbox(item)" />
            <span class="f-14 fw-normal">{{item?.name}}</span>
          </div>



          <div class="custom-input">
            <label for="amount">Amount</label>
            <input [(ngModel)]="item?.monkey_patch_deduction_entry.amount" in (input)="onValueChange($event,item)"
              type="text" id="amount" placeholder="0">
          </div>
        </div>
      </ng-container>
    </div>

    <div class="deduction-footer py-2 d-flex justify-content-between px-4">
      <span class="f-14">Total</span>
      <span class="f-14"> {{totalDeductionAmount | globalCurrency}}</span>
    </div>
  </div>
</div>