<div class="canvas-height d-flex flex-column">
    <app-logo-loader [state]="loading"></app-logo-loader>
    <div class="offcanvas-header d-flex align-items-center px-4">
        <div class="canvas-header-left d-flex align-items-center gap-4">
            <app-back-button></app-back-button>
            <div class="canvas-title d-flex align-items-center gap-2 ">
                <span class="f-14">Custom Fields</span>
                <!-- <button (click)="addNewCustomField()" style="border: none; background-color:transparent" type="button"
                    class="btn icon-btn">
                    <ng-icon style="color: #fff;" name="featherSettings"></ng-icon>
                </button> -->
            </div>
        </div>
        <div class="d-flex gap-4">
            <div (click)="ngbActiveOffCanvas.dismiss()" class="canvas-cancel-btn">Cancel</div>
            <div (click)="save()" class="canvas-save-btn">Save</div>
        </div>
    </div>
    <div style="flex: 1;" class="canvas-body">
        <ng-container *ngFor="let customField of customFields; let i = index">
            <div class="w-100">
                <button (click)="toggleCollapse(i)" [attr.aria-expanded]="customField.isCollapsed"
                    aria-controls="collapseExample" class="text-btn btn py-1">
                    <span class="f-12 disabled-font">+ {{customField?.name}}</span>
                </button>
                <div #collapse="ngbCollapse" [ngbCollapse]="customField.isCollapsed"
                    (ngbCollapseChange)="onCollapseChange($event, i)" class="w-100">
                    <textarea *ngIf="customField.data_type === 'text'" tabindex="0"
                        [(ngModel)]="customField.default_value" autofocus class="f-12 custom-text-area" rows="3"
                        name="default-value" id=""></textarea>

                    <input *ngIf="customField.data_type === 'number'" type="text" tabindex="0"
                        [(ngModel)]="customField.default_value" (input)="customField.default_value = validatorService.numberFormat($event, {
          type: 'string',
          decimal: true,
          digit: 2
        })" s class="f-12 custom-input" name="default-value" id="">

                    <div class="d-flex gap-2" *ngIf="customField.data_type === 'date'">
                        <div (click)="openDatePicker(customField)" style="width: 90%; height: fit-content !important;"
                            class="custom-div d-flex p-1">
                            <span *ngIf="!customField.default_value" class="f-12 disabled-font">dd/mm/yyyy</span>
                            <span *ngIf="customField.default_value" class="f-12">{{customField.default_value
                                | date:'dd/MM/yyyy'}}</span>
                            <ng-icon name="feather-calendar"
                                class="disabled-fon d-flex align-items-center justify-content-center"
                                style="transform: scale(0.9);"></ng-icon>
                        </div>
                        <button style="color: #5f44ce; background-color: #f8f8f8;"
                            (click)="customField.default_value = ''" type="button" class="btn f-12">
                            clear</button>
                    </div>



                </div>
            </div>
        </ng-container>
    </div>
</div>