  import { Component } from '@angular/core';
  import { AuthService } from 'src/app/core/services/auth.service';

  @Component({
    selector: 'app-server-down-modal',
    templateUrl: './server-down-modal.component.html',
    styleUrls: ['./server-down-modal.component.css']
  })
  export class ServerDownModalComponent {
    public loading = false;
    public valueStack = {
      page: { next_url: "" },
      debit_credit: { in_amount: 0, out_amount: 0 },
    };

  constructor(private authService:AuthService){

  }

  handleRefresh(){
    this.loading = true;
    this.authService.getPlatformInfo().subscribe({
      next:(res)=>{
        this.loading = false;
        // this.valueStack = res;
        console.log("res",res);
        this.authService.setServerDownStatus(false);

      },
      error:(error)=>{
        console.log(error);
        this.loading = false;
      }
    })
  }
  }
