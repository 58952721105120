import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { AdminService } from "src/app/core/services/admin.service";
import { Project } from "src/app/interfaces/interfaces";
import { FilterModalComponent } from "../filter-modal/filter-modal.component";
import { AuthService } from "src/app/core/services/auth.service";
import { Title } from "@angular/platform-browser";


@Component({
  selector: "app-report-modal",
  templateUrl: "./report-modal.component.html",
  styleUrls: ["./report-modal.component.css"],
})
export class ReportModalComponent implements OnInit {
  @Input() parent: any;
  public project = {} as Project;
  party_id: string;
  dateRange = "";
  loading = false;
  public filter = false;
  public dataFromFilter: any = {};
  public filterParams = new HttpParams();
  public reportType = "Not Selected";
  public report = [];
  public downloadPdfBtn = false;
  public downloadCsvBtn = false;
  public dateSelector = [
    { key: "all", value: "All" },
    { key: "today", value: "Today" },
    { key: "lastWeek", value: "Last Week (Mon - Sun)" },
    { key: "lastMonth", value: "Last Month" },
    { key: "thisWeek", value: "This Week (Mon - Today)" },
    { key: "thisMonth", value: "This Month" },
    { key: "custom", value: "Custom" },
  ];
  public startDate?: Date;
  public endDate?: Date;
  public report_duration_title?: string = "All Time Date";
  public qp;
  public datePicker: Object = {
    fromDate: new Date(),
    toDate: new Date(),
    show: false
  }
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private adminService: AdminService,
    private authService: AuthService,
    public title: Title,
  ) {
    title.setTitle("Project Report");
    this.report = [
      {
        name: "Party",
        report_type: [
          // { name: "Party Transaction", pdf: true, value: "partyTransaction" },
          { name: "Party Balance", pdf: true, value: "partyBalance" },
        ],
      },
      {
        name: "Party Project Transaction",
        report_type: [
          // { name: "Party Transaction", pdf: true, value: "partyTransaction" },
          { name: "Party Project Transaction", pdf: true,csv:false, value: "partyTransaction" },
        ],
      },
      {
        name: "Equipment Stock",
        report_type: [
          // { name: "Party Transaction", pdf: true, value: "partyTransaction" },
          { name: "Equipment Stock", pdf: true,csv:false, value: "equipmentStock" },
        ],
      },
      {
        name: "Attendance",
        report_type: [
          { name: "Salary", pdf: true, csv: false, value: "salary" },
          {
            name: "Attendance",
            pdf: true,
            csv: true,
            value: "attendance",
          },
        ],
      },
      {
        name: "single-attendance",
        report_type: [
          { name: "Salary", pdf: true, csv: false, value: "single-salary" },
          {
            name: "Attendance",
            pdf: true,
            csv: false,
            value: "single-attendance",
          },
        ],
      },
      {
        name: "Material",
        report_type: [
          {
            name: "Material Inventory",
            pdf: true,
            csv: false,
            value: "material_inventory",
          },
          {
            name: "Material Purchase",
            pdf: true,
            csv: true,
            value: "material_purchased",
          },
        ],
      },
      {
        name: "Payment",
        report_type: [
          { name: "Payment", pdf: true, csv: true, value: "payment" },
          { name: "Summary", pdf: true, csv: false, value: "paymentSummary" }
        ],
      },
      {
        name: "Task",
        report_type: [
          {
            name: "DPR",
            pdf: true,
            csv: false,
            value: "taskPDF",
          },

        ],
      },
      // {
      //   name: "Attendance",
      //   report_type: [{ name: "Attendance", file_type: ["pdf", "csv"] }],
      //   value: "attendance",
      // },
    ];
  }

  ngOnInit(): void {
    this.project = this.parent.project;
    if (!this.project) {
      this.project = this.authService.session.project;
    }
    this.report.map(i => {
      if (i.name == this.parent.page.name) {
        this.reportType = i.report_type[0].value;
        this.setDownloadBtn(i.report_type[0])
      }
    })
  }


  setDownloadBtn(report_type) {
    this.downloadPdfBtn = report_type.pdf;
    this.downloadCsvBtn = report_type.csv;
  }
  dismiss(str) {
    this.activeModal.dismiss("Hi");
  }
  dateRangeSet(e) {
    this.dateRange = e;
    const cDate = new Date();
    let d;
    let date = new Date();
    switch (e) {
      case "custom":
        this.datePicker['show'] = true;
        break;

      case "all":
        this.report_duration_title = "All Time";
        this.startDate = null;
        this.endDate = null;
        break;

      case "today":
        this.startDate = cDate;
        this.endDate = cDate;
        this.report_duration_title = "Today";
        break;

      case "lastWeek":
        this.report_duration_title = "Last Week (Mon-Sun)";
        const prevMonday = new Date();
        prevMonday.setDate(
          prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
        );
        d = new Date().getDate() - prevMonday.getDate();
        d < 7 ? prevMonday.setDate(prevMonday.getDate() - 7) : prevMonday;
        this.startDate = prevMonday;
        this.endDate = new Date(prevMonday);
        this.endDate.setDate(prevMonday.getDate() + 6);
        break;

      case "lastMonth":
        this.report_duration_title = "Last Month";
        this.startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        this.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        break;

      case "thisWeek":
        this.report_duration_title = "This Week";
        const main = new Date();
        d = main.getDay();
        const mon = new Date(main);
        this.startDate = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
        this.endDate = new Date();
        break;

      case "thisMonth":
        this.report_duration_title = "This Month";
        this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.endDate = new Date();
        break;
    }
    if (this.startDate) {
      const date = new Date(this.startDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (this.endDate) {
      const date = new Date(this.endDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }

  open() {
    const i = this.modalService.open(FilterModalComponent, { size: "lg" });
    i.componentInstance.companyReport = { 'filterTypes': ['party', 'project'], 'project': this.project };

    i.result.then((res) => (this.qp = res.qp));
  }

  downloadPDF() {
    this.filterParams = new HttpParams();
    this.loading = true;
    this.startDate
      ? (this.filterParams = this.filterParams.set(
        "start_date",
        this.startDate.toISOString()
      ))
      : null;
    this.endDate
      ? (this.filterParams = this.filterParams.set(
        "end_date",
        this.endDate.toISOString()
      ))
      : null;
    switch (this.reportType) {
      case "salary":
        this.attendanceSalaryReport();
        break;

      case "single-salary":
        this.filterParams = this.filterParams.set('workforcestock_id', this.parent.page.workforceStockId)
        this.attendanceSalaryReport();
        break;
      case "attendance":
        this.attendanceReport();
        break;
      case "single-attendance":
        this.filterParams = this.filterParams.set('role', 'admin')
        this.filterParams = this.filterParams.set('project_id', this.project.id)
        this.filterParams = this.filterParams.set('workforcestock_id', this.parent.page.workforceStockId);
        this.attendanceOperation();
        break;
      case "attendanceOperation":
        this.attendanceOperation();
        break;
      case "payment":
        this.payment();
        break;
      case "paymentSummary":
        this.paymentSummary();
        break;
      case "material_purchased":
        this.material_purchased();
        break;
      case "material_inventory":
        this.material_inventory();
        break;
      case "partyTransaction":
        this.partyTransaction();
        break;
      case "equipmentStock":
        this.equipmentStock();
        break;
      case "partyBalance":
        this.partyBalance();
        break;
      case "taskPDF":
        this.taskPDF();
        break;
      default:
        // alert("CSV Not Available selected filter,");
        this.loading = false;
        break;
    }
  }

  downloadCSV() {
    this.filterParams = new HttpParams();
    this.loading = true;
    this.startDate
      ? (this.filterParams = this.filterParams.set(
        "start_date",
        this.startDate.toISOString()
      ))
      : null;
    this.endDate
      ? (this.filterParams = this.filterParams.set(
        "end_date",
        this.endDate.toISOString()
      ))
      : null;

    switch (this.reportType) {
      case "attendance":
        this.csv_attendanceReport();
        break;
      case "single-attendance":
        this.filterParams = this.filterParams.set('workforcestock_id', this.parent.page.workforceStockId)
        this.csv_attendanceReport();
        break;
      case "payment":
        this.csv_payment();
        break;

      case "material_purchased":
        this.csv_material();
        break;
      default:
        alert("CSV Not Available selected filter,");
        this.loading = false;
        break;
    }
    // if (this.reportType == "attendance") this.csv_attendanceReport();
    // if (this.reportType == "payment") this.csv_payment();
    // if (this.reportType == "material_purchased") this.csv_material();
    // if (this.reportType == "material_inventory") this.csv_material_inventory();
    // if (this.reportType == "partyTransaction") this.csv_partyTransaction();
  }
  attendanceSalaryReport() {
    this.adminService
      .generateAttendanceSalary(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }

  singleAttendanceSalaryReport() {
    this.filterParams = this.filterParams.set('workforcestock_id', this.parent.page.workforceStockId)
    this.adminService
      .generateAttendanceSalary(this.project.id, this.filterParams)
      .subscribe(res => {
        this.loading = false;
        window.open(res.url, "_blank");
      }, err => {
        this.loading = false;
        alert("Something went wrong")
      })
  }

  attendanceReport() {
    this.adminService
      .generateAttendanceOperation(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }

  singleAttendanceOperation() {
    this.filterParams = this.filterParams.set('role', 'admin')
    this.filterParams = this.filterParams.set('project_id', this.project.id)
    this.filterParams = this.filterParams.set('workforcestock_id', this.parent.page.workforceStockId);

    this.adminService
      .generateAttendanceOperation(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }

  attendanceOperation() {
    this.adminService
      .generateAttendanceOperation(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }

  payment() {
    this.adminService.generatePaymentReport(this.project.id, this.filterParams).subscribe((res) => {
      this.loading = false;
      window.open(res.url, "_blank");
    }, (err) => {
      console.log(err);
      this.loading = false
    });
  }

  paymentSummary() {
    this.adminService.generatePaymentSummaryReport(this.project?.id, this.filterParams).subscribe({
      next: (res) => {
        this.loading = false;
        window.open(res.url, "_blank")
      },
      error: (error) => {
        console.log(error),
        this.loading = false
      }
    })
  }
  paymentTrade() {
    this.adminService.generateTradePaymentReport(this.project.id, this.filterParams).subscribe((res) => {
      this.loading = false;
      const msgChan = new MessageChannel();
      window.open(res.url, "_blank");
    }, (err) => {
      console.log(err);
      this.loading = false
    });
  }
  paymentCategory() {
    this.adminService.generateCategoryPaymentReport(this.project.id, this.filterParams).subscribe((res) => {
      this.loading = false;
      window.open(res.url, "_blank");
    }, (err) => {
      console.log(err);
      this.loading = false
    });
  }
  material_purchased() {
    this.adminService
      .generateMaterialReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }
  material_inventory() {
    this.adminService
      .generateMaterialInventoryReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Somthing wrong happen");
        }
      );
  }

  partyTransaction() {
    this.filterParams = this.filterParams.set('project_id', this.project.id)
    this.filterParams = this.filterParams.set('party_company_user_id', this.parent.page.party_company_user_id);
      this.adminService
        .generatePDFPaymentTransaction(this.project.id, this.filterParams)
        .subscribe(
          (res) => {
            this.loading = false;
            window.open(res.url, "_blank");
          },
          (err) => {
            this.loading = false;
            alert("Somthing wrong happen");
          }
        );
  }
  equipmentStock(){
    const equipment_stock_id = sessionStorage.getItem('equipmentStock');
    if(equipment_stock_id){
      this.filterParams  = this.filterParams.set('equipment_stock_id', sessionStorage.getItem('equipmentStock'))
    }
      this.adminService
        .generateEquipmentstock(this.project.id, this.filterParams)
        .subscribe(
          (res) => {
            this.loading = false;
            sessionStorage.removeItem('equipmentStock');
            window.open(res.url, "_blank");
          },
          (err) => {
            this.loading = false;
            sessionStorage.removeItem('equipmentStock');
            alert("Somthing wrong happen");
          }
        );
  }

  partyBalance() {
    this.adminService.generatePDFPartyBalance(this.project.id, this.qp).subscribe((res) => {
      this.loading = false;
      window.open(res.url, "_blank");
    }, err => console.log(err), () => this.loading = false);
  }

  taskPDF() {
    this.adminService
      .generateTaskReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Server Error!");
        }
      );
  }

  csv_attendanceReport() {
    this.adminService
      .generateCSVAttendanceReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Server Error!");
        }
      );
  }

  csv_singleAttendanceReport() {
    this.adminService
      .generateCSVAttendanceReport(this.project.id, this.filterParams)
      .subscribe(res => { this.loading = false; window.open(res.url, "_blank") },
        (err) => {
          this.loading = false;
          alert("Server Error!")
        }

      );
  }
  csv_payment() {
    this.adminService
      .generateCSVPaymentReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Server Error!");
        }
      );
  }
  csv_material() {
    this.adminService
      .generateCSVMaterialReport(this.project.id, this.filterParams)
      .subscribe(
        (res) => {
          this.loading = false;
          window.open(res.url, "_blank");
        },
        (err) => {
          this.loading = false;
          alert("Server Error!");
        }
      );
  }
    setDate(e) {
    this.datePicker['show'] = false;
      this.report_duration_title = "Custom";
    if (e.fromDate) {
      const date = new Date(e.fromDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (e.toDate) {
      const date = new Date(e.toDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }

}
