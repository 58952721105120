import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/interfaces/interfaces';
import { ImageSliderModalComponent } from 'src/app/shared/components/modal/image-slider-modal/image-slider-modal.component';

@Component({
  selector: 'app-overview-photos',
  templateUrl: './overview-photos.component.html',
  styleUrls: ['./overview-photos.component.css']
})
export class OverviewPhotosComponent implements OnInit {
  @Input() project = {} as Project;
  public photos = [] as string[];

  constructor(
    private ngbModal: NgbModal
  ) {
    //
  }
  ngOnInit(): void {
    this.photos = this.project.photos;
  }

  navigateToPdf(link: string) {
    window.open(link, '_blank');
  }

  openAlbumphotos(albumImages: string[], i: number) {
    if (albumImages[i].endsWith('pdf')) {
      this.navigateToPdf(albumImages[i])
    } else {
      const sliderModal = this.ngbModal.open(ImageSliderModalComponent, { fullscreen: true, backdropClass: 'dark-backdrop', modalDialogClass: 'image-modal-dialog' });
      // if(i){
      //   const temp = albumImages[i];
      //   albumImages.splice(i,1);
      //   albumImages.unshift(temp)
      // }
      sliderModal.componentInstance.parent = {
        imagesArray: albumImages,
        imageIndex: i
      };

    }
  }
}
