import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/company/services/company.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { Company } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-not-premium-user-popup',
  templateUrl: './not-premium-user-popup.component.html',
  styleUrls: ['./not-premium-user-popup.component.css']
})
export class NotPremiumUserPopupComponent implements OnInit {
  public isNotPaid = false;
  public company = {} as Company;
  public companies = [] as Company[];
  public showCompaniesDropdown = false;
  public trialExhausted = false;
  openMenu = false;
  activeTab: string;
  public bookDemo = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    private responsiveService: ResponsiveService,
    private companyService: CompanyService,
    private adminService: AdminService
  ) {

  }

  ngOnInit(): void {
    this.companyService.isNotPremium$.subscribe(res => {

      this.company = this.authService.session.company || {} as Company;
      this.activeTab = this.company.name;
      this.trialExhausted = this.company.is_paid == 0 ? false : true;
      this.isNotPaid = res;
      this.responsiveService.setNotPremiumPopUpStatus(this.isNotPaid);
    })
    
    // this.companyService.observeCompanies$.subscribe(res=>{
    //   this.companies = res || [] as Company[];
    // })
    this.getCompanies();
    if(this.company.country_iso == 'in'){
      this.bookDemo = 'https://zfrmz.in/yLkzfO63Z4YICBAnwmVs';
    }else{
      this.bookDemo = 'https://zfrmz.in/eCoBLSZMhOHMQHN2ZL3S'
    }
  }

  getCompanies() {
    this.companies = this.authService.session.companies || [] as Company[];
  }

  ngOnDestroy(): void {
    this.companyService.premiumUserPopupBehaviorSubject.next(false)
  }

  selectCompany(item: Company) {
    this.adminService.getCompanyDetails(item.id).subscribe({
      next: (res) => {
        this.authService.saveCompany(res);
        this.router.navigate([`/c/${item.id}/d/home`]);
        this.companyService.checkCompanySubscription()
        this.company = res || {} as Company;
      }
    })
  }
  closePopup() {
    this.companyService.premiumUserPopupBehaviorSubject.next(false)
    this.isNotPaid = false;
  }

  companyDropToggle() {
    if (this.showCompaniesDropdown) {
      this.showCompaniesDropdown = false
    } else {
      this.showCompaniesDropdown = true
    }
  }

  trialSubcription() {
    const checkValidUser = this.checkValidUser(this.company);
    if (!checkValidUser) {
      return alert('Ask admin to start trial')
    }
    this.authService.companyTrialSubscription(this.company).subscribe({
      next: () => {
        location.reload();
        this.router.navigate(["/company/dashboard/home"]);
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  checkValidUser(company: Company): boolean {
    for (const i of this.authService.session.profile.monkey_patch_company_user) {
      if (i.company_id == company.id && i.company_role_id == 'id1') {
        return true;
      }
    }
    return false
  }

  navigateTo() {
    https://zfrmz.in/yLkzfO63Z4YICBAnwmVs indian user https://zfrmz.in/eCoBLSZMhOHMQHN2ZL3S internantional
    this.router.navigate(['/../upgrade-plan/upgrade'])
    //
  }

  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/auth']);
    });
  }

  sendWhatsAppMsg(){
    const phoneNumber = '+919560209605'; // Recipient's WhatsApp number
    const message = encodeURIComponent(
      `Hi, my trial has been exhausted, can you help me with the premium plan details?`
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  }
}
