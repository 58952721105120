<div class="canvas-height">
    <div class="offcanvas-header d-flex align-items-center px-4">
        <div class="canvas-header-left d-flex align-items-center gap-2">
            <div class="pointer m-0 p-0">
                <app-back-button></app-back-button>
            </div>
            <div class="d-flex align-items-center">
                <div class="canvas-title">
                    <span *ngIf="componentData.from === forReductionDeduction.RETENTION">New Retention</span>
                    <span *ngIf="componentData.from === forReductionDeduction.DEDUCTION">New Deduction</span>
                </div>
            </div>
        </div>

        <div class="d-flex gap-4">
            <div class="canvas-save-btn" 
                 type="button" 
                 [class.disabled]="retentionForm.get('name')?.invalid" 
                 [style.opacity]="retentionForm.get('name')?.invalid ? '0.6' : '1'"
                 (click)="onSubmit()"
                 [attr.disabled]="retentionForm.get('name')?.invalid ? true : null">
                Save
            </div>
        </div>
    </div>
    <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
    <div class="canvas-body p-4">
        <form [formGroup]="retentionForm" (ngSubmit)="onSubmit()">
            <div class="custom-input">
                <label for="name">Item name</label>
                <input type="text" 
                       id="name" 
                       formControlName="name" 
                       class="f-14 fw-normal"
                       (keydown.enter)="$event.preventDefault(); onSubmit()">
                <div *ngIf="retentionForm.get('name')?.invalid && retentionForm.get('name')?.touched" class="text-danger">
                    <small *ngIf="retentionForm.get('name')?.errors?.['required']">Item name is required</small>
                </div>
            </div>
        </form>
    </div>
</div>