import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Project } from "src/app/interfaces/interfaces";
import { ChartService } from "src/app/project/service/chart.service";
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid, 
  ApexTooltip
} from "ng-apexcharts";

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  toolTip:ApexTooltip
};



interface Bills {
  label:string,
  paid:number,
  total:number
}

interface ExpenseBills {
  label:string,
  value_x:number,
  value_y:number
}

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit {
  @Input() project = {} as Project;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  
  // public billsData = [] as Bills[];
  public expenseBills = [] as ExpenseBills[];
  public loading =  false;

  constructor(
    private chartService:ChartService
  ){
   //
  }

  ngOnInit(): void {
    // this.getChartBillData();
    this.getChartExpenseFeatureWise();
  }

  

  // getChartBillData(){
  //   let qp = new HttpParams();
  //   qp = qp.set('project_id', this.project.id);
  //  this.chartService.getChartBills(qp).subscribe({
  //   next:res=>{
  //     this.billsData = res.data;
  //   },
  //   error:err=>{
  //     console.log(err);
  //   }
  //  })
  // }


  getChartExpenseFeatureWise(){
    this.loading =  true;
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);

    this.chartService.getChartExpenseFeatureWise(qp).subscribe({
      next:res=>{
        this.expenseBills = res?.data;
        this.generateChart();
        this.loading = false;
      },
      error:err=>{
        //
      }
    })

  }

  generateChart(){
    this.chartOptions = {
      series: [
        {
          name: "Total",
          data: this.expenseBills.map(i=>i?.value_y)
        }
      ],
      chart: {
        height: 230,
        type: "bar",
        toolbar:{
         show:false
        },
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      toolTip:{
        
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#546E7A",
        "#26a69a",
        "#D10CE8"
      ],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      yaxis:{
        labels:{
          formatter:(value)=>{
            if (isFinite(value)) {
                return value.toFixed(0);
            } else {
                return ""
            }
          }
        }
      },
      xaxis: {
        categories: this.expenseBills.map(i=>i?.label.split(" ")),
        labels: {
          style: {
            colors: [
              "#000",
              // "#000",
              // "#000",
              // "#000",
              // "#000",
              // "#000",
              // "#000",
              // "#000"
            ],
            fontSize: "8px"
          }
        }
      }
    };
  }
  
  }

  




