<span [hidden]="timeOver || config.hideTimer">
  <span>
    <span [hidden]="config.hideText">Offer Ends in: <br class="onlyMobile" /></span>
    <span *ngIf="daysLeft>0">
      {{daysLeft}} Days :
    </span>
    {{hoursLeft}} Hrs : {{minsLeft}} Mins 
    <span>
      : {{secsLeft}} Secs
    </span>
  </span>
</span>
