import {  Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbOffcanvas, NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css']
})
export class AddCommentComponent {
  @Input() comment:string;
  @Input() title?:string;
  @Output() emitComment: EventEmitter<any> = new EventEmitter();
  constructor(private modalService:NgbModal, private ngbActiveModal:NgbActiveModal){
  }
  
  saveComment() {
    this.emitComment.emit(this.comment);
    this.ngbActiveModal.close(this.comment);
  }
  closeModal(){
    this.modalService.dismissAll();

  }
}
