<div style="position: relative">
  <div
    class="custom-input"
    (click)="filterCompanyUsers()"
    [ngClass]="formInputError == 'partyName' ? 'input-error' : ''"
  >
    <label class="d-flex"
      >{{
        parentComponent.inputLabel ? parentComponent.inputLabel : "Party Name"
      }}
      <div *ngIf="required" style="color: red; margin-left: 2px">*</div></label
    >
    <input
      tabindex="-1"
      (keyup)="filterCompanyUsers()"
      [(ngModel)]="companyUser.name"
      autocomplete="off"
      #companyUserName
    />

    <span *ngIf="!companyUserSearchBox && companyUser?.name">
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 18H19" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M14.5 1.50023C14.8978 1.1024 15.4374 0.878906 16 0.878906C16.2786 0.878906 16.5544 0.933776 16.8118 1.04038C17.0692 1.14699 17.303 1.30324 17.5 1.50023C17.697 1.69721 17.8532 1.93106 17.9598 2.18843C18.0665 2.4458 18.1213 2.72165 18.1213 3.00023C18.1213 3.2788 18.0665 3.55465 17.9598 3.81202C17.8532 4.06939 17.697 4.30324 17.5 4.50023L5 17.0002L1 18.0002L2 14.0002L14.5 1.50023Z"
          stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>

    <span *ngIf="!companyUserSearchBox && !companyUser.name">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9999 20.9984L16.6499 16.6484"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span (click)="closeSearchBox($event)" *ngIf="companyUserSearchBox">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>

  <div class="search-box-outer-div" *ngIf="companyUserSearchBox">
    <div class="search-box">
      <ng-container *ngFor="let item of companyUsers; index as i">
        <div
          class="d-flex align-self-center p-3 search-box-row gap-4"
          (click)="setCompanyUser(item)"
        >
          <div class="avatar align-self-center">
            {{ item.name | shortName }}
          </div>
          <div style="width: 90%;" class="pointer d-flex justify-content-between align-items-center">
            <div class="align-items-center">
              <span class="party-name">{{ item.name | truncate : "20" }}</span
              ><br />
              <span *ngIf="item.mobile" class="party-mobile">{{
                item.mobile
              }}</span>
            </div>
            <div class="align-self-center">
              <span class="f-12 disabled-font">
                {{ companyUserTypeDetails[item?.type]?.name }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- <hr> -->
    </div>
    <div *ngIf="authService.hasAccess(authService.companyUserPolicy.Add_new_party)" (click)="addPartyCanvas(null)"
      class="d-flex justify-content-center align-items-center py-2 m-3" style="border: 2px dashed #8d7af0; cursor: pointer">
      <span style="color: #5f44ce; font-size: 1rem; font-weight: 500">+ Create Party</span>
    </div>
  </div>
</div>
