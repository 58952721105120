import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularCropperjsComponent } from '../../angular-cropperjs/angular-cropperjs.component'

@Component({
  selector: 'app-img-edit-modal',
  templateUrl: './img-edit-modal.component.html',
  styleUrls: ['./img-edit-modal.component.css']
})
export class ImgEditModalComponent implements OnInit {
  @Input() data: any;
  @Input() aspectRatio: number = 1;
  @ViewChild('angularCropper', { static: true }) public angularCropper: AngularCropperjsComponent;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    console.log('aspectRatio', this.aspectRatio)
    //
  }
  dismiss(str) {
    this.activeModal.dismiss('Hi');
  }
  close(str) {
    this.activeModal.close({data: str});
  }
  done() {
    this.angularCropper.exportCanvas()
  }
  handleImport(evt) {
    this.activeModal.close(evt);
  }
}
