/**
 * Created by tarun on 14/7/17.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalLoginComponent } from '../components/modal-login/modal-login.component';

import { Config } from '../../config/config.config';

import {CompanyFileUploadOptions, FileUploadOptions} from 'src/app/interfaces/interfaces';
import { LocalStorageService } from './local-storage-service.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public digitCount = 2;
  public editorConfig = {} as any;
  public editorConfigInline = {} as any;
  public editorConfigHindi = {} as any;

  private partyCompanyUserId = new BehaviorSubject('');
  public partyIdObservable = this.partyCompanyUserId.asObservable();

  private projectId = new BehaviorSubject('');
  public projectIdObservable = this.projectId.asObservable();

  private taskId = new BehaviorSubject('');
  public taskIdObservable = this.taskId.asObservable();

  public memberCount = new BehaviorSubject(0);
  public memberCountObservable = this.memberCount.asObservable();

  constructor(
    private http: HttpClient,
    private config: Config,
    private ngbModal: NgbModal,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    this.digitCount = localStorageService.get('country')?.decimal_digit || 2;
    this.editorConfig = {
      plugins: ['table', 'image', 'lists', 'advlist', 'code', 'fullscreen', 'link', 'media'],
      toolbar: 'removeformat | bold | alignjustify | alignright | alignleft | aligncenter | image'
        + ' | numlist | bullist | outdent | indent | formatselect | link | shading | media',
      images_upload_url: 'https://api.onsiteteams.com/apis/v3/file',
      images_upload_handler: this.images_upload_handler,
      height: 500,
      image_advtab: true,
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.onsiteteams.com/',
      extended_valid_elements: '*[*]',
      content_css: '/assets/css/editor.css',
      setup: (editor: any) => {
        editor.ui.registry.addButton('shading', {
          text: 'Shading',
          onAction: () => {
            editor.focus();
            editor.selection.setContent('<div class="editor__shading">' + editor.selection.getContent() + '</div>');
          }
        });
      }
      // valid_styles: { '*': 'color,font-size,font-weight,font-style,text-decoration' }
    };
    this.editorConfigInline = {
      plugins: ['quickbars', 'lists', 'advlist'],
      toolbar: false,
      menubar: false,
      // inline: true,
      height: 100,
      quickbars_selection_toolbar: 'bold italic| numlist | bullist | h2 h3 blockquote quicklink',
      quickbars_insert_toolbar: '',
      mobile: {
        theme: 'silver'
      },
      placeholder: 'Type Here To Connect With Teacher'
    };
    this.editorConfigHindi = {
      plugins: ['table', 'image', 'lists', 'advlist', 'code', 'fullscreen', 'link', 'media'],
      toolbar: 'removeformat | bold | alignjustify | alignright | alignleft | aligncenter | image'
        + ' | numlist | bullist | outdent | indent | formatselect | link | shading | media',
      images_upload_url: 'https://api.onsiteteams.com/apis/v3/file',
      images_upload_handler: this.images_upload_handler,
      height: 500,
      image_advtab: true,
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'http://localhost:4200/',
      extended_valid_elements: '*[*]',
      content_css: '/assets/css/editor.css',
      setup: (editor: any) => {
        editor.ui.registry.addButton('shading', {
          text: 'Shading',
          onAction: () => {
            editor.focus();
            editor.selection.setContent('<div class="editor__shading">' + editor.selection.getContent() + '</div>');
          }
        });
      }
      // valid_styles: { '*': 'color,font-size,font-weight,font-style,text-decoration' }
    };
  }




  getParam(param: string): Promise<string> {
    return Promise.resolve(param);
  }
  setBodyClass(className): void {
    document.body.className = className;
  }
  getByUrl(url): Observable<any> {
    return this.http.get(
      url,
      {}
    );
  }
  attachFIle(files): Observable<any> {
    const fileList: FileList = files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.config.userEndpoint}/file`,
      formData,
      {}
    );
  }
  s3attachFIle(files): Observable<any> {
    const fileList: FileList = files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${this.config.userEndpoint}/solution/upload`,
      formData,
      {}
    );
  }
  s3attachAnyFIle(fileUploadOptions: FileUploadOptions): Observable<any> {
    const fileList: FileList = fileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', fileUploadOptions.contentType);
    formData.append('folder', fileUploadOptions.folder);
    formData.append('extension', fileUploadOptions.extension);
    formData.append('project_id', fileUploadOptions.project_id);
    formData.append('task_id', fileUploadOptions.task_id);
    formData.append('expense_id', fileUploadOptions.expense_id);
    formData.append('material_id', fileUploadOptions.material_id);
    formData.append('drawing_id', fileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload`,
      formData,
      {}
    );
  }

  s3attachCompanyFIle(companyFileUploadOptions: CompanyFileUploadOptions): Observable<any> {
    const fileList: FileList = companyFileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', companyFileUploadOptions.contentType);
    formData.append('parent_dir', companyFileUploadOptions.parent_dir);
    formData.append('name', companyFileUploadOptions.name)
    formData.append('extension', companyFileUploadOptions.extension);
    formData.append('project_id', companyFileUploadOptions.project_id);
    formData.append('task_id', companyFileUploadOptions.task_id);
    formData.append('expense_id', companyFileUploadOptions.expense_id);
    formData.append('material_id', companyFileUploadOptions.material_id);
    formData.append('drawing_id', companyFileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload/companyfile`,
      formData,
      {}
    );
  }
  images_upload_handler = (blobInfo, success, failure) => {
    const newfile = new File([blobInfo.blob()], blobInfo.filename());
    const fileName = newfile.name;
    const fileType = newfile.type;
    const extn = fileName.substring(fileName.lastIndexOf('.'));
    const fileUploadOptions = {
      files: [newfile],
      contentType: fileType,
      extension: extn,
      folder: 'company'
    } as FileUploadOptions;
    this.s3attachAnyFIle(fileUploadOptions).subscribe(res => {
      success(res.url);
    }, err => {
      return failure('Upload Error!');
    });
  }
  openLoginModal(type) {
    document.body.classList.add('modal-login');
    const modalRef = this.ngbModal.open(ModalLoginComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = { type: type };
    modalRef.result.then(data => {
      location.reload();
    }, err => {
      console.log(err);
    });
  }
  getBanner(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/banner`,
      { params }
    );
  }
  fixTinyMCETabIssue(evt) {
    const event = evt.event;
    const editor = evt.editor;
    let code;
    if (event.keyCode) {
      code = event.keyCode;
    } else if (event.which) {
      code = event.which;
    }
    if (code === 9 && !event.altKey && !event.ctrlKey) {
      if (event.shiftKey) {
        editor.execCommand('Outdent');
      } else {
        editor.execCommand('Indent');
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      return false;
    }
  }

  sendPartyId(partyCompanyUserId: string) {
    this.partyCompanyUserId.next(partyCompanyUserId);
  }

  sendProjectId(projectId: string) {
    this.projectId.next(projectId);
  }

  sendTaskId(taskId: string) {
    this.taskId.next(taskId);
  }

  getAmountByPercent(percent, total) {
    const per = (total / 100) * percent;
    if (per % 1 == 0) {
      return per;
    } else {
      return parseFloat(Number(per).toFixed(this.digitCount))
    }
  }
  getPercentByAmount(amount, total) {
    const amt = (100 * amount) / total;
    return amt;
  }

  deepEquale(obj1, obj2) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false
    for (const keys of Object.keys(obj1)) {
      // console.log(obj1[keys], obj2[keys], typeof(obj1[keys]))
      if (typeof (obj1[keys]) == 'object') {
        if (typeof (obj1[keys]) !== typeof (obj2[keys])) {
          return false
        }
      } else {
        if (obj1[keys] !== obj2[keys]) {
          return false
        }
      }
    }
    return true
  }

  public fileExtension = new Map([
    ['DWG','dwg.svg'],
    ['dwg','dwg.svg'],
    ['pdf','pdf.svg'],
    ['PDF','pdf.svg'],
    ['docx','docx.svg'],
    ['DOCX','docx.svg'],
    ['xlsx','xlsx.svg'],
    ['XLSX','xlsx.svg'],
  ])

  fileExtensionExtract(fileName:string):{file:string, isImage:boolean}{
   const nameArr = fileName.split('.') || [];
    const extn = nameArr[nameArr.length-1];
    if(this.fileExtension.has(extn)){
      return {file:this.fileExtension.get(extn), isImage:false}
    }else{
      return {file:fileName, isImage:true}
    }
  }

  getGstPercentage() {
    return new Promise((resolve, reject) => {
    try {
      const country = this.localStorageService.get('country');
      const taxSlabArray = country?.tax_slabs || [];
      if (Array.isArray(taxSlabArray)) {
        resolve(taxSlabArray);
      } else {
        reject(new Error('Data in localStorage is not an array.'));
      }
    } catch (error) {
      reject(error);
    }
    });
  }

  isSameStateGst(companyGst: string, partyGst: string): boolean {
    try{
      var str1Lower = companyGst.toLowerCase();
      var str2Lower = partyGst.toLowerCase();
      if(str1Lower == "" || str2Lower == "") throw new Error()
      return str1Lower.substring(0, 2) === str2Lower.substring(0, 2);
    }catch(e){
      return true
    }
  }
}