<!-- <div class="only-web" *ngIf="deviceDetectorService.deviceType=='desktop'"> -->

  <!-- <ng-progress [spinner]="false"></ng-progress> -->
  <!-- <p class="m-0" *ngFor="let alert of alerts" style="border:1px solid red">
    <ngb-alert class="m-0" [type]="alert.type" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert>
  </p> -->
  <div *ngIf="showAlert" class="no-internet-alert d-flex justify-content-center align-items-center" style=" position: fixed; z-index: 9999; background-color: rgba(0, 0, 0, 0.6);
   height: 100vh; width: 100%;">
     <div class="alert-card">
        <div class="alert-card-body d-flex justify-content-center">
          <p class="m-0" >{{alerts.message}}</p>
        </div>
        <hr>
        <div class="alert-card-footer d-flex justify-content-center">
             <button (click)="closeAlert()" class="alert-close-cstm-btn" [ngClass]="{'success': alerts.type === 'success', 'danger':alerts.type === 'danger'}" >Close</button>
        </div>
     </div>
  </div>

  <router-outlet></router-outlet>

<!-- </div> -->

