import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CostCodeLib } from 'src/app/interfaces/interfaces';
import { HttpParams } from '@angular/common/http';
import { CompanyService } from 'src/app/company/services/company.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AddCostCodeComponent } from '../add-cost-code/add-cost-code.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';

export interface Parent {
  company_id?: string;
  sub_category_id?: string;
  height?: 0 | 1;
  is_add_new?: boolean;
}
interface CostCodeNode {
  name: string;
  id: string;
  parent: string;
  children: CostCodeLib[];
}

@Component({
  selector: 'app-select-cost-code',
  templateUrl: './select-cost-code.component.html',
  styleUrls: ['./select-cost-code.component.css']
})
export class SelectCostCodeComponent implements OnInit {
  @Input() parent = {} as Parent;
  public costCodeNodes = [] as CostCodeNode[];
  public costCodeNode = {} as CostCodeNode;
  public loading = false;
  public busy=false;
  public tradeSearchString?: string;
  constructor(
    public ngbActiveOffcanvas: NgbActiveOffcanvas,
    private ngbOffcanvas: NgbOffcanvas,
    private companyService: CompanyService,
    private adminService: AdminService,
    public authService: AuthService,
    private utilService: UtilService,
    private notificationService: NotificationsService
  ) {

  }
  ngOnInit(): void {
    if (this.parent?.company_id == undefined) {
      this.parent.company_id = this.authService.session.company.id;
    }
    if (this.parent?.sub_category_id != undefined) {
      this.setCostCode(this.parent.sub_category_id, '', true);
    }
    this.getCostCode();
  }

  getCostCodeNode(costCode: CostCodeLib[]) {
    costCode.map(item => {
      this.costCodeNodes.push({
        id: item.id,
        name: item.text_en,
        parent: item.monkey_patch_parent,
        children: item.monkey_patch_children
      })
    });
  }
  public costCodes = [] as CostCodeLib[];
  public valueStack = {
    page: { next_url: "" }
  };

  getCostCode() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.parent.company_id)
    this.companyService.getCostCode(qp).subscribe({
      next: res => {
        this.costCodes = res.subcategories;
        this.valueStack = res;
        this.getCostCodeNode(this.costCodes)
      },
      error: err => {
        console.log("Unable to load Cost code");
      }
    })
  }

  pullData(term: string): Observable<any[]> {
    if (term == '') {
      this.costCodeNodes = [];
      this.getCostCode();
      return of([])
    }
    let qp = new HttpParams();
    qp = qp.set('company_id', this.parent.company_id);
    qp = qp.set('search', term);
    return this.adminService.subcategoryList(qp).pipe(map(res => {
      this.costCodes = [];
      this.costCodeNodes = [];
      this.getCostCodeNode(res.subcategories);
      this.valueStack = res;
      return [];
    }));
  }

  tradeSearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.pullData(term)?.pipe(
          catchError(() => {
            return of([]);
          }))
      )
    )

  setCostCode(id: string, name?: string, is_autoSelected?:boolean) {
    this.costCodeNode.id = id;
    if (name) {
      this.costCodeNode.name = name;
    }
    if(!is_autoSelected) return this.saveCostCode();
  }
  saveCostCode() {
    this.ngbActiveOffcanvas.close({ sub_category_id: this.costCodeNode.id, sub_cat_text_en: this.costCodeNode.name })
  }
  addNewCostCodeCanvas() {
    if (!this.authService.hasAccess(this.authService.companyUserPolicy.Add_new_trade)) {
      this.notificationService.initiate({ title: 'You don\'t have access', type: toastTypes.error });
      return
    }
    const ngbOffcanvas = this.ngbOffcanvas.open(AddCostCodeComponent, {
      position: 'end',
      backdrop: 'static',
      panelClass: 'offcanvas-sm'
    })
    ngbOffcanvas.result.then(onfilled => {
      this.ngbActiveOffcanvas.close({ sub_category_id: onfilled.id, sub_cat_text_en: onfilled.text_en });
    })
  }
  onScroll(){
    if(!this.busy){
      this.utilService.getByUrl(this.valueStack.page.next_url).subscribe({
        next:(res)=>{
          this.costCodes = this.costCodes.concat(res.subcategories);
          this.valueStack = res;
          this.getCostCodeNode(this.costCodes)
        },
        error:(err)=>{
          console.log("Unable to load Cost code");
        }
      })
    }
    
  }
}
