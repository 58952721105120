import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'src/app/core/services/admin.service';
@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  public currentMessage = new BehaviorSubject<any>(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private adminService: AdminService
  ) {
    this.afMessaging.messages.subscribe((message) => {
      // console.log('New message received: ', message);
      this.currentMessage.next(message);
    });
  }

  requestPermission(web_fcm_token: string, userId: string): void {
    if(!userId || userId === '') return;
    const storedToken = localStorage.getItem('web_fcm_token');
    if (!storedToken) {
      this.generateToken({id: userId});
    } else if(storedToken && storedToken !== web_fcm_token){
      this.updateFCMToken({id: userId, web_fcm_token: storedToken});
    }else{
      // console.log('FCM token found in localStorage:', storedToken);
    }
  }

  generateToken(data){
    let retryCount = 0;
    this.afMessaging.requestToken.subscribe(
      (token) => {
        // console.log('Generated FCM Token:', token);
        localStorage.setItem('web_fcm_token', token);
        data['web_fcm_token'] = token;
        this.updateFCMToken(data);
      },
      (error) => {
        if (retryCount < 1) {
          this.generateToken(data);
          retryCount++;
        }
      }
    );
  }

  updateFCMToken(data): void {
    this.adminService.updateFCMToken(data).subscribe((res) => {
      // console.log('Token updated in DB');
    });
  }

  receiveMessage(): void {
    this.afMessaging.messages.subscribe(
    (payload) => {
      // console.log('Message received: ', payload);
      this.currentMessage.next(payload);
    });
  }
}
