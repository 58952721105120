import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Address, Company, Country } from 'src/app/interfaces/interfaces';
import { ValidatorService } from '../../services/validator.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { LocalStorageService } from '../../services/local-storage-service.service';
import { HttpParams } from '@angular/common/http';
interface Parent {
  address?:Address,
  type?: 'branch' | 'party',
  party_company_user_id?:string,
}
@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent {
  @Input() parent = {} as Parent;
  public loading = false;
  public company = {} as Company;
  public brancchAddress = {} as Address;
  public selectingCountry = false;
  public country = {} as Country;
  public countries = [] as Country[]; 
  constructor(
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private ngbOffcanvas: NgbOffcanvas,
    private adminService: AdminService,
    public authService: AuthService,
    private validatorService: ValidatorService,
    private notificationServices: NotificationsService,
    private localStorageService: LocalStorageService
  ) {
    this.company = this.authService.session.company;
    this.country = this.authService.session.country;
    this.brancchAddress.country_code = this.country.country_code;
  }
  ngOnInit(): void {
    this.getCountryList();
    if(this.parent.address){
      this.brancchAddress = this.parent.address;
    }
  }
  getCountryList() {
    this.authService.getCountryList().subscribe({
      next: (res) => {
        this.loading = false;
        this.countries = res || [];
        if(this.parent.address && this.parent.address.country_code){
          const country = this.countries.find(
            country => country.country_code === this.parent.address.country_code
          );
          if(country) this.country = country;
        }
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
      },
    });
  }
  cancelBtn() {
    this.ngbActiveOffcanvas.dismiss();
  }
  saveBtn(){
    if(!this.brancchAddress.address_title){
      return this.notificationServices.initiate({title:'Please enter Branch Name', type:toastTypes.error});
    }else if(!this.brancchAddress.address_line_1){
      return this.notificationServices.initiate({title:'Please enter Address Line 1', type:toastTypes.error});
    }
    this.loading = true;
    if(this.brancchAddress.id){
      this.editAddress();
    }else{
      this.addAddress();
    }
  }
  addAddress(){
    this.brancchAddress.company_id = this.company.id;
    let addressObserver;
    if(this.parent.type == 'branch'){
      this.brancchAddress.owner_id = this.company.id;
      addressObserver = this.adminService.addCompanyAddress(this.brancchAddress);
    }else{
      this.brancchAddress.owner_id = this.parent.party_company_user_id;
      addressObserver = this.adminService.addCompanyUserAddress(this.brancchAddress);
    }
    addressObserver.subscribe({
      next:(res)=>{
        this.notificationServices.initiate({title:'Address Added Successfully', type:toastTypes.success});
        this.ngbActiveOffcanvas.close(res);
      },
      error:(err)=>{
        console.log(err);
        this.loading = false;
        this.notificationServices.initiate({title:'Unable to add', type:toastTypes.error});
      }
    })
  }
  editAddress(){
    this.adminService.editAddress(this.brancchAddress).subscribe({
      next:(res)=>{
        this.notificationServices.initiate({title:'Address Updated Successfully', type:toastTypes.success});
        this.ngbActiveOffcanvas.close(res);
      },
      error:(err)=>{
        console.log(err);
        this.loading = false;
        this.notificationServices.initiate({title:'Unable to add', type:toastTypes.error});
      }
    })
  }
  setCountry(item: Country) {
    this.country = item || {};
    this.selectingCountry = false;
    this.brancchAddress.country_code = this.country.country_code;
  }
}
