export enum COMPANY_USER_TYPE {
    CLIENT = "customer",
    STAFF = "employee",
    VENDOR = "vendor",
    LABOUR = "labour",
    LABOUR_CONTRACTOR = "labour_contractor",
    MATERIAL_SUPPLIER = "material_supplier",
    EQUIPMENT_SUPPLIER = "equipment_supplier",
    SUBCONTRACTOR = "subcontractor",
    INVESTOR = "investor",
    OTHERS_VENDOR = "other_vendor",
}

export const CompanyUserTypeDetails: Record<COMPANY_USER_TYPE, { name: string }> = {
    [COMPANY_USER_TYPE.CLIENT]: { name: "Client" },
    [COMPANY_USER_TYPE.STAFF]: { name: "Staff" },
    [COMPANY_USER_TYPE.VENDOR]: { name: "Vendor" },
    [COMPANY_USER_TYPE.LABOUR]: { name: "Worker" },
    [COMPANY_USER_TYPE.LABOUR_CONTRACTOR]: { name: "Labour Contractor" },
    [COMPANY_USER_TYPE.MATERIAL_SUPPLIER]: { name: "Material Supplier" },
    [COMPANY_USER_TYPE.EQUIPMENT_SUPPLIER]: { name: "Equipment Supplier" },
    [COMPANY_USER_TYPE.SUBCONTRACTOR]: { name: "Subcontractor" },
    [COMPANY_USER_TYPE.INVESTOR]: { name: "Investor" },
    [COMPANY_USER_TYPE.OTHERS_VENDOR]: { name: "Other Vendor" },
};