import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var YT: any;

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent implements OnInit {
  public data: any;
  public vid: any;
  public player: any;
  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.data.goto = this.data.goto ? this.data.goto : 0;
    this.vid = this.data.url.substr(this.data.url.lastIndexOf('/') + 1, this.data.url.length);
    this.player = new YT.Player('player', {
      height: '270',
      width: '480',
      videoId: this.vid,
      playerVars: {
        controls: 1,
        start: this.data.goto,
        color: 'red' // or 'white'
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    });
  }
  dismiss() {
    this.activeModal.close({ reload: true });
    // location.reload();
  }
  play() {
    this.player.playVideo();
  }
  pause() {
    this.player.pauseVideo();
  }
  goToTime(time) {
    this.player.seekTo(time, true);
  }
  onPlayerReady = (event: any) => {
    // this.goToTime(this.data.goto);
  }
  onPlayerStateChange = (event: any) => {};
}
