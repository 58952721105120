<div>
    <div class="modal-body">
      <div *ngFor="let alert of alerts">
        <ngb-alert [type]="alert.type" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert>
      </div>
      <div *ngIf="formName=='login'">
        <div class="modal-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-10 col-10">
                <h3>Login!</h3>
              </div>
              <div class="col-sm-2 col-2 text-right">
                <button type="button" class="modal-close" aria-label="Close" (click)="dismiss('Cross click')">
                  <span class="icon icon-close"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-form">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Mobile</label>
                  <input class="form-control {{form_err.mobile ? 'radio__red': ''}}" type="number" [(ngModel)]="anon.mobile" (blur)="mobileValidation()">
                </div>
                <div class="form-group">
                  <label>OTP</label>
                  <button class="btn btn-sm btn-padding btn-link float-right" (click)="formName='reset'">Forgot Password</button>
                  <input type="password" class="form-control" [(ngModel)]="anon.otp" (keyup)="loginOnKeyUp($event)">
                </div>
                <div class="form-group">
                  <button [disabled]="loading" class="btn btn-block btn-primary" (click)="login()">Login</button>
                </div>
                <div class="form-group">
                  <p><button class="btn btn-sm btn-link" [disabled]="desableResenBTN" type="button" (click)="disableResend();">Didn't get OTP? <span class="btn-color">Resend</span></button></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="formName=='register'">
        <div class="modal-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-10 col-10">
                <h3>TO CONTINUE, CREATE A FREE ACCOUNT</h3>
              </div>
              <div class="col-sm-2 col-2 text-right">
                <button type="button" class="modal-close" aria-label="Close" (click)="dismiss('Cross click')">
                  <span class="icon icon-close"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-form">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="form-control" type="text" [(ngModel)]="anon.name" class="form-control">
                </div>
                <div class="form-group">
                  <label>Mobile</label>
                  <input type="number" class="form-control {{form_err.mobile? 'radio__red': ''}}" name="mobile"
                    placeholder="Mobile No" required [(ngModel)]="anon.mobile">
                </div>
                <div class="form-group">
                  <button [disabled]="loading" class="btn btn-block btn-primary" (click)="register()">Register</button>
                </div>
                <div class="form-group">
                  <p><button class="btn btn-sm btn-link" type="button" (click)="formName='login'">Have an OTP? <span class="btn-color">Login</span></button></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

