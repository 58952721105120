<div class="overview-photos">
    <ng-container *ngIf="photos.length" >
        <ng-container *ngFor="let item of photos, index as i">
            <div (click)="openAlbumphotos(photos, i)"   class="img-wrapper">
                <img height="100%" width="100%" [src]="item" alt="sample">
            </div>
        </ng-container>
       
    </ng-container>

    <ng-container *ngIf="!photos.length">
        <div  class="img-wrapper">
            <img height="100%" width="100%" src="../../../../assets/svg/no-photos.svg" alt="no-image">
        </div>
    </ng-container>
</div>