import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderServiceTsService {
  public loaderBehavior = new BehaviorSubject(false);
  public observeLoader = this.loaderBehavior.asObservable();
  updateLoader(value:boolean){
    this.loaderBehavior.next(value)
  }
}
