<button *ngIf="parent_page?.name != 'Party Project Transaction' && parent_page?.name !== 'Equipment Stock'" class="topbar-btn" (click)="downloadReport()">
  <span style="color: #8889A9;" class="f-12 d-none d-md-block">
    Download Report
  </span>
  <span style="color: #8889A9;" class="f-12 d-none d-sm-block d-md-none">
    Download
  </span>
  <div>
    <ng-icon class="disabled-font" name="featherDownload"></ng-icon>
  </div>
</button>

<button *ngIf="parent_page?.name == 'Party Project Transaction'" class="topbar-btn" (click)="downloadReport()">
  <img src="../../../../assets/svg/pdf.svg" alt="pdf" width="30" height="30" style="filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(61deg) brightness(197%) contrast(101%);">
</button>

<button class="topbar-btn" *ngIf="parent_page?.name === 'Equipment Stock'" (click)="downloadReport()">
  <div>
    <ng-icon name="featherFileText"></ng-icon>
  </div>
</button>
