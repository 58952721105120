import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class ResponsiveService {
  private smallSidebarStatus = new BehaviorSubject<boolean>(false);
  private notPremiumPopUp = new BehaviorSubject<boolean>(false);
  private isTransactionsDirty = new BehaviorSubject<boolean>(false);
  constructor() {
    //
   }

  getSmallSidebarStatus(): Observable<boolean>{
    return this.smallSidebarStatus;
  }

  setSmallSidebarStatus(value:boolean):void{
    this.smallSidebarStatus.next(value);
  }

  getNotPremiumPopUPStatus():Observable<boolean>{
    return this.notPremiumPopUp;
  }

  setNotPremiumPopUpStatus(value:boolean):void{
    this.notPremiumPopUp.next(value);
  }

  getIsTransactionsDirty():Observable<boolean>{
    return this.isTransactionsDirty;
  }

  setIsTransactionsDirty(value:boolean):void{
    this.isTransactionsDirty.next(value);
  }



}
