export enum CustomFieldType {
    PO =              "po",
	CLIENT_PARTY=      "client_party",
	STAFF_PARTY=       "staff_party",
	LABOUR_PARTY=     "labour_party",
	VENDOR_PARTY=       "vendor_party",
	GRN=               "grn",
	QUOTATION=         "quotation",
	SALESORDER_INVOICE= "salesorder_invoice",
	SUBCON_WORKORDER=   "subcon_workorder",
	BOQ_WORKORDER=      "boq_workorder",
}

export const CustomFieldTypeRecord: Record<CustomFieldType, string> = {
    [CustomFieldType.PO]: "Purchase Order",
    [CustomFieldType.CLIENT_PARTY]: "Client",
    [CustomFieldType.STAFF_PARTY]: "Staff",
    [CustomFieldType.LABOUR_PARTY]: "Labour",
    [CustomFieldType.VENDOR_PARTY]: "Vendor",
    [CustomFieldType.GRN]: "Material Received",
    [CustomFieldType.QUOTATION]: "Quotation",
    [CustomFieldType.SALESORDER_INVOICE]: "Sales Order/Invoice",
    [CustomFieldType.SUBCON_WORKORDER]: "Subcon Work Order",
    [CustomFieldType.BOQ_WORKORDER]: "BOQ Work Order",
}; 


export enum CustomFieldDataType {
    TEXT = "text",
    NUMBER = "number",
    DATE = "date",
}

