import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-upgrade-status-modal',
  templateUrl: './upgrade-status-modal.component.html',
  styleUrls: ['./upgrade-status-modal.component.css']
})
export class UpgradeStatusModalComponent {
@Input() parent = {} as Subscription;
constructor(
  public ngbActiveModal: NgbActiveModal,
  public authService:AuthService
  ){}
}
