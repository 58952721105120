<div>
  <div class="modal-body">
    <div class="float-end onsite-modal-close" (click)="dismiss('Cross click')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

    <div *ngIf="index>0 && images.length>1" (click)="previousImage(index)" class="backward-arrow arrow" style="position: absolute; top: 50%; left: 0px;">
      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18L9 12L15 6" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

    <div  *ngIf="!images[index].endsWith('pdf')" class="d-flex justify-content-center align-items-center img-container" >
      <img  class="main-img" [ngClass]="isImage(images[index]) ? 'image' : 'file'"  
      [src]="fileExtensionExtract(images[index])"
      (error)="onImageError(fileExtensionExtract(images[index]))"
      alt="image">
    </div>
    <div *ngIf="images[index].endsWith('pdf')"  class="d-flex justify-content-center align-items-center img-container" >
      <app-pdf-viewer [config]="{url:images[index], showControl:1, scale: 0.8}"></app-pdf-viewer>
    </div>

    <div *ngIf="index<images.length-1 && images.length>1" (click)="nextImage(index)" class="forward-arrow arrow" style="position:absolute; top: 50%; right: 0px;">
      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <ng-container>
      <div class="d-flex justify-content-center  gap-5 py-2" style="width: 100%; position: absolute; bottom: 0px;"  >
        <div class="cursor download-btn" (click)="downloadFile(images[index])"> 
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 10L12 15L17 10" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15V3" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> 
        </div>
      </div>
    </ng-container>
  </div>
</div>
