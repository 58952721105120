<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-wrap flex-column">
        <span class="f-14">Opening Balance</span>
      </div>
    </div>
    <div class="d-flex gap-4 ">
      <div class="canvas-cancel-btn" (click)="ngbActiveOffcanvas.dismiss()">Cancel</div>
      <div (click)="save()" class="canvas-save-btn">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div class="canvas-body d-flex flex-column gap-3">


    <div class="d-flex justify-content-between gap-5">
      <div (click)="paymentType = 'receive'" class="custom-div d-flex justify-content-center gap-2 custom-radio">
        <div [ngClass]="{'selected-radio':paymentType === 'receive'}" class="radio-selector"></div>
        <span class="f-14">
          Party will pay
        </span>
      </div>

      <div (click)="paymentType = 'pay'" class="custom-div d-flex justify-content-center gap-2 custom-radio">
        <div [ngClass]="{'selected-radio':paymentType === 'pay'}" class="radio-selector"></div>
        <span class="f-14">
          Party will receive
        </span>
      </div>

    </div>

    <div class="custom-input">
      <label for="amount">Amount({{authService.session.company.currency || '₹'}})*</label>
      <input id="amount" type="text" (input)="calculateAmount($event)" [(ngModel)]="amount">
    </div>
  </div>
</div>