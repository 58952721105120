<div class="main-div p-4">
    <div class="main-header d-flex justify-content-between align-items-center">
        <span class="f-16 fw-bold">Edit Party ID</span>
        <button (click)="ngbActiveModal.dismiss()" type="button" style="border: none;" class="btn icon-btn">
            <ng-icon class="d-flex align-items-center" name="featherX"></ng-icon>
        </button>
    </div>

    <div class="main-body d-flex flex-column gap-2 py-4">
        <div class="custom-div py-1">
            <label for="prefix" class="form-label">Prefix</label>
            <input [(ngModel)]="this.partySequence.prefix" type="text" class="form-control" id="prefix">
        </div>
        <div class="custom-div py-1">
            <label for="sequence" class="form-label">Sequence</label>
            <input [(ngModel)]="this.partySequence.sequence" type="number" class="form-control" id="sequence">
        </div>
    </div>

    <button (click)="save()" [disabled]="!this.partySequence.prefix || !this.partySequence.sequence"
        class="w-100 btn save-btn">Save</button>

</div>