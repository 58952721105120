import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { Company, CompanyUser, Project } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { PartyMemberModalComponent } from '../modal/party-member-modal/party-member-modal.component';
import { UtilService } from '../../services/util.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { AddPartyLibraryComponent } from '../../../company-library/components/add-party-library/add-party-library.component';
import { CompanyPartyType } from 'src/app/enum/enum';

  interface ParentData {
    pageFrom?: 'partyEarning'| 'party-payment' | 'materialPurchase'| 'materialInventory' | 'withdraw'| 'deposit' | 'attendance' | 'project-setting' | 'sales-invoice' | 'AddQuotationComponent' | 'equipment' | 'warehouse-setting' | 'create-subcon'| 'purchase-order' | 'material-return' | 'task-assignee' | 'project-creation' | 'asset-modal' | 'todo' | 'party-to-party-payment' | 'boq';
    project_id?: string;
    party_company_user_id?: string;
    autoFocus?: boolean;
    autoClick?: boolean;
    inputLabel?: string;
  }
  @Component({
    selector: 'app-party-input',
    templateUrl: './party-input.component.html',
    styleUrls: ['./party-input.component.css']
  })
  export class PartyInputComponent implements OnInit, OnDestroy {
    @ViewChild('companyUserName') companyUserNameEle: ElementRef;
    @Output() onCompanyUserSelect = new EventEmitter<CompanyUser>(true);
    @Input() parentComponent = {} as ParentData;
    @Input() required: boolean;
    public formInputError = '';
    public companyUser = {} as CompanyUser;
    public companyUsers = [] as CompanyUser[];
    public newCompanyUser = {} as CompanyUser;

  private project = {} as Project;
  public company = {} as Company;
  public companyUserSearchBox = false;
  isMobileEditable = false;
  mobileForm: FormGroup;
  mobileNumber: string;
  editableUserIndex = 0;
  editableUserIndices: number[] = [];


    constructor(
      private adminService: AdminService,
      private localStorageService: LocalStorageService,
      public authService:AuthService,
      private modalService: NgbModal,
      private utilService: UtilService,
      private notificationService: NotificationsService,
      private formBuilder: FormBuilder,
      private ngbOffcanvas: NgbOffcanvas,
    ) {
      this.mobileForm = this.formBuilder.group({
        mobileNumber: ['', [
          Validators.required,
          Validators.pattern('^[6-9]\\d{9}$') // Regex for 10-digit Indian mobile number
        ]]
      });
    }

  ngOnInit(): void {
    this.project = this.authService.session.project || {};
    this.company = this.authService.session.company || {};
    this.getCompanyUsers();

  }

  ngAfterViewInit() {
    if(this.parentComponent.autoFocus){
      setTimeout(() => {
        this.companyUserNameEle.nativeElement.focus();
      }, 100);
    }
  }

  ngOnDestroy() {
    // Cleanup code here
    this.editableUserIndices = []
  }

  getCompanyUsers() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    if (this.parentComponent.pageFrom) {
      let priority_type;
      switch (this.parentComponent.pageFrom) {
        case 'materialPurchase':
          priority_type = 'material_supplier';
          break;

          case 'materialInventory':
          priority_type = 'material_supplier';
          break;

          case 'purchase-order':
          priority_type = 'material_supplier';
          break;

          case 'material-return':
          priority_type = 'material_supplier';
          break;
        case 'deposit':
          priority_type = 'customer';
          break;

        case 'sales-invoice':
          priority_type = 'customer';
          break;

        case 'AddQuotationComponent':
          priority_type = 'customer';
          break;
        case 'project-setting':
          priority_type = 'employee';
          break;

          case 'warehouse-setting':
          priority_type = 'employee';
          break;

          case 'task-assignee':
          priority_type = 'employee';
          break;

          case 'project-creation':
          priority_type = 'employee';
          break;
        case 'attendance':
          priority_type = 'labour_contractor';
          break;
        case 'equipment':
          priority_type = 'equipment_supplier';

          break;
        case 'AddQuotationComponent' || 'boq':
          priority_type = 'customer';
          break;
        case 'create-subcon':
          priority_type = 'subcontractor';
          break;
      }
      if (priority_type) {
        qp = qp.set('priority_type', priority_type);
      }
    }
    this.adminService.getCompnayUserList(qp).subscribe((res) => {
      this.companyUsers = res.data || ([] as CompanyUser[]);
      this.localStorageService.set('companyUsers', this.companyUsers);
      if(this.parentComponent.autoClick){
        this.companyUserNameEle.nativeElement.click();
      }
      
      this.utilService.partyIdObservable.subscribe(party_company_user_id => {
        if (party_company_user_id) {
          this.companyUser = this.companyUsers.find(user => user.id == party_company_user_id);
          if (this.companyUser) {
            this.setCompanyUser(this.companyUser);
          } else {
            this.companyUser = {}
            this.sendCompanyUser(this.companyUser)
          }
        }
      })

    });
  }

  filterCompanyUsers() {
    let len = 0;
    this.companyUser.name = this.companyUser.name?.trimStart()
    this.companyUser.name ? len = this.companyUser.name?.length : 0;

    if (len > 0) {
      this.companyUsers = this.localStorageService.get('companyUsers') || [];
      const pattern = new RegExp(this.companyUser.name, 'i');
      this.companyUsers = this.companyUsers.filter((f) =>
        f.name.match(pattern)
      );
      this.companyUserSearchBox = true;
    }
    if (len == 0) {
      this.companyUsers = this.localStorageService.get('companyUsers');
      this.companyUserSearchBox = true;
    }

    // This check for: user not find the company user and going to create new company user
    if (this.companyUsers.length == 0) {
      this.newCompanyUser.name = this.companyUser.name;
    }
  }

  closeSearchBox(event?) {
    event?.stopPropagation();
    this.companyUser = {};
    this.newCompanyUser = {};
    this.filterCompanyUsers();
    this.companyUserSearchBox = false;
    this.onCompanyUserSelect.emit()
  }

  addQuickCompanyUser(withMobile, event) {
    event.stopPropagation();
    if (withMobile == true) {

      const activeModal = this.modalService.open(PartyMemberModalComponent);
      activeModal.componentInstance.data = { project: this.project, companyName: this.companyUser.name, companyMobile: null, source: this.parentComponent.pageFrom };
      activeModal.result.then((res) => {
        this.newCompanyUser = {} as CompanyUser;
        this.sendCompanyUser(res)
        this.companyUserSearchBox = false;
        // this.companyUsers.unshift(res);
        this.companyUsers = this.localStorageService.get('companyUsers');
        this.companyUsers.unshift(res);
        this.localStorageService.set('companyUsers', this.companyUsers);
      });
    } else {
      if (this.parentComponent.pageFrom == 'project-setting' || this.parentComponent.pageFrom == 'warehouse-setting') {
        return this.notificationService.initiate({ title: "Team member mobile number required, kindly add mobile number.", type: toastTypes.warning })
      }
      this.companyUser.name = this.companyUser.name?.trim();
      let company_user_type = 'vendor';
      if (this.parentComponent?.pageFrom == 'deposit' || this.parentComponent?.pageFrom == 'sales-invoice') {
        company_user_type = 'customer';
      }
      const data = {
        name: this.companyUser.name,
        project_id: this.parentComponent.pageFrom === 'AddQuotationComponent' ? '' : this.project.id,
        type: company_user_type,
        company_id: this.company.id
      };
      this.adminService.saveNewCompanyUser(data).subscribe(res => {
        this.newCompanyUser = {} as CompanyUser;
        this.companyUsers = this.localStorageService.get('companyUsers');
        this.companyUsers.unshift(res);
        this.localStorageService.set('companyUsers', this.companyUsers);
        this.sendCompanyUser(res)
        this.companyUserSearchBox = false;

      });
    }
  }

  setCompanyUser(item: CompanyUser) {
    // alert('dkm')

      // if (this.verifyUserStatus(item?.id)) {
        //if varification TRUE then flow will go ahead
      if ((this.parentComponent.pageFrom == 'project-setting' || this.parentComponent.pageFrom == 'warehouse-setting') && item.mobile == 0){
          const modal = this.modalService.open(PartyMemberModalComponent);
        modal.componentInstance.data = { project: this.project, companyName: item.name, companyMobile: null, hasCompanyUser: item, source: this.parentComponent.pageFrom == 'project-setting' || this.parentComponent.pageFrom == 'warehouse-setting' ?'teamMember':'' };
          modal.result.then(res=>{
            const index = this.companyUsers.findIndex(user=>user.id === res.id);
            this.companyUsers[index] = res;
            this.sendCompanyUser(res)
          })
        }else{
          this.companyUser = item;
          this.formInputError = ''
          this.companyUserSearchBox = false;
          this.sendCompanyUser(item);
        }
    }

  checkName(companyUserName: string) {
    const localCompanyUser = this.localStorageService.get('companyUsers') as CompanyUser[];
    const findCompanyUser = localCompanyUser.filter(item => item.name === companyUserName);
    if (findCompanyUser.length <= 0) {
      // this.sendCompanyUser()
    } else {
      return this.companyUser;
    }
  }

  sendCompanyUser(item?: CompanyUser) {
    this.formInputError = '';
    this.onCompanyUserSelect.emit(item)
  }
  setError(value: boolean) {
    value == true ? this.formInputError = 'partyName' : this.formInputError = '';
  }

  addPartyCanvas(item) {
    if ((item == null && !this.authService.hasAccess(this.authService.companyUserPolicy.Add_new_party)) || (item != null && !this.authService.hasAccess(this.authService.companyUserPolicy.Edit_party_detail))) {
      this.notificationService.initiate({ title: 'You don\'t have access', type: toastTypes.error });
      return
    }
    const defaultValue = this.companyUser.name;
    let type = 'vendor';
    let mobile = false;
    let from: string;
    this.closeSearchBox();
    console.log(this.parentComponent?.pageFrom)
    if (this.parentComponent?.pageFrom) {
      switch (this.parentComponent?.pageFrom) {
        case 'sales-invoice':
          type = CompanyPartyType.Customer;
          from = 'sales-invoice'
          break;
        case 'deposit':
          type = CompanyPartyType.Customer;
          from = 'deposit'
          break;
        case 'project-setting':
          type = CompanyPartyType.Employee;
          from = 'project-setting'
          mobile = true;
          break;
        case 'warehouse-setting':
          type = CompanyPartyType.Employee;
          from = 'warehouse-setting'
          mobile = true;
          break;
        case 'materialPurchase':
          type = CompanyPartyType.MaterialSupplier;
          from = 'materialPurchase';
          break;
        case 'materialInventory':
          type = CompanyPartyType.MaterialSupplier;
          from = 'materialPurchase';
          break;  
        case 'attendance':
          type = CompanyPartyType.LabourContractor;
          from = 'attendance'
          break;
        case 'AddQuotationComponent':
          type = CompanyPartyType.Customer;
          from = 'AddQuotationComponent';
          break;
        case 'equipment':
          type = CompanyPartyType.Customer;
          from = 'equipment';
          break;
        case 'create-subcon':
          type = CompanyPartyType.SubContractor;
          from = 'create-subcon';
          break;
        case 'purchase-order':
          type = CompanyPartyType.MaterialSupplier;
          from = 'purchase-order';
          break;

        default:
          type = CompanyPartyType.Vendor;
      }
    }
    const canvasFeatures = this.ngbOffcanvas.open(AddPartyLibraryComponent, { position: 'end', backdrop: 'static', panelClass: 'offcanvas-sm' });
    canvasFeatures.componentInstance.parent = { 'companyUser': item, requiredFields: { mobile, name: true, type }, defaultValue: defaultValue, from: from }
    canvasFeatures.result.then(res => {
      this.companyUser = res;
      this.formInputError = '';
      this.companyUserSearchBox = false;
      this.sendCompanyUser(res);
      this.companyUsers.push(res);
    }, rej => {
      if(this.parentComponent.autoClick){
        this.companyUserNameEle.nativeElement.click();
      }
      console.log(rej)
    })
  }
  

  
  


}
