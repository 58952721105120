<div class="p-0 canvas-height">
    <!-- <app-logo-loader [state]="loading"></app-logo-loader> -->
    <div class="offcanvas-header d-flex align-items-center px-4">
      <div
        class="canvas-header-left d-flex align-items-center gap-4"
        style="width: 100%"
      >
        <app-back-button></app-back-button>
        <div class="canvas-title">
          <span style="font-weight: 700; font-size: 14px; line-height: 21px"
            >Add New Account</span
          >
        </div>
      </div>
      <div class="d-flex gap-4 ">
        <div class="canvas-cancel-btn" (click)="dismissOffCanva()">Cancel</div>
        <div class="canvas-save-btn" (click)="save()" >Save</div>
      </div>
    </div>
    <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  
    <div class="canvas-body">
      <div class="d-flex bank-info gap-3 mb-4">
        <div style="height:40px; width:40px; background-color: grey; border-radius: 5px;" class="bank-logo" *ngIf="bankLogoUrl">
            <ng-container>
              <img style="height:40px; width:40px; border-radius: 4px;" [src]="bankLogoUrl" onerror="this.src='https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/DEFAULT_LOGO.jpg'" alt="">
            </ng-container>
        </div>
        <div class="bank-info d-flex flex-column">
          <span class="f-14" >{{userBank.bank_name}}</span>
          <span class="f-12">{{userBank.bank_address}}</span>
        </div>
      </div>
  
  
      <form>
        <div class="custom-input mb-4">
          <label for="accountHolderName">Account Holder Name:</label>
          <input type="text" id="accountHolderName" name="accountHolderName" [(ngModel)]="userBank.account_name">
        </div>
  
        <div class="custom-input mb-4" >
          <label for="accountNumber">Account Number:</label>
          <input type="text" id="accountNumber" name="accountNumber" [(ngModel)]="userBank.account_number">
        </div>
      
      <div class="custom-input mb-4" >
        <label for="bankCode">{{authService.session.company.country_iso == 'in'?'IFSC Code':'Bank/Branch code'}}:</label>
        <input type="text" id="bankCode" name="bankCode" (ngModelChange)="onValidateBankCode($event)" [(ngModel)]="userBank.bank_code" autocomplete="off" >
      </div>
      
      <div class="custom-input mb-4" >
        <label for="bankName">Bank Name:</label>
        <input type="text" id="bankName" name="bankName" [(ngModel)]="userBank.bank_name">
      </div>

      <div class="custom-input mb-4" >
        <label for="bankAddress">Bank Address:</label>
        <input type="text" id="bankAddress" name="bankAddress" [(ngModel)]="userBank.bank_address">
      </div>

      <div class="custom-input mb-4" >
        <label for="iBanNumber">Iban Number:</label>
        <input type="text" id="iBanNumber" name="iBanNumber" [(ngModel)]="userBank.iban_number">
      </div>

      <div *ngIf="authService.session.country.country_iso === 'in'" class="custom-input" >
        <label for="upiNumber">UPI Number:</label>
        <input type="text" id="upiNumber" name="upiNumber" [(ngModel)]="upi_id">
      </div>

        
  
  
      </form>
    </div>
  
  
  
  </div>
  