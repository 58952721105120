import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { CompanyUser, Country, Project } from "src/app/interfaces/interfaces";
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-party-member-modal',
  templateUrl: './party-member-modal.component.html',
  styleUrls: ['./party-member-modal.component.css']
})

export class PartyMemberModalComponent implements OnInit {
  @Input() data: any;
  public formError = { name: false, mobile: false };
  public companyUser = {} as CompanyUser;
  public companyUsers = [] as CompanyUser[];
  public project = {} as Project;
  public mobileString = '';
  public country = {} as Country;
  public countries = [] as Country[];

  constructor(
    private activeModal: NgbActiveModal,
    private adminService: AdminService,
    private authService:AuthService,
    modalConfig: NgbModalConfig,
    public localStorageService: LocalStorageService,
    private notificationService: NotificationsService,
  ) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit(): void {
    this.country = this.localStorageService.get('country');
    if(!this.country){
      this.getCountryList()
    }

    this.project = this.data.project;
    this.data.companyName ? this.companyUser.name = this.data.companyName : this.companyUser.name = null;
  }

  getCountryList() {
    this.authService.getCountryList().subscribe({
      next: (res) => {
        this.countries = res || [];
        for(const item of this.countries){
          if(item.country_iso == this.authService.session.company.country_iso){
            this.country = item;
          }
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  close() {
    this.activeModal.dismiss();
  }
  save() {
    if (this.data.hasCompanyUser) {
      this.adminService.editCompnayUserList({ ...this.data.hasCompanyUser, mobile: this.companyUser?.mobile }).subscribe({
        next: (res) => {
          this.notificationService.initiate({ title: 'Successfully Added', type: toastTypes.success });
          this.activeModal.close(res);
        },
        error: (err) => {
          this.notificationService.initiate({ title: 'Something went wrong!', type: toastTypes.error });
          this.activeModal.dismiss();
        }
      })

    } else {
      this.companyUsers = this.localStorageService.get('companyUsers')
      const data = {
        name: this.companyUser.name,
        mobile: this.companyUser.mobile,
        project_id: this.project.id,
        type: 'vendor'
      };

      switch (this.data?.source) {
        case 'project-setting': {
          data.type = 'employee';
          break;
        }
        case 'deposit': {
          data.type = 'client';
          break;
        }
        case 'sales-invoice': {
          data.type = 'client';
          break;
        }
        default: {
          data.type = 'vendor'
        }
      }
      
      //Error check
      if (this.data?.source === 'project-setting' && !this.companyUser.mobile) {
        return this.formError.mobile = true;
      }
      //Error check
      if(this.companyUser.mobile){
        if (data.mobile.toString().length < this.country.minimum_digits.toString().length || 
          data.mobile.toString().length > this.country.maximum_digits.toString().length) {
          return this.formError.mobile = true;
        }
      }
      this.adminService.saveNewCompanyUser(data).subscribe(res => {
        this.companyUsers.push(res)
        this.localStorageService.set('companyUsers', this.companyUsers)
        this.activeModal.close(res);
      });
    }

  }

  validateMobile(evt) {
    const re = /[^0-9]+/
    const updatedString = evt.target.value.replace(re, '')
    .substring(0, this.country.maximum_digits.toString().length).trim();
    if (updatedString != evt.target.value) {
      if (updatedString.length > 0) {
        this.mobileString = updatedString;
        this.companyUser.mobile = parseInt(updatedString);
      } else {
        this.mobileString = null;
        this.companyUser.mobile = null;
      }
    } else {
      this.companyUser.mobile = parseInt(updatedString);
    }
  }
}
