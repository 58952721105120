import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  public data: any;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }
  goNext() {
    this.activeModal.close(true);
  }
  cancelModal() {
    this.activeModal.dismiss(false);
  }
}
