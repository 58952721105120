import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyUser } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/shared/services/validator.service';

interface Parent {
  companyUser?:CompanyUser
}
@Component({
  selector: 'app-party-opening-balance',
  templateUrl: './party-opening-balance.component.html',
  styleUrls: ['./party-opening-balance.component.css']
})
export class PartyOpeningBalanceComponent implements OnInit {
  @Input() parent:Parent = {
    companyUser:{} as CompanyUser
  }
  public paymentType:'pay'|'receive' = 'receive';
  public amount:number;

  constructor(
    public ngbActiveOffcanvas:NgbActiveOffcanvas,
    private adminService:AdminService,
    private ngbOffActiveCanvas:NgbActiveOffcanvas,
    public authService: AuthService,
    private validatorService: ValidatorService
  ){
    //
  }
  ngOnInit(): void {
    if(this.parent.companyUser?.id){
      this.amount = this.parent.companyUser.opening_balance
      if(this.parent.companyUser?.opening_balance>0){
        this.paymentType = 'receive';
      }else if(this.parent.companyUser?.opening_balance < 0) {
        this.paymentType = 'pay'
      }
      this.amount = Math.abs(this.parent.companyUser.opening_balance);
    }
  }

  calculateAmount(e) {
    e.target.value = this.validatorService.numberFormat(e, { type: 'string', decimal: true, digit: this.authService.session.country.decimal_digit || 3 });
    this.amount = parseFloat(e.target.value) || 0;
  }

  save(){
   if(this.parent.companyUser?.id){

    if(this.paymentType === 'pay'){
      this.parent.companyUser.opening_balance = -this.amount;
    }else if(this.paymentType === 'receive'){
      this.parent.companyUser.opening_balance = this.amount;
    }

    this.adminService.editOpeningBalance({
      id:this.parent.companyUser.id,
      opening_balance:this.parent.companyUser.opening_balance
    }).subscribe({
      next:res=>{
       
        this.ngbOffActiveCanvas.close(res);
      },
      error:err=>{
        //
      }
    });

   }else{
    if(this.paymentType === 'pay'){
      this.ngbActiveOffcanvas.close(-this.amount);
    }else if(this.paymentType === 'receive'){
      this.ngbActiveOffcanvas.close(this.amount);
    }
   }
  }


}
