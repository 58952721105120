import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { Company, Project, Token } from '../interfaces/interfaces';
import { AuthService } from '../core/services/auth.service';
import { NotificationsService, toastTypes } from '../core/services/notifications.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  private JSONMAP = new Map<string, any>();
  private company: Company;
  private project: Project;
  
  private readonly cacheableUrls = [
    'https://testapi.onsiteteams.in/apis/v3/list/platforminfo',
  ];
  
  private readonly superAdminUrls = [
    'admin/add/customfield', 
    'admin/upload/material/bycsv',
    'admin/upload/boq/bycsv',
    'admin/delete/customfield'
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private notificationService: NotificationsService
  ) {
    setInterval(() => this.JSONMAP.clear(), 10 * 60 * 1000);
    this.project = this.localStorageService.get('project');
    this.company = this.localStorageService.get('company');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isSuperAdminRequest = this.superAdminUrls.some(url => req.url.includes(url));
    const token: Token = isSuperAdminRequest 
      ? this.authService.getPrimaryToken() 
      : this.localStorageService.get('token');

    const modifiedReq = req.clone({
      setHeaders: {
        ...(token && { Authorization: `Bearer ${token.token}` }),
        ...(this.project?.id && { project_id: this.project.id }),
        ...(this.company?.id && { project_company_id: this.company.id }),
        Accept: 'application/json',
        'version-code': '171',
      }
    });

    if (modifiedReq.method === 'GET' && this.isCacheable(modifiedReq.url)) {
      const cachedResponse = this.JSONMAP.get(modifiedReq.urlWithParams);
      if (cachedResponse) {
        return new Observable(observer => {
          observer.next(cachedResponse);
          observer.complete();
        });
      }

      return next.handle(modifiedReq).pipe(
        tap(response => this.JSONMAP.set(modifiedReq.urlWithParams, response)),
        catchError(err => throwError(() => err)) // Simply rethrow error for GET requests
      );
    }

    // Handle all requests (POST and others)
    return next.handle(modifiedReq).pipe(
      catchError(err => {
        // Only call handleError for POST requests
        if (modifiedReq.method === 'POST' || modifiedReq.method === 'DELETE' || modifiedReq.method === 'PATCH') {
          return this.handleError(err);
        }
        // For non-POST requests, just rethrow the error
        return throwError(() => err);
      })
    );
  }

  private isCacheable(url: string): boolean {
    return this.cacheableUrls.some(cacheUrl => url.startsWith(cacheUrl));
  }

  private handleError(error: any): Observable<never> {
    if (error.status > 399) {
      // Optionally log or handle errors here
      this.notificationService.initiate({ title: error.error.message, type: toastTypes.error });
    }
    return throwError(() => error);
  }
}