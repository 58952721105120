import { Pipe, PipeTransform } from '@angular/core';
import { Material } from 'src/app/interfaces/interfaces';

@Pipe({
  name: 'materialStatus'
})
export class MaterialStatusPipe implements PipeTransform {
  transform(material: Material): string {
    if (material.type === "deposit" && material.is_purchased === 0 && material.is_grn === 1) {
      return "Received";
    } 
    else if (material.type === "withdraw" && material.is_material_transferred === 1) {
      return "Transfer Out";
    } 
    else if (material.type === "withdraw" && material.is_material_returned === 1) {
      return "Material Return";
    } 
    else if (material.type === "deposit" && material.is_material_transferred === 1) {
      return "Transfer In";
    } 
    else if (material.type === "deposit" && material.is_purchased === 1) {
      return "Purchased";
    } 
    else if (material.type === "withdraw" && material.is_material_transferred === 0 && material.is_material_returned === 0) {
      return "Used";
    } 
    else {
      return "Unknown Type";
    }  
  }
}
