import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModalComponent } from '../components/modal/calendar-modal/calendar-modal.component';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(
    private ngbModal:NgbModal,
    private authService: AuthService
  ) {
    //
  }

  openCalendar(myDate): Promise<any> {
    const calendar = this.ngbModal.open(CalendarModalComponent, { windowClass: 'w-auto top-50 start-50 translate-middle' });
    myDate = new Date(myDate);
    calendar.componentInstance.model = { year: myDate.getFullYear(), month: myDate.getMonth(), day: myDate.getDate() };
    return calendar.result
  }

  dateToUtc(day, month, year, hours?: number, minutes?: number, seconds?: number, milliseconds?: number) {
    const myDate = new Date();
    myDate.setFullYear(year, month-1, day);
    if (hours !== null && hours !== undefined) {
      myDate.setHours(hours);
    }
    if (minutes !== null && minutes !== undefined) {
      myDate.setMinutes(minutes);
    }
    if (seconds !== null && seconds !== undefined) {
      myDate.setSeconds(seconds)
    }
    if (milliseconds !== null && milliseconds !== undefined) {
      myDate.setMilliseconds(milliseconds)
    }
    return myDate.toISOString()
  }

  numberFloatSanitize(num: number): number {
    if (num % 1 == 0) {
      return num
    } else {
      return Number(num.toFixed(this.authService.session.country.decimal_digit))
    }
  }

  numberValidate(num: number, digit, option?:{}): number {
    let temp = num.toString();
    temp = temp.split('').slice(0, digit).join('')
    temp = temp.replace(/[^0-9-.]/g, '');
    temp = Number(temp).toFixed(this.authService.session.country.decimal_digit)
    return parseFloat(temp)
  }

  trimString(input): string {
    return input.trimLeft()
  }
  numberFormat(e, option?) {
    const digitCount = this.authService.session.country.decimal_digit || 2;
    let t = e.target.value;
    const indexOfDecimal = t.indexOf(".")
    if(indexOfDecimal >= 0){
      if(indexOfDecimal>11){
        let tail = t.substring(indexOfDecimal);
        let head = t.slice(0,indexOfDecimal)
        e.target.value = head+tail
      }
      e.target.value =  t.slice(0, indexOfDecimal + digitCount + 1)
    }else{
      if(t.length>11){
        t = t.slice(0,11)
      }
      e.target.value = t;
    }
    const re = new RegExp(`[^0-9.]{0,${digitCount}}`, 'g');
    // /^\d{0,11}(\.\d{0,3})?$/
    const updatedString = e.target.value.replace(re, '').trim();
    if (updatedString != e.target.value) {
      e.target.value = updatedString.length > 0 ? updatedString : null
    } else {
      e.target.value = updatedString;
    }
    let value = option.type == 'number' ? (option.decimal ? parseFloat(e.target.value || 0) : parseInt(e.target.value || 0)) : e.target.value;
    if(Number.isNaN(value) && e.target.value == '.'){
      return value = e.target.value;
    }
    return value ;
  }
  validateGSTIN(gstin: string): boolean {
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/;
    return gstinRegex.test(gstin);
  }
  valueFormat(value: any, option?) {
    const digitCount = this.authService.session.country.decimal_digit || 2;
    let t = value.toString();
    const indexOfDecimal = t.indexOf(".")
    value = indexOfDecimal >= 0 ? t.slice(0, indexOfDecimal + digitCount + 1) : t;
    const re = !option.decimal ? /[^0-9]/g : new RegExp(`[^0-9.]{0,${digitCount}}`, 'g');;
    const updatedString = value.replace(re, '').trim();
    if (updatedString != value) {
      value = updatedString.length > 0 ? updatedString : null
    } else {
      value = updatedString;
    }
    value = option.decimal ? parseFloat(value || 0) : parseInt(value || 0) || 0;
    return value;
  }

}
