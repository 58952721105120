<app-logo-loader [state]="loading"></app-logo-loader>
<div style="padding: 30px 40px 0px 40px">
  <div class="d-flex flex-column">
    <div class="d-flex" style="margin-bottom: 20px">
      <div class="f-18" style="font-weight: 500">Company Setting</div>
      <div class="ms-auto">
        <svg class="pointer" (click)="activeModal.dismiss();" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div *ngIf="authService.hasAccess(authService.companyUserPolicy.Comapny_details)" class="tabs_container">
      <div class="d-flex p-0" style="margin-left: 0 !important">
        <span class="cursor single_tab text-muted custom-text f-14"
          [ngClass]="componentTab == 'companyDetails' ? 'active' : ''" (click)="componentTab = 'companyDetails'">
          Company Details
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text f-14"
          [ngClass]="componentTab == 'bankDetails' ? 'active' : ''" (click)="componentTab = 'bankDetails'">
          Bank Details
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text f-14"
          [ngClass]="componentTab == 'companyBackLock' ? 'active' : ''" (click)="componentTab = 'companyBackLock'">
          Lock Back-Data Entry
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text f-14"
          [ngClass]="componentTab == 'businessOverview' ? 'active' : ''"
          (click)="componentTab = 'businessOverview'; companyOverview();">
          Business Overview
          <div class="active_tab_line"></div>
        </span>
      </div>
    </div>
  </div>
</div>
<div style="border-top: 1px solid #e5e7f3; margin-bottom: 36px"></div>
<div style="padding: 0px 40px 30px 40px">
  <div *ngIf="authService.hasAccess(authService.companyUserPolicy.Comapny_details)" class="d-flex flex-column gap-1">
    <ng-template [ngIf]="componentTab == 'companyDetails'">
      <div class="row mt-3">
        <div class="col-12 col-sm-3 d-flex flex-column justify-content-between align-items-center">
          <div class="d-flex flex-column justify-content-between align-items-center">
            <div style="margin-bottom: 16px;" class="project-photo">
              <img *ngIf="company?.logo" [src]="company.logo" />
              <div class="project-name" *ngIf="!company?.logo">{{ company.name | shortName }}</div>
            </div>
            <div style="margin-bottom: 8px;">
              <span class="project-img-replace-btn" (click)="selectFileInput()">Replace Logo</span>
            </div>
            <input class="cursor" id="picInput" type="file" [(ngModel)]="tempImg" (change)="replaceImg($event)"
              hidden />
          </div>
          <button (click)="leaveCompany()" class="btn btn-outline-danger f-12 mt-2 d-flex gap-2 align-items-center"
            style="padding: 8px 16px;">
            <div>
              <ng-icon name="featherLogOut" style="transform: scale(1.3);"></ng-icon>
            </div>
            <span style="margin-top: 3px;">Leave Company</span>
          </button>
        </div>
        <div class="col-12 col-sm-9">
          <div class="row gx-3 gy-3">
            <div class="col-6">
              <div class="custom-input" style="width: 100%">
                <label class="custom-text d-none d-lg-block">Company Name</label>
                <label class="custom-text d-block d-lg-none">Company</label>
                <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="company.name" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input" style="width: 100%">
                <label class="custom-text d-none d-lg-block">Phone Number</label>
                <label class="custom-text d-block d-lg-none">Phone</label>
                <input class="custom-text" type="number" autocomplete="off" [(ngModel)]="company.mobile" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input" style="width: 100%">
                <label class="custom-text d-none d-lg-block">Company Address</label>
                <label class="custom-text d-block d-lg-none">Address</label>
                <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="company.address" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input" style="width: 100%">
                <label class="custom-text">City</label>
                <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="company.city" />
              </div>
            </div>
            <div *ngIf="authService.session.country.country_iso === 'in'" class="col-6">
              <div class="custom-input" style="width: 100%">
                <label class="custom-text d-none d-lg-block">PAN number</label>
                <label class="custom-text d-block d-lg-none">PAN</label>
                <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="company.pan_number" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input" style="width: 100%">
                <label
                  class="custom-text d-none d-lg-block">{{authService.session.country.tax_display_name?authService.session.country.tax_display_name:'Tax'}}</label>
                <!-- <label class="custom-text d-block d-lg-none">{{authService.session.country.tax_display_name?authService.session.country.tax_display_name:'Tax'}}</label> -->
                <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="company.gstin" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="componentTab == 'bankDetails'">
      <div *ngIf="companyBanks.length < 1" class="d-flex justify-content-center align-items-center">
        <button type="button" (click)="addAccountCanvas('add')" class="custom-add-bank-btn f-14">
          + New Account
        </button>
      </div>

      <div *ngIf="companyBanks.length > 0">
        <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
          [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page.next_url == ''"
          class="bank-account-container">
          <ng-container *ngFor="let bank of companyBanks; index as i">
            <div (click)="openBoxStatus = false" class="row px-2 mb-4">
              <div class="col-lg-4 d-flex justify-content-between mt-1 mb-2">
                <div class="d-flex">
                  <div style="
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    background-color: grey;
                    margin-right: 8px;
                  ">
                    <img style="height: 40px; width: 40px; border-radius: 4px" [src]="
                      getBankUrl(bank.monkey_patch_bank_account?.bank_code)
                    " onerror="this.src='https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/DEFAULT_LOGO.jpg'"
                      alt="" />
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <span class="f-14">{{
                      bank?.monkey_patch_bank_account?.bank_name
                      }}</span>
                    <span class="f-12">{{
                      bank?.monkey_patch_bank_account?.bank_address
                      }}</span>
                  </div>
                </div>


                <div class="d-flex align-items-center">
                  <span *ngIf="bank?.primary" class="primary-badge d-lg-none">primary</span>
                  <div style="position: relative; " class="cursor d-flex flex-column align-items-end d-lg-none">
                    <img (click)=" $event.stopPropagation(); openOptionsBox(i)"
                      src="../../../../../assets/svg/3-dot-vertical-grey.svg" alt="" />
                    <div *ngIf="openBoxStatus && i === selectedBoxIndex"
                      style="margin-right: 4px; position: absolute; top: 22px;"
                      class="d-flex flex-column bank-account-options mt-1">
                      <span (click)="addAccountCanvas('edit',bank)" class="f-12 cursor">Edit</span>
                      <hr class="m-0" />
                      <span class="f-12" (click)="deleteCompanyBankAccount(bank?.id)">Delete</span>
                      <hr *ngIf="!bank?.primary" class="m-0" />
                      <span *ngIf="!bank?.primary" (click)="setPrimaryCompanyBankAccount(bank?.id)"
                        class="f-12 cursor">Set Primary</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex flex-column">
                <span class="f-14 bankDetailsKey">AC Holder:
                  <span class="f-14 bankDetailsValue">{{
                    bank?.monkey_patch_bank_account?.account_name
                    }}</span>
                </span>
                <span class="f-14 bankDetailsKey">AC Number:
                  <span class="f-14 bankDetailsValue">{{
                    bank?.monkey_patch_bank_account?.account_number
                    }}</span>
                </span>

                <span class="f-14 bankDetailsKey">{{authService.session.company.country_iso == 'in'?'IFSC
                  Code':'Bank/Branch code'}}:
                  <span class="f-14 bankDetailsValue">{{
                    bank?.monkey_patch_bank_account?.bank_code
                    }}</span></span>
                <span class="f-14 bankDetailsKey">Iban No:
                  <span class="f-14 bankDetailsValue">{{
                    bank?.monkey_patch_bank_account?.iban_number
                    }}</span></span>
                <span *ngIf="authService.session.country.country_iso === 'in'" class="f-14 bankDetailsKey">UPI:
                  <span class="f-14 bankDetailsValue">{{
                    bank?.monkey_patch_bank_account?.upi_ids[0]
                    }}</span></span>
              </div>
              <div class="col-lg-4 d-flex justify-content-end">
                <span *ngIf="bank?.primary" class="primary-badge d-none d-lg-block">primary</span>


                <div style="position: relative;" class="cursor d-flex flex-column align-items-end d-none d-lg-block">
                  <img (click)=" $event.stopPropagation(); openOptionsBox(i)"
                    src="../../../../../assets/svg/3-dot-vertical-grey.svg" alt="" />
                  <div *ngIf="openBoxStatus && i === selectedBoxIndex"
                    style="margin-right: 4px; position:absolute; top: 22px; right:2px; white-space: nowrap;"
                    class="d-flex flex-column bank-account-options mt-1">
                    <span (click)="addAccountCanvas('edit',bank)" class="f-12 cursor">Edit</span>
                    <hr class="m-0" />
                    <span class="f-12 cursor" (click)="deleteCompanyBankAccount(bank?.id)">Deleted</span>
                    <hr *ngIf="!bank?.primary" class="m-0" />
                    <span *ngIf="!bank?.primary" (click)="setPrimaryCompanyBankAccount(bank?.id)"
                      class="f-12 cursor">Set Primary</span>
                  </div>
                  <div class="bank-details-options">

                  </div>
                </div>


              </div>
            </div>
          </ng-container>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button type="button" (click)="addAccountCanvas('add')" class="custom-add-bank-btn f-14 cursor">
            + New Account
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="componentTab == 'companyBackLock'">
      <div class="vstack gap-4">
        <div class="d-flex gap-4 align-items-center">
          <div class="d-flex align-items-center gap-3">
            <span class="allow-text-width">Don't Allow New Entry After</span>
            <input [disabled]="!allowNewEntries" class="custom-input-date" type="number"
              [placeholder]="add_locked_hours / 24 + ' ' + 'Days'"
              [value]="!allowNewEntries ? '' : add_locked_hours / 24" autocomplete="off"
              (keyup)="setLockBackAddDate($event)" />
          </div>
          <div>
            <div>
              <div *ngIf="!allowNewEntries" (click)="allowNewEntries = true">
                <svg class="edit-delete-icons" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.125 4.05469H4.29167C3.84964 4.05469 3.42572 4.23028 3.11316 4.54284C2.80059 4.8554 2.625 5.27933 2.625 5.72135V17.388C2.625 17.83 2.80059 18.254 3.11316 18.5665C3.42572 18.8791 3.84964 19.0547 4.29167 19.0547H15.9583C16.4004 19.0547 16.8243 18.8791 17.1368 18.5665C17.4494 18.254 17.625 17.83 17.625 17.388V11.5547"
                    stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M16.375 2.80488C16.7065 2.47336 17.1562 2.28711 17.625 2.28711C18.0938 2.28711 18.5435 2.47336 18.875 2.80488C19.2065 3.1364 19.3928 3.58604 19.3928 4.05488C19.3928 4.52372 19.2065 4.97336 18.875 5.30488L10.9583 13.2215L7.625 14.0549L8.45833 10.7215L16.375 2.80488Z"
                    stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div *ngIf="allowNewEntries" class="d-flex justify-content-between align-items-center edit-option">
                <div>
                  <span (click)="AddCompanyBackLock()" class="project-btn save">Save</span>
                </div>
                <div class="close_custom_btn">
                  <svg class="close-icon" (click)="allowNewEntries = false" viewBox="0 0 44 44" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22" cy="22" r="22" fill="white" />
                    <path d="M28 17L16 29" stroke="#3F3F3F" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16 17L28 29" stroke="#3F3F3F" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex gap-4 align-items-center">
          <div class="d-flex align-items-center gap-3">
            <span class="allow-text-width">
              Don't Allow edit for Old Entries After
            </span>
            <input [disabled]="!allowEditingOldEntries" class="custom-input-date" type="number"
              [placeholder]="edit_locked_hours / 24 + ' ' + 'Days'"
              [value]="!allowEditingOldEntries ? '' : edit_locked_hours / 24" autocomplete="off"
              (keyup)="setLockBackDate($event)" />
          </div>
          <div>
            <div *ngIf="!allowEditingOldEntries" (click)="allowEditingOldEntries = true">
              <svg class="edit-delete-icons" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.125 4.05469H4.29167C3.84964 4.05469 3.42572 4.23028 3.11316 4.54284C2.80059 4.8554 2.625 5.27933 2.625 5.72135V17.388C2.625 17.83 2.80059 18.254 3.11316 18.5665C3.42572 18.8791 3.84964 19.0547 4.29167 19.0547H15.9583C16.4004 19.0547 16.8243 18.8791 17.1368 18.5665C17.4494 18.254 17.625 17.83 17.625 17.388V11.5547"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M16.375 2.80488C16.7065 2.47336 17.1562 2.28711 17.625 2.28711C18.0938 2.28711 18.5435 2.47336 18.875 2.80488C19.2065 3.1364 19.3928 3.58604 19.3928 4.05488C19.3928 4.52372 19.2065 4.97336 18.875 5.30488L10.9583 13.2215L7.625 14.0549L8.45833 10.7215L16.375 2.80488Z"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div *ngIf="allowEditingOldEntries" class="d-flex justify-content-between align-items-center edit-option">
              <div>
                <span (click)="editCompanyBackLock()" class="project-btn save">Save</span>
              </div>
              <div class="close_custom_btn">
                <svg class="close-icon" (click)="allowEditingOldEntries = false" viewBox="0 0 44 44" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="white" />
                  <path d="M28 17L16 29" stroke="#3F3F3F" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 17L28 29" stroke="#3F3F3F" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="componentTab == 'businessOverview'">
      <div class="d-flex flex-column gap-3">
        <div class="d-flex flex-column p-3 rounded" style="border: 1px solid #F1F1F1;">
          <div class="d-flex align-items-center gap-2 mb-3">
            <img src="../../../../assets/svg/combo-chart.svg" />
            <span class="f-16">Avg. Business / Year</span>
          </div>
          <div class="d-flex gap-2 f-12">
            <span (click)="companyConfig.business_amount = 10"
              [ngClass]="companyConfig.business_amount == 10 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">1cr-10cr</span>
            <span (click)="companyConfig.business_amount = 20"
              [ngClass]="companyConfig.business_amount == 20 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">10cr-20cr</span>
            <span (click)="companyConfig.business_amount = 50"
              [ngClass]="companyConfig.business_amount == 50 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">20cr-50cr</span>
            <span (click)="companyConfig.business_amount = 100"
              [ngClass]="companyConfig.business_amount == 100 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">50cr-100cr</span>
          </div>
        </div>

        <div class="d-flex flex-column p-3 rounded" style="border: 1px solid #F1F1F1;">
          <div class="d-flex align-items-center gap-2 mb-3">
            <img src="../../../../assets/svg/workers.svg" />
            <span class="f-16">Company Size</span>
          </div>
          <div class="d-flex gap-2 f-12">
            <span (click)="companyConfig.company_size = 20"
              [ngClass]="companyConfig.company_size == 20 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">1-20</span>
            <span (click)="companyConfig.company_size = 50"
              [ngClass]="companyConfig.company_size == 50 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">20-50</span>
            <span (click)="companyConfig.company_size = 100"
              [ngClass]="companyConfig.company_size == 100 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">50-100</span>
            <span (click)="companyConfig.company_size = 200"
              [ngClass]="companyConfig.company_size == 200 ? 'active-span':'disabled-font'"
              class=" w-25 text-center rounded py-2 pointer" style="border: 1px solid #F1F1F1;">100-200</span>
          </div>
        </div>

        <div class="d-flex flex-column p-3 rounded" style="border: 1px solid #F1F1F1;">
          <div class="d-flex align-items-center gap-2 mb-3">
            <img src="../../../../assets/svg/safety-hat.svg" />
            <span class="f-16">Construction Type</span>
          </div>
          <div class="custom-div mb-3 f-14" (click)="isSelectOpen = true">
            <span>Select</span>
            <span><ng-icon name="featherChevronRight"></ng-icon></span>
          </div>
          <div class="d-flex flex-wrap gap-2 f-12">
            <div class="d-flex text-center px-3 py-2 gap-1 rounded" style="background: #F1F1F1;"
              *ngFor="let type of selectedConstructionType">
              <span>{{type.text_en}}</span>
              <ng-icon class="pointer" (click)="removeConstructionType(type.id)" name="featherX"></ng-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="mt-auto d-flex justify-content-between"
      *ngIf="componentTab == 'companyDetails' || componentTab == 'businessOverview'"
      style="margin-top: 40px !important">
      <div class="ms-auto align-self-center">
        <span (click)="componentTab == 'companyDetails' ? editCompany() : editCompanyConfig()"
          *ngIf="componentTab == 'companyDetails' || componentTab == 'businessOverview'"
          [ngClass]="busy ? 'save-busy' : 'project-btn'" class="save">Save</span>
      </div>
    </div>

    <div *ngIf="componentTab == 'businessOverview' && isSelectOpen" class="select-canva-bg">
      <div class="select-canva">
        <div class="canva-close-btn" (click)="isSelectOpen = false">
          <ng-icon class="icon-style" name="featherChevronsDown"></ng-icon>
        </div>
        <div class="p-4 mx-2 mt-3 overflow-y-auto" style="height: 38vh;">
          <div class="d-flex justify-content-between gap-2 py-3" *ngFor="let type of categoryList">
            <span>{{type.text_en}}</span>
            <input type="checkbox" (change)="handleSelectedType($event,type)"
              [checked]="companyConfig.construction_category_ids?.includes(type.id)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!authService.hasAccess(authService.companyUserPolicy.Comapny_details)">
    <button (click)="leaveCompany()" class="btn btn-outline-danger f-12 mt-2 d-flex gap-2 align-items-center"
      style="padding: 8px 16px;">
      <div>
        <ng-icon name="featherLogOut" style="transform: scale(1.3);"></ng-icon>
      </div>
      <span style="margin-top: 3px;">Leave Company</span>
    </button>
  </div>
</div>