import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-video-palyer',
  templateUrl: './video-palyer.component.html',
  styleUrls: ['./video-palyer.component.css']
})
export class VideoPalyerComponent {
  @Input() videoId:string;
  public videoUrl: SafeResourceUrl;
  constructor(
    private sanitizer: DomSanitizer,
  ) {
    //
  }
  ngOnInit(): void {
    this.videoUrl = this.sanitizeUrl(`https://www.youtube.com/embed/${this.videoId}?autoplay=1&rel=0`);
  }
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
