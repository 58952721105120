import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Company, CustomField } from "src/app/interfaces/interfaces";
import { AdminService } from "src/app/core/services/admin.service";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "src/app/core/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateModalComponent } from "src/app/company-settings/company-settings/components/custom-fields/components/custom-date-modal/custom-date-modal.component";
import { FormGroup, FormsModule } from "@angular/forms";
import { featherCalendar, featherX } from "@ng-icons/feather-icons";
import { NgIconsModule } from "@ng-icons/core";
import {
  CustomFieldDataType,
} from "src/app/enum/custom_fields";
import { IPOP_FIELD_TYPE } from "src/app/enum/ipop-field-type-enum";
import { ValidatorService } from "src/app/shared/services/validator.service";
@Component({
  selector: "app-custom-fields-update",
  standalone: true,
  imports: [CommonModule, NgIconsModule, FormsModule],
  templateUrl: "./custom-fields-update.component.html",
  styleUrls: ["./custom-fields-update.component.css"],
})
export class CustomFieldsUpdateComponent implements OnInit {
  @Input() existing_customFieldsList: CustomField[];
  @Input() customField_type: string;
  // @Input() po_id: string;
  // @Input() grn_id: string;
  // @Input() sales_invoice_id: string;
  // @Input() quotation_id: string;
  // @Input() boq_workorder_id: string;
  // @Input() subcon_workorder_id: string;

  featherCalendar = featherCalendar;
  featherX = featherX
  CustomFieldDataType = CustomFieldDataType;

  private company = {} as Company;
  public customFieldsList: CustomField[] = [];
  public customFieldsForm: FormGroup;
  private ExistingCustomFieldsMap = new Map<string, CustomField>();
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private ngbModal: NgbModal,
    public validatorService: ValidatorService
  ) {
    this.company = this.authService.session.company;
  }

  ngOnInit(): void {
    this.getCustomFieldList();
  }

  createMapOfExistingCustomFieldsAndSwapCustomFieldWithExisting() {
    this.ExistingCustomFieldsMap.clear();

    if (!this.existing_customFieldsList?.length) {
      return;
    }

    this.existing_customFieldsList.forEach((customField) => {
      if (customField?.id) {
        this.ExistingCustomFieldsMap.set(customField.id, customField);
      }
    });

    if (
      this.ExistingCustomFieldsMap.size === 0 ||
      !this.customFieldsList?.length
    ) {
      return;
    }

    this.customFieldsList.forEach((customField) => {
      if (customField?.id && this.ExistingCustomFieldsMap.has(customField.id)) {
        const existingField = this.ExistingCustomFieldsMap.get(customField.id);
        if (existingField?.default_value !== undefined) {
          customField.default_value = existingField.default_value;
        }
      }
    });
  }

  getCustomFieldList() {
    let params = new HttpParams();
    params = params.append("company_id", this.company.id);
    params = params.append("custom_field_type", this.customField_type);

    this.adminService.getCustomFieldList(params).subscribe((res) => {
      this.customFieldsList = res.data || [];
      this.customFieldsList.forEach((customField) => {
        if (!customField.default_value_enabled) {
          customField.default_value = "";
        }
      });
      if (this.existing_customFieldsList) {
        this.createMapOfExistingCustomFieldsAndSwapCustomFieldWithExisting();
      }
    });
  }

  openDatePicker(customField: CustomField) {
    const ngbModalRef = this.ngbModal.open(CustomDateModalComponent, {
      size: "md",
      centered: true,
      windowClass: "custom-modal",
      backdropClass: "custom-modal-backdropClass",
    });

    ngbModalRef.componentInstance.source = customField.default_value;

    ngbModalRef.result.then(
      (onResult) => {
        customField.default_value = onResult.selectedDate;
      },
      (onReject) => {
        //
      }
    );
  }

  returnCustomFields():CustomField[] {
    const customFieldsWithDefaultValues =
      this.customFieldsList.filter((customField) => {
        return (
          customField.default_value !== null && customField.default_value !== ""
        );
      }) || [];

      return customFieldsWithDefaultValues;
  }

  // onSave(id: string) {
  //   let observer;

  //   const customFieldsWithDefaultValues =
  //     this.customFieldsList.filter((customField) => {
  //       return (
  //         customField.default_value !== null && customField.default_value !== ""
  //       );
  //     }) || [];

  //   if (!customFieldsWithDefaultValues.length) {
  //     return;
  //   }

  //   switch (this.customField_type) {
  //     case CustomFieldType.PO: {
  //       const customPOData = {
  //         purchase_order_id: id,
  //         custom_fields: customFieldsWithDefaultValues,
  //       };
  //       observer = this.adminService.editPOCustomFields(customPOData);
  //       break;
  //     }
  //     case CustomFieldType.GRN: {
  //       const customGRNData = {
  //         grn_id: id,
  //         custom_fields: customFieldsWithDefaultValues,
  //       };

  //       observer = this.adminService.editGRNCustomFields(customGRNData);

  //       break;
  //     }

  //     case CustomFieldType.SALESORDER_INVOICE: {
  //       const customSalesInvoiceData = {
  //         salesorder_invoice_id: id,
  //         custom_fields: customFieldsWithDefaultValues,
  //       };
  //       observer = this.adminService.editSalesOrderinvoiceCustomFields(
  //         customSalesInvoiceData
  //       );
  //       break;
  //     }
  //     case CustomFieldType.QUOTATION: 
  //     case CustomFieldType.BOQ_WORKORDER:
  //     case CustomFieldType.SUBCON_WORKORDER: {
  //       const customData = {
  //         workorder_id: id,
  //         custom_fields: customFieldsWithDefaultValues,
  //       };
  //       observer = this.adminService.editWorkOrderCustomFields(customData);
  //       break;
  //     }

  //     default:
  //       break;
  //   }

  //   observer.subscribe({
  //     next: (res) => {
  //       console.log(res);
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     },
  //   });
  // }

  clearDate(customField: CustomField) {
    customField.default_value = "";
  }
}
