import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../../config/config.config';
import { PayrollType } from 'src/app/enum/enum';
import { Payroll } from 'src/app/interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompanyAttendanceService {

  private selectedPayrollSource = new BehaviorSubject<Payroll>(null);
  selectedPayroll$ = this.selectedPayrollSource.asObservable();
  constructor(
    private http: HttpClient,
    private config: Config
  ) { }

  // Set the selected Payroll
  setSelectedPayroll(obj: Payroll) {
    this.selectedPayrollSource.next(obj);
  }

  // Get the current selected Payroll
  getSelectedPayroll() {
    return this.selectedPayrollSource.getValue();
  }


  detailCompanyAttendanceInfo(id:string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyattendanceinfo/${id}`,
      { }
    );
  }

  setCompanyAttendanceLevelTab(tab:PayrollType){
    sessionStorage.setItem('companyAttendanceLevelTab', tab);
  }

  getCompanyAttendanceLevelTab():PayrollType{
    const tab = sessionStorage.getItem('companyAttendanceLevelTab') as PayrollType;
    if(tab){
      return tab
    }
    return PayrollType.labour;
  }

  setCompanyAttendanceLevelListType(status:number){
    sessionStorage.setItem('companyAttendanceLevelListType', status.toString());
  }

  getCompanyAttendanceLevelListType():number{
    const status = sessionStorage.getItem('companyAttendanceLevelListType') as '0'|'1';
    if(status){
      return parseInt(status)
    }
    return 0
  }

}
