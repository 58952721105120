// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  socketAddress: 'https://testapi.onsiteteams.in',
  rozarPayKey: 'rzp_test_Rg4BPEXaLx6JyP',
  firebaseConfig: {
    apiKey: "AIzaSyBw46UlxuO42WCbMWzN2GTx1JRj_tg5sgY",
    authDomain: "onsite-8a261.firebaseapp.com",
    projectId: "onsite-8a261",
    storageBucket: "onsite-8a261.appspot.com",
    messagingSenderId: "535156370173",
    appId: "1:535156370173:web:b82b4007aad1e1dad8ec94"
  },
  chatSocketServer: 'https://testchatsocket.onsiteteams.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
