import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-caret',
  templateUrl: './caret.component.html',
  styleUrls: ['./caret.component.css']
})
export class CaretComponent {
  @Input() showCaret:boolean;
  @Input() caretColor:string;

}
