import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Config } from 'src/app/config/config.config';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(
    private http: HttpClient,
    private config: Config
  ) {
    //
  }

  generateColors( labels?: string[], label?: string,): Observable<string | string[]> {
    const colors: string[] = [];

    // Color generation logic using string hash
    function stringToColor(str: string): string {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const hexColor = ((hash & 0x00FFFFFF) | 0x1000000).toString(16).slice(1);
      return `#${hexColor}`;
    }

    labels.forEach((l) => {
      colors.push(stringToColor(l));
    });

    // Return a single color if a specific label is provided
    if (label) {
      const index = labels.indexOf(label);
      return of(index !== -1 ? colors[index] : '');
    }

    // Return the array of colors if no specific label is provided
    return of(colors);
  }

  getFinancialHealth(params:HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/chart/financialhealth`,
      { params }
    );
  }

  getChartBills(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/chart/bills`, {params})
  }

  getExpenseData(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/chart/expense/subcategory-wise`, {params})
  }

  getAttendanceData(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/chart/weekly/attendace`, {params})
  }

  getChartExpenseFeatureWise(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/chart/expense/feature-wise`, {params})
  }


}
