import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ForReductionDeduction } from 'src/app/enum/enum';
import { DeductionItem, RetentionItem } from 'src/app/interfaces/interfaces';

interface ComponentData{
  from:ForReductionDeduction,
  retentionItem?:RetentionItem,
  deductionItem?:DeductionItem,
}
@Component({
  selector: 'app-create-new-retention',
  templateUrl: './create-new-retention-deduction.component.html',
  styleUrls: ['./create-new-retention-deduction.component.css']
})
export class CreateNewRetentionDeductionComponent implements OnInit{
  public componentData: ComponentData;
  retentionForm: FormGroup;
  private destroy$ = new Subject<void>();
  forReductionDeduction = ForReductionDeduction;
  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas
  ) {}

  ngOnInit():void{
    this.initForm();

    if(this.componentData.deductionItem && this.componentData.deductionItem.id){
      this.retentionForm.patchValue({
        name: this.componentData.deductionItem.name
      });
    }

    if(this.componentData.retentionItem && this.componentData.retentionItem.id){
      this.retentionForm.patchValue({
        name: this.componentData.retentionItem.name
      });
    }
  }

  initForm(): void {
    this.retentionForm = this.fb.group({
      name: ['', Validators.required],
    });
  }


  onSubmit(): void {
    if (this.retentionForm.valid) {
      if(this.componentData.from === ForReductionDeduction.RETENTION){
        if(this.componentData.retentionItem && this.componentData.retentionItem.id){
          this.editRetention();
        }else{
          this.createNewRetention();
        }
    } else {
        if(this.componentData.deductionItem && this.componentData.deductionItem.id){
          this.editNewDeduction();
        }else{
          this.createNewDeduction();
      }
    }
      this.markFormGroupTouched(this.retentionForm);
    }
  }

  // Helper method to mark all controls as touched
  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
 
  createNewRetention(): void {
      const retentionFormInput = {
        name: this.retentionForm.value.name,
        company_id: this.authService.session.company.id,
      };
      this.adminService
        .addRetentionItem(retentionFormInput)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next:(res: RetentionItem) => {
            this.ngbActiveOffcanvas.close(res);
          },
          error:(err) => {
            console.error(err);
          },
          complete: () => {
            this.retentionForm.reset();
          }
        });
    }

    editRetention(): void {
      const retentionFormInput = {
        name: this.retentionForm.value.name,
        company_id: this.authService.session.company.id,
        id: this.componentData.retentionItem.id
      };
      this.adminService
        .editRetentionItem(retentionFormInput)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next:(res: RetentionItem) => {
            this.ngbActiveOffcanvas.close(res);
          },
          error:(err) => {
            console.error(err);
          },
          complete: () => {
            this.retentionForm.reset();
          }
        });
    }

    createNewDeduction(): void {
      const deductionFormInput = {
        name: this.retentionForm.value.name,
        company_id: this.authService.session.company.id,
      };
      this.adminService
        .createDeduction(deductionFormInput)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next:(res: RetentionItem) => {
            this.ngbActiveOffcanvas.close(res);
          },
          error:(err) => {
            console.error(err);
          },
          complete: () => {
            this.retentionForm.reset();
          }
        });
    }


    editNewDeduction(): void {
      const deductionFormInput = {
        name: this.retentionForm.value.name,
        company_id: this.authService.session.company.id,
        id: this.componentData.deductionItem.id
      };
      this.adminService
        .editDeductionItem(deductionFormInput)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next:(res: RetentionItem) => {
            this.ngbActiveOffcanvas.close(res);
          },
          error:(err) => {
            console.error(err);
          },
          complete: () => {
            this.retentionForm.reset();
          }
        });
    }
}
