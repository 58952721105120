<div class="row align-items-center">
  <div class="col-6">
    <a routerLink="/{{examType}}"><img src="../../../assets/images/logo-b.png" class="mobile__logo" /></a>
  </div>
  <div class="col-6 text-right">
    <a *ngIf="!authService.session.isActive" class="header__live" routerLink="{{livebtn.link}}">
      <div class="icon liveTest">
        <div class="liveTest__ring"></div>
        <div class="liveTest__circle"></div>
      </div>
      <div class="liveTest__text">{{livebtn.text}}</div>
    </a>
    <span class="cursor" (click)="openNav()"><img src="../../../../assets/images/menu-white.svg" class="header__menu"></span>
  </div>
</div>
<div id="myNav" class="mobile__overlay">
  <a [hidden]="authService.session.isActive" routerLink="/"><img src="../../../../assets/images/logo-b.png" style="border-radius: 50%" class="mobile__overlay--logo" /></a>
  <a *ngIf="authService.session.isActive" routerLink="/"><img src="{{authService.session.profile.profile_pic}}" onError="this.src='../../../assets/images/profile.jpg';" style="border-radius: 50%; width: 100px;height: 100px" class="mobile__overlay--logo" /></a>
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <div class="mobile__overlay--content">
    <div *ngIf="!authService.session.isActive">
      <a routerLink="/auth"><span class="icon icon-download"></span> Login</a>
    </div>
    <div *ngIf="authService.session.isActive">
      <a routerLink="/profile">
        <span class="icon icon-home"></span> Dashboard
      </a>
    </div>
    <a *ngFor="let item of mobileMenus" routerLink="{{item.link}}"><span class="icon {{item.icon}}"></span> {{item.text}}</a>
    <div *ngIf="authService.session.isActive">
      <a routerLink="/profile/edit"><span class="icon icon-settings"></span> Settings</a>
      <button *ngIf="authService.session.isActive" (click)="logout()"><span class="icon icon-download"></span> Logout</button>
    </div>
  </div>
</div>
