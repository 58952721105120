<div *ngIf="parent.display" (click)="parent={}; disableScroll(false)" class="photo-slider mw-100"
  style="z-index: 9999;">
  <div class="d-flex align-items-center flex-column gap-4">
    <div style="height: 90vh; padding-top: 2vh;">
      <!-- for Mobile -->
      <img class="d-lg-none" style="height: auto; width: 100%;" [src]="parent.urls[currentIndex]">
      <!-- for desktop -->
      <img class="d-none d-md-block d-lg-block" style="height: 100%; width: auto;" [src]="parent.urls[currentIndex]">
    </div>
    <div class="d-flex justify-content-center bg-black w-100 p-1 gap-3" style="color: #fff;">
      <div><i class="pointer p-2 bi bi-chevron-left arrow" (click)="slideOption($event, 'prev')"></i></div>
      <div><i class="pointer p-2 bi bi-chevron-down arrow" (click)="slideOption($event, 'down')"></i></div>
      <div><i class="pointer p-2 bi bi-chevron-right arrow" (click)="slideOption($event, 'next')"></i></div>

    </div>
  </div>
</div>
