<!-- <div class="project-navabar">
  <div class="row p-0 m-0 w-100 " style="box-sizing: border-box">
    <div style="position: relative;"
      class="col-12 col-md-3 d-flex justify-content-start align-items-center p-0 w-100 gap-1">
      <div class="d-flex align-items-center gap-1">
        <div class="d-lg-none" style="margin-right: 8px;" (click)="openSmallSidebar()">
          <img src="../../../../assets/svg/burgerMenu.svg" alt="menu">
        </div>
        <div class="p-0 m-0 d-none d-sm-block">
          <div style="height: 52px; width: 52px;" class="flex-shrink-1 project_logo me-2 mb-2"
            [ngbTooltip]="authService.session.project?.name" [disableTooltip]="isLargeScreen">
            {{authService.session.project?.name | shortName | uppercase}}
          </div>
        </div>
      </div>
      <div style="overflow-y: auto; overflow-x: hidden;" class="w-100">
        <span class="f-14  p-0 m-0">{{authService.session.project?.name | truncate:'50'}}</span>

        <div class="container-fluid p-0 mx-0 mt-1 " style="position:relative;">
          <div *ngIf="fromModule === 'project'" class="row m-0 p-0" style="padding-inline: 16px">
            <div class="col-12 d-flex justify-content-start p-0 scrollable">
              <div class="tabs_container" style="margin-block:1px; padding:0px !important">
                <div class="tab_container_content d-flex gap-4">
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="overview"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_dashboard) && authService.hasAccess(authService.companyUserPolicy.Project_Overview)">
                    Overview
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="party-list"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_party_balance)">
                    Party
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="task"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_all_task, authService.companyUserPolicy.View_my_task)">
                    Task
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="payment"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_all_transactions, authService.companyUserPolicy.View_my_transactions)">
                    Transaction
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="attendance"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_attendance)">
                    Attendance
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="material"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_material)">
                    Material
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="equipment"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_Equipment) && company?.is_equipment_enabled">
                    Equipment
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="sub-contractor"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_Subcon) && company?.is_subcon_enabled">
                    Sub Con
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="photos"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_photos)">
                    Files
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active" routerLink="boq"
                    >
                    Estimate
                    <div class="active_tab_line"></div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="fromModule === 'warehouse'" class="row m-0 p-0" style="padding-inline: 16px">
            <div class="col-12 d-flex justify-content-start p-0"
              style=" overflow-y: hidden; overflow-x: auto; scroll-behavior: smooth; overscroll-behavior: contain;">
              <div class="tabs_container" style="margin-block:1px; padding:0px !important">
                <div class="tab_container_content d-flex gap-4">
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active"
                    routerLink="warehouse-inventory"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_dashboard)">
                    Inventory
                    <div class="active_tab_line"></div>
                  </span>
                  <span class="cursor single_tab f-14 text-muted" routerLinkActive="active"
                    routerLink="warehouse-transaction"
                    *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_party_balance)">
                    Transaction
                    <div class="active_tab_line"></div>
                  </span>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class=" d-flex justify-content-end align-items-center p-0  option-wrapper" style="gap:1rem">
        <div class="avatars" (click)="openSettings('member')">
          <ng-container *ngFor="let item of member; let i = index">
            <ng-container *ngIf="i < 3 && (item.role == 'manager' || item.role == 'admin')">
              <ng-template #i>{{
                item.monkey_patch_company_user?.name
                }}</ng-template>
              <a *ngIf="item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
                <img [ngClass]="
                      item.role == 'manager' ? 'avatar-manager' : 'avatar-admin'
                    " class="avatar" [src]="item.monkey_patch_company_user.profile_pic" />
              </a>
              <a *ngIf="!item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
                <div [ngClass]="
                      item.role == 'manager' ? 'avatar-manager' : 'avatar-admin'
                    " class="avatar">
                  {{ item.monkey_patch_company_user?.name.charAt(0) }}
                </div>
              </a>
            </ng-container>
          </ng-container>
          <a class="ms-1 text-decoration-none" *ngIf="memberCount > 4">
            <div class="avatar avatar-last">+{{ memberCount - 3 }}</div>
          </a>
          <a class="ms-2 text-decoration-none">
            <ng-template #add>Manage Access</ng-template>
            <div class="avatar avatar-add" [ngbTooltip]="add">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3335 17.5V15.8333C13.3335 14.9493 12.9823 14.1014 12.3572 13.4763C11.7321 12.8512 10.8842 12.5 10.0002 12.5H4.16683C3.28277 12.5 2.43493 12.8512 1.80981 13.4763C1.18469 14.1014 0.833496 14.9493 0.833496 15.8333V17.5"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428 10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333 2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428 5.24238 9.16667 7.08333 9.16667Z"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.6665 6.66797V11.668" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.1665 9.16797H14.1665" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </a>
        </div>
        <button style="background-color: transparent; border: none; padding: 0px;"
          (click)="openSettings(fromModule == 'warehouse' ? 'warehouseDetails':'projectDetails')">
          <div>
            <ng-icon style="transform: scale(1.1);" class="disabled-font" name="featherSettings"></ng-icon>
          </div>
        </button>
        <button [routerLink]="'/../c/'+authService.session.company.id+'/d/notification'" routerLinkActive="active"
          style="background-color: transparent; border: none; padding: 0px;">
          <div>
            <ng-icon style="transform: scale(1.1);" class="disabled-font" name="featherBell"></ng-icon>
          </div>
        </button>
        <app-user></app-user>



      </div>


    </div>
  </div>
</div> -->

<div class="project-header">
  <div class="d-flex align-items-center gap-1">
    <div class="d-lg-none" style="margin-right: 8px;" (click)="openSmallSidebar()">
      <img src="../../../../assets/svg/burgerMenu.svg" alt="menu">
    </div>
    <div class="p-0 m-0 d-none d-sm-block">
      <div style="height: 48px; width: 48px;" class="flex-shrink-1 project_logo me-2 mb-2"
        [ngbTooltip]="authService.session.project?.name" [disableTooltip]="isLargeScreen">
        {{authService.session.project?.name | shortName | uppercase}}
      </div>
    </div>
  </div>
  <div
    [ngClass]="{'open-width':windowWidth >= 992 , 'close-width':windowWidth <= 992 && windowWidth >= 576, 'mobile-width':windowWidth <= 576}"
    class="d-flex flex-column ">
    <div style="padding-right: 18px;" class=" d-flex justify-content-between align-items-start gap-4">
      <div class="name-wrapper">
        <span class="f-14">
          {{authService.session.project?.name }}
        </span>
      </div>

      <div class=" d-flex justify-content-end align-items-center p-0 " style="gap:1rem">
        <div class="status-dropdown" ngbDropdown *ngIf="fromModule != 'warehouse'">
          <button class="btn btn-sm btn-custom-outline f-12 d-flex align-items-center gap-2" ngbDropdownToggle>
            <div class="circle-dot {{status.class}}"></div>
            <span>{{status.text}}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <div class="d-flex flex-column align-items-center">
              <button (click)="changeProjectStatus(project.id, 'Ongoing')" class="dropdown-item btn f-12" ngbDropdownItem>
                <div class="circle-dot ongoing-btn"></div>
                Ongoing
              </button>
              <button (click)="changeProjectStatus(project.id, 'OnHold')" class="dropdown-item btn f-12" ngbDropdownItem>
                <div class="circle-dot onhold-btn"></div>On Hold
              </button>
              <button (click)="changeProjectStatus(project.id, 'NotStarted')" class="dropdown-item btn f-12" ngbDropdownItem>
                <div class="circle-dot not-started-btn"></div>Not Started
              </button>
              <hr style="width: 90%;" class="w-80 my-2">
              <button (click)="changeProjectStatus(project.id, 'Complete')" class="dropdown-item completed-btn btn f-12 d-flex gap-2 " ngbDropdownItem>
                <ng-icon name="featherCheckCircle" class="d-flex align-items-center "></ng-icon>
                Completed</button>
            </div>
          </div>
        </div>
        <div class="avatars" (click)="openSettings('member')">
          <ng-container *ngFor="let item of member; let i = index">
            <ng-container *ngIf="i < 2 && (item.role == 'manager' || item.role == 'admin')">
              <ng-template #i>{{
                item.monkey_patch_company_user?.name
                }}</ng-template>
              <a *ngIf="item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
                <img [ngClass]="
                      item.role == 'manager' ? 'avatar-manager' : 'avatar-admin'
                    " class="avatar" [src]="item.monkey_patch_company_user.profile_pic" />
              </a>
              <a *ngIf="!item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
                <div [ngClass]="
                      item.role == 'manager' ? 'avatar-manager' : 'avatar-admin'
                    " class="avatar">
                  {{ item.monkey_patch_company_user?.name.charAt(0) }}
                </div>
              </a>
            </ng-container>
          </ng-container>
          <a class="ms-2 text-decoration-none">
            <ng-template #add>Manage Access</ng-template>
            <div class="avatar avatar-add" [ngbTooltip]="add">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3335 17.5V15.8333C13.3335 14.9493 12.9823 14.1014 12.3572 13.4763C11.7321 12.8512 10.8842 12.5 10.0002 12.5H4.16683C3.28277 12.5 2.43493 12.8512 1.80981 13.4763C1.18469 14.1014 0.833496 14.9493 0.833496 15.8333V17.5"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428 10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333 2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428 5.24238 9.16667 7.08333 9.16667Z"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.6665 6.66797V11.668" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.1665 9.16797H14.1665" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </a>
        </div>
        <button style="background-color: transparent; border: none; padding: 0px;"
          (click)="openSettings(fromModule == 'warehouse' ? 'warehouseDetails':'projectDetails')">
          <div>
            <ng-icon style="transform: scale(1.1);" class="disabled-font" name="featherSettings"></ng-icon>
          </div>
        </button>
        <button [routerLink]="'/../c/'+authService.session.company.id+'/d/notification'" routerLinkActive="active"
          style="background-color: transparent; border: none; padding: 0px;">
          <div>
            <ng-icon style="transform: scale(1.1);" class="disabled-font" name="featherBell"></ng-icon>
          </div>
        </button>
        <app-user></app-user>
      </div>
    </div>

    <div *ngIf="fromModule === 'project'" class="menu-wrapper ">
      <button
        *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_dashboard) && authService.hasAccess(authService.companyUserPolicy.Project_Overview)"
        type="button" routerLink="overview" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Overview
        <div class="active_tab_line"></div>
      </button>

      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_party_balance)" type="button"
        routerLink="party-list" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Party
        <div class="active_tab_line"></div>
      </button>
      <button
        *ngIf="authService.hasAccess(authService.companyUserPolicy.View_all_task, authService.companyUserPolicy.View_my_task)"
        type="button" routerLink="task" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Task
        <div class="active_tab_line"></div>
      </button>
      <button
        *ngIf="authService.hasAccess(authService.companyUserPolicy.View_all_transactions, authService.companyUserPolicy.View_my_transactions)"
        type="button" routerLink="payment" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Transaction
        <div class="active_tab_line"></div>
      </button>
      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_attendance)" type="button"
        routerLink="attendance" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Attendance
        <div class="active_tab_line"></div>
      </button>
      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_material)" type="button"
        routerLink="material" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Material
        <div class="active_tab_line"></div>
      </button>
      <button
        *ngIf="authService.hasAccess(authService.companyUserPolicy.View_Equipment) && company?.is_equipment_enabled"
        type="button" routerLink="equipment" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Equipment
        <div class="active_tab_line"></div>
      </button>
      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_Subcon) && company?.is_subcon_enabled"
        type="button" routerLink="sub-contractor" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Sub Con
        <div class="active_tab_line"></div>
      </button>
      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_photos)" type="button" routerLink="photos"
        routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Files
        <div class="active_tab_line"></div>
      </button>
      <button type="button" routerLink="boq" *ngIf="authService.hasAccess(authService.companyUserPolicy.view_my_boq) || authService.hasAccess(authService.companyUserPolicy.view_all_boq)"
        routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Estimate
        <div class="active_tab_line"></div>
      </button>
    </div>

    <div class="menu-wrapper" *ngIf="fromModule === 'warehouse'">
      <button type="button" routerLinkActive="active" routerLink="warehouse-inventory"
        *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_dashboard)"
        class="btn menu-btn f-12 px-0">
        Inventory
        <div class="active_tab_line"></div>
      </button>

      <button *ngIf="authService.hasAccess(authService.companyUserPolicy.View_project_party_balance)" type="button"
        routerLink="warehouse-transaction" routerLinkActive="active" class="btn menu-btn f-12 px-0">
        Transaction
        <div class="active_tab_line"></div>
      </button>
    </div>
  </div>
</div>