import { Injectable } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { Anon, Profile, Token } from '../interfaces/interfaces';
import { Router } from '@angular/router';
import { AsyncSubject, BehaviorSubject, Subject } from 'rxjs';
import { Socket, io } from "socket.io-client";
import { FirebaseMessagingService } from '../shared/services/firebase-messaging.service';
@Injectable()
export class LoginService {

  public anonymousUser = {} as Anon;

  constructor(
    private authService: AuthService,
    private router: Router,
    private firbaseMessagingService: FirebaseMessagingService
  ) { }

  setSessionValue(data) {
    const token: Token = {
      token: data.token,
      expire: data.expire,
    } as Token;
    this.authService.saveToken(token);
    this.authService.session.token = token;
    this.authService.session.isActive = true;
    this.authService.session.profile = data.user as Profile;
    this.authService.saveProfile(this.authService.session.profile);
    this.firbaseMessagingService.requestPermission(this.authService.session.profile.web_fcm_token, this.authService.session.profile.id);
    this.firbaseMessagingService.receiveMessage();
    this.authService.sendGTMEvent('Login');
  }

  navigateToCompany() {
    let companyId = this.authService.session.profile.current_company_id;
    if (!companyId) {
      if (this.authService.session.profile.monkey_patch_company_user?.length > 0) {
        companyId = this.authService.session.profile.monkey_patch_company_user[0].company_id;
      }else{
        alert('Company not found')
        //start new journey for create new company
        //to do
      }
    }
    if (companyId) {
      console.log('Welcome to Onsite! Your company id is', companyId);
      this.router.navigate([`/c/${companyId}/d/home`]);
    } else {
      console.log('Company Id not found')
    }
  }

}



@Injectable({
  providedIn: 'root' ,
})
export class SocketLoginService {

  public socket: Socket;
  constructor() {
    //
  }
  public connectSocket(){
    this.socket = io('https://socket.onsiteteams.com')
  }

  public sendMessage(message: any) {
    this.socket.emit('message', message);
  }

  public closeSocket(){
    this.socket.close()
  }
}