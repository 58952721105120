import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Observer} from 'rxjs';
import {AuthService} from './auth.service';
import { LoginService } from 'src/app/login/login.service';
import { AdminService } from './admin.service';
import {map, catchError } from 'rxjs/operators';
import { Company } from 'src/app/interfaces/interfaces';

declare var Tawk_API: any;

function loadScript(src): Observable<any> {
  return new Observable<any>((observer: Observer<any>) => {
    // Load the script
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = src;
    scriptElement.onload = () => {
      observer.next(true);
      observer.complete();
    };
    scriptElement.onerror = (error: any) => {
      observer.error('Couldnt load script ' + src);
    };
    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  });
}
function loadTawkToScript(src): Observable<any> {
  return new Observable<any>((observer: Observer<any>) => {
    // Load the script
    // var Tawk_API = Tawk_API || {},
    //   Tawk_LoadStart = new Date();
    // (function () {
    //   var s1 = document.createElement("script"),
    //     s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5c8a278cc37db86fcfcdc717/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = src;
    scriptElement.async = true;
    scriptElement.charset = 'UTF-8';
    scriptElement.setAttribute('crossorigin', '*');
    scriptElement.onload = () => {
      setTimeout(() => {
        const Tawk_LoadStart = new Date();
        try {
          Tawk_API.showWidget();
        } catch (e) {}
      }, 1000);
      observer.next(true);
      observer.complete();
    };
    scriptElement.onerror = (error: any) => {
      observer.error('Couldnt load script ' + src);
    };
    document.getElementsByTagName('script')[0].appendChild(scriptElement);
  });
}
@Injectable()
export class TawkToResolverService  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadTawkToScript('https://embed.tawk.to/63053b2137898912e964bd1a/1gb66tvpg');
  }
}
@Injectable()
export class RazorpayResolverService  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }
}

@Injectable()
export class JQueryResolverService  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('assets/js/jquery.min.js');
  }
}
@Injectable()
export class DownloadJSResolverService  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('assets/js/download.js');
  }
}

@Injectable()
export class PDFJSResolverService  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.min.js');
  }
}
@Injectable()
export class ProfileResolverService   {
  constructor(
    private authService: AuthService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const username = route.paramMap.get('username');
    return this.authService.getUserByUsername(username);
  }
}

@Injectable()
export class ImageResolverService   {
  constructor(
    private authService: AuthService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.authService.refreshToken(); // any thing
  }
}
@Injectable(
  {providedIn: 'root'}
)
export class ZohoChat  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('assets/js/zoho-chat.js');
  }
}

@Injectable(
  {providedIn: 'root'}
)
export class InputScroll  {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('assets/js/input-scroll-off.js');
  }
}

@Injectable()
export class LoginStatus {
  constructor(
    private authService:AuthService,
    private router:Router,
    private loginService: LoginService
    ){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any> {

    if (!this.authService.session.isActive) {
    //  this.router.navigate(["/company/dashboard/home"]);
     return

    }else{
      this.loginService.navigateToCompany();
      // this.router.navigate(["/company/dashboard/home"]);

    }

  }
}

@Injectable()
export class CompanyResolverService   {
  constructor(
    private adminService: AdminService,
    private authService:AuthService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company>|Promise<Company>|Company {
    const a = this.adminService.getCompanyDetails(route.params.id);
    return a
  }
}
