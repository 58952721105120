import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveOffcanvas, NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { AdminService } from "src/app/core/services/admin.service";
import {
  Address,
  City,
  Company,
  CompanyUser,
  Country,
  CustomField,
} from "src/app/interfaces/interfaces";
import { AuthService } from "src/app/core/services/auth.service";
import { ValidatorService } from "src/app/shared/services/validator.service";
import {
  NotificationsService,
  toastTypes,
} from "src/app/core/services/notifications.service";
import { LocalStorageService } from "src/app/shared/services/local-storage-service.service";
import { PartyGstComponent } from "../party-gst/party-gst.component";
import { PartyOpeningBalanceComponent } from "../party-opening-balance/party-opening-balance.component";
import { CompanyPartyType } from "src/app/enum/enum";
import { PartyBankComponent } from "src/app/company-parties/components/party-bank/party-bank.component";
import { CompanyService } from "src/app/company/services/company.service";
import { HttpParams } from "@angular/common/http";
import { part } from "core-js/core/function";
import { PartyCustomFieldsListComponent } from "./party-custom-fields-list/party-custom-fields-list.component";
import { AddAddressComponent } from "src/app/shared/components/add-address/add-address.component";
import { ConfirmModalComponent } from "src/app/shared/components/modal/confirm-modal/confirm-modal.component";
import { PartyPrefixSequenceComponent } from "./party-prefix-sequence/party-prefix-sequence.component";

interface RequiredFields {
  name?: boolean;
  mobile?: boolean;
  city?: boolean;
  type?: 'customer'| 'employee'| 'labour'|'Material Supplier'| 'Labour Contractor'| 'Equipment Supplier'| 'Sub Contractor'|'any'|'investor';
}
interface Parent {
  companyUser?: CompanyUser;
  requiredFields?: RequiredFields;
  from?: string;
  defaultValue?: string;
}

interface UserBank {
  id?: string;
  company_user_id?: string;
  account_name?: string;
  account_number?: string;
  bank_code?: string;
  upi_ids?: string[];
  bank_name?:string[];
  bank_address?:string[];
  iban_number?:string[];
}
@Component({
  selector: "app-add-party-library",
  templateUrl: "./add-party-library.component.html",
  styleUrls: ["./add-party-library.component.css"],
})
export class AddPartyLibraryComponent implements OnInit {
  @Input() parent = {} as Parent;

  enum: typeof CompanyPartyType = CompanyPartyType;

  public bankDetails = {} as UserBank;
  public cities = [] as City[];
  public formInputError = "";
  public companyUser = {} as CompanyUser;
  public searchBox = "";
  public country = {} as Country;
  public countries = [] as Country[];
  private company = {} as Company;
  public selectingCountry = false;
  public selectingAddress = false;
  public partyAddress = [] as Address[];
  public loading = false;
  public customFieldLoader = false;
  public customFields = [] as CustomField[];
  public customFieldTypes = '';
  public valueStack = {
    page: { next_url: "" },
  };
  public busyAddress = false;
  public partyTypesClient = [
    {name:"Client", value:"customer", is_vendor:false},
    {name:"Staff", value:"employee", is_vendor:false},
    {name:"Labour", value:"labour", is_vendor:false},
    {name:"Investor", value:"investor", is_vendor:false},
    {name:"Labour Contractor", value:"labour_contractor", is_vendor:true},
    {name:"Material Supplier", value:"material_supplier", is_vendor:true},
    {name:"Equipment Supplier", value:"equipment_supplier", is_vendor:true},
    {name:"Subcontractor", value:"subcontractor", is_vendor:true},
  ]
  public selectedPartyType:string;
  constructor(
    public ngbActiveOffcanvas: NgbActiveOffcanvas,
    private companyService: CompanyService,
    private ngbOffcanvas: NgbOffcanvas,
    private adminService: AdminService,
    public authService: AuthService,
    private validatorService: ValidatorService,
    private notificationServices: NotificationsService,
    private localStorageService: LocalStorageService,
    private ngbModal:NgbModal
  ) {
    this.company = this.authService.session.company;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.parent.companyUser?.id) {
      this.getCompanyUserDetails(this.parent.companyUser?.id);
      this.getListOfBankDetails();
      this.listPartyAddress();
    } else {
      this.companyUser.name = this.parent.defaultValue || '';
      this.companyUser.type =  this.parent.requiredFields.type == 'any' ? "customer" : this.parent.requiredFields.type;
      this.getCountryList();
    }

    if (this.parent.from === "task" || this.parent.from === 'project-setting' || this.parent.from === 'warehouse-setting') {
      this.selectedPartyType= 'Staff';
      this.companyUser.type = "employee";
    }

    if (this.parent.from === 'create-subcon') {
      this.selectedPartyType= 'Subcontractor';
      this.companyUser.type = "subcontractor";
    }

    if(this.parent.from === 'materialPurchase' || this.parent.from === 'purchase-order'){
      this.companyUser.type = 'material_supplier';
      this.selectedPartyType = 'Material Supplier';
    }

    if(this.parent.from === 'deposit' || this.parent.from === 'sales-invoice' || this.parent.from === 'AddQuotationComponent'){
      this.companyUser.type = 'customer';
      this.selectedPartyType = 'Client'
    }

    if(this.parent.from === 'attendance'){
      this.companyUser.type = 'labour_contractor';
      this.selectedPartyType = 'Labour Contractor';
    }

    if(this.parent.from === 'equipment'){
      this.companyUser.type = 'equipment_supplier';
      this.selectedPartyType = 'Equipment Supplier'
    }

    // if(this.parent.from === 'project-setting'){
    //   this.companyUser.type = 'labour_contractor';
    //   this.selectedPartyType = 'Labour Contractor';
    // }
  }
  getCompanyUserDetails(id){
    this.adminService.getCompanyParty(id).subscribe({
      next: (res) => {
        this.companyUser = res;
        // this.customFields = res.custom_fields || [] as CustomField[];
        switch (res.type) {
          case 'customer':
            this.getCustomFieldList('client_party');
            break;
          case 'employee':
            this.getCustomFieldList('staff_party');
            break;
          case 'labour':
            this.getCustomFieldList('labour_party');
            break;
          default:
           this.getCustomFieldList('vendor_party');
        }
        this.mobileString = this.companyUser.mobile.toString();
        this.getCountryList();
        this.selectedPartyType = this.partyTypesClient.find(i=>i.value==this.companyUser.type).name
        
      },
      error: (err)=>{
        this.loading = false;
      }
    })
  }
  getCountryList() {
    this.authService.getCountryList().subscribe({
      next: (res) => {
        this.loading = false;
        this.countries = res || [];
        for (const item of this.countries) {
          if(this.parent?.companyUser?.id && item.country_code == this.companyUser?.monkey_patch_user?.country_code){
              this.country = item;
              break;
          }else{
            if(item.country_iso == this.authService.session.company.country_iso){
              this.country = item;
              break;
            }
          }
        }
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
      },
    });
  }

  getCities() {
    this.authService
      .getCachedJSONByUrl("assets/data/cityState.json")
      .subscribe((res) => {
        let result = [];
        res.states.map((item) => {
          result = result.concat(item.cities);
        });
        sessionStorage.setItem("cities", JSON.stringify(result));
      });
  }

  searchCity() {
    this.cities = JSON.parse(sessionStorage.getItem("cities"));
    let len = 0;
    this.companyUser.city ? (len = this.companyUser.city.length) : 0;
    if (len > 0) {
      this.searchBox = "citySearch";
      const pattern = new RegExp(this.companyUser.city, "i");
      this.cities = this.cities.filter((f) => f.name.match(pattern));
    }
    if (len === 0) {
      this.cities = JSON.parse(sessionStorage.getItem("cities"));
    }
  }

  saveBtn() {
    this.companyUser.name = this.companyUser.name?.trim();

    if (this.formInputError != "") {
      return this.notificationServices.initiate({
        title: "Enter a valid Number!",
        type: toastTypes.error,
      });
    }
    if (!this.companyUser.name) {
      return this.notificationServices.initiate({
        title: "Party Name Required",
        type: toastTypes.error,
      });
    }

    //Parent required fields
    if (this.parent?.requiredFields?.mobile && !this.companyUser.mobile) {
      return this.notificationServices.initiate({
        title: "Mobile Number Required",
        type: toastTypes.error,
      });
    }

    if (this.companyUser.mobile) {
      if (
        this.companyUser.mobile.toString().length <
          this.country.minimum_digits.toString().length ||
        this.companyUser.mobile.toString().length >
          this.country.maximum_digits.toString().length
      ) {
        return (this.formInputError = "mobile");
      }else{
        this.companyUser.country_code = this.country.country_code;
      }
    }

    if (this.parent.requiredFields) {
      // if (this.parent.requiredFields?.type != "any") {
      //   // if (this.companyUser.type != this.parent.requiredFields?.type) {
      //   //   return this.notificationServices.initiate({
      //   //     title: `Party type should be ${this.parent.requiredFields?.type}`,
      //   //     type: toastTypes.error,
      //   //   });
      //   // }
      // }
    }

    if (!this.selectedPartyType) {
      return this.notificationServices.initiate({
      title: `Party type is missing!`,
        type: toastTypes.warning,
      });
    }

    const company = this.authService.session.company as Company;
    if (this.companyUser.id) {
      if (company.creator == this.companyUser.user_id) {
        const tempSuperAdmin = {
          id: this.companyUser.id,
          name: this.companyUser.name,
          city: this.companyUser.city,
          aadhar_card_number: this.companyUser.aadhar_card_number,
          pan_card_number: this.companyUser.pan_card_number,
        };
        this.companyUser = {};
        this.companyUser = tempSuperAdmin;
      }
      this.editCompanyUser(this.companyUser);
    } else {
      this.companyUser.company_id = company.id;
      this.addCompanyUser(this.companyUser);
    }
  }

  addCompanyUser(cu: CompanyUser) {
    this.adminService.saveNewCompanyUser(cu).subscribe((res) => {
      this.ngbActiveOffcanvas.close(res);
      this.editCompanyUserCustomField(res.id);
      this.notificationServices.initiate({
        title: "Party Added",
        type: toastTypes.success,
      });
      if (res?.company_user_bank_account_ids.length > 0) {
        this.bankDetails.id = res?.company_user_bank_account_ids[0];
        if(this.bankDetails.account_number && this.bankDetails.bank_code) this.editCompanyUserbankAccount();
      } else {
        this.bankDetails.company_user_id = res?.id;
        if (this.bankDetails.account_name && this.bankDetails.account_number) this.addCompanyUserbankAccount();
      }
    });
  }

  editCompanyUser(cu: CompanyUser) {
    this.adminService.editCompnayUserList(cu).subscribe((res) => {
      this.editCompanyUserCustomField(res.id);
      this.ngbActiveOffcanvas.close(res);
    });
  }

  cancelBtn() {
    this.ngbActiveOffcanvas.dismiss();
  }

  setSelection(item, e) {
    e.stopPropagation();
    this.companyUser.type = item.value;
    this.searchBox = "";
  }
  mobileNumberValidate(): boolean {
    if (this.companyUser.mobile) {
      if (typeof this.companyUser.mobile != "number") return false;
    }
    return true;
  }

  trimString() {
    this.companyUser.name = this.validatorService.trimString(this.companyUser?.name);
  }

  mobileString = "";
  numberValidate(evt) {
    this.formInputError = "";
    const re = /[^0-9]+/;
    const updatedString = evt.target.value
      .replace(re, "")
      .substring(0, this.country.maximum_digits.toString().length)
      .trim();
    if (updatedString != evt.target.value) {
      if (updatedString.length > 0) {
        this.mobileString = updatedString;
        this.companyUser.mobile = parseInt(updatedString);
      } else {
        this.mobileString = null;
        this.companyUser.mobile = 0;
      }
    } else {
      this.companyUser.mobile = parseInt(updatedString);
    }
  }

  openGstDetails() {
    const ngbOffCanvasRef = this.ngbOffcanvas.open(PartyGstComponent, {
      ariaLabelledBy: "offcanvas-basic-title",
      position: "end",
      backdrop: "static",
      panelClass: "offcanvas-sm",
    });
    ngbOffCanvasRef.componentInstance.parent = {companyUser: this.parent.companyUser?.id?this.parent.companyUser:this.companyUser};
    ngbOffCanvasRef.result.then(
      (onFullfilled) => {
        this.companyUser.gstin = onFullfilled.gstin;
        this.companyUser.billing_address = onFullfilled.billing_address;
        this.companyUser.legal_business_name = onFullfilled.legal_business_name;
        this.companyUser.state_of_supply = onFullfilled.state_of_supply;
      },
      (onReject) => {
        //
      }
    );
  }

  openOpeningBalanceCanvas() {
    const ngbOffCanvasRef = this.ngbOffcanvas.open(
      PartyOpeningBalanceComponent,
      {
        ariaLabelledBy: "offcanvas-basic-title",
        position: "end",
        backdrop: "static",
        panelClass: "offcanvas-sm",
      }
    );

    ngbOffCanvasRef.componentInstance.parent = {
      companyUser: this.parent.companyUser,
    };

    ngbOffCanvasRef.result.then(
      (onFullfilled) => {
        if (this.parent.companyUser?.id) {
          this.companyUser.opening_balance = onFullfilled.opening_balance;
        } else {
          this.companyUser.opening_balance = onFullfilled;
        }
      },
      (onReject) => {
        //
      }
    );
  }

  openBankBalanceCanvas() {
    const ngbOffcanvasRef = this.ngbOffcanvas.open(PartyBankComponent, {
      ariaLabelledBy: "offcanvas-basic-title",
      position: "end",
      backdrop: "static",
      panelClass: "offcanvas-sm",
    });

    ngbOffcanvasRef.componentInstance.parent = {
      from: "add-party",
      hasRequestType: this.bankDetails.id ? "edit" : "add",
      hasCompanyBank: this.bankDetails,
    };

    ngbOffcanvasRef.result.then(
      (onFullfilled) => {
        if(onFullfilled.monkey_patch_bank_account){
          this.bankDetails.account_name = onFullfilled.monkey_patch_bank_account.account_name;
          this.bankDetails.account_number = onFullfilled.monkey_patch_bank_account.account_number;
          this.bankDetails.bank_code = onFullfilled.monkey_patch_bank_account.bank_code;
          this.bankDetails.bank_name = onFullfilled.monkey_patch_bank_account.bank_name;
          this.bankDetails.bank_address = onFullfilled.monkey_patch_bank_account.bank_address;
          this.bankDetails.iban_number = onFullfilled.monkey_patch_bank_account.iban_number;
          this.bankDetails.upi_ids = onFullfilled.monkey_patch_bank_account.upi_ids;
        }else{
          this.bankDetails.account_name = onFullfilled.account_name;
          this.bankDetails.account_number = onFullfilled.account_number;
          this.bankDetails.bank_code = onFullfilled.bank_code;
          this.bankDetails.bank_name = onFullfilled.bank_name;
          this.bankDetails.bank_address = onFullfilled.bank_address;
          this.bankDetails.iban_number = onFullfilled.iban_number;
          this.bankDetails.upi_ids = onFullfilled.upi_ids;
        }
        // this.onValidateIfsc(onFullfilled.monkey_patch_bank_account.ifsc_code);
      },
      (onReject) => {
        //
      }
    );
  }

  addCompanyUserbankAccount() {
    this.companyService.addCompanyUserbankAccount(this.bankDetails).subscribe({
      next: (res) => {
        // console.log("res", res);
      },
      error: (err) => {
        //
      },
    });
  }

  editCompanyUserbankAccount() {
    this.companyService.editCompanyUserbankAccount(this.bankDetails).subscribe({
      next: (res) => {
        // console.log("res", res);
      },
      error: (err) => {
        //
      },
    });
  }

  getListOfBankDetails(){
    let qp = new HttpParams();
    qp = qp.set('company_id',this.company.id);
    qp = qp.set('company_user_id', this.parent.companyUser.id);
    this.adminService.getCompanyUserBankAccount(qp).subscribe({
      next:res=>{
        if(res.data?.length){
          this.bankDetails.account_name = res.data[0].monkey_patch_bank_account?.account_name;
          this.bankDetails.account_number = res.data[0].monkey_patch_bank_account?.account_number;
          this.bankDetails.upi_ids = res.data[0].monkey_patch_bank_account?.upi_ids;
          this.bankDetails.bank_code = res.data[0].monkey_patch_bank_account?.bank_code;
          this.bankDetails.bank_name = res.data[0].monkey_patch_bank_account?.bank_name;
          this.bankDetails.bank_address = res.data[0].monkey_patch_bank_account?.bank_address;
          this.bankDetails.iban_number = res.data[0].monkey_patch_bank_account?.iban_number;
          this.bankDetails.id = res.data[0]?.id;
          this.bankDetails.company_user_id = res.data[0]?.company_user_id;
        }
      },
      error:err=>{
        //
      }
    })
  }

  setCountry(item: Country) {
    this.country = item || {};
    this.selectingCountry = false;
    this.mobileString = "";
  }

  // handleCheckboxChange():boolean{
  //   return
  // }

  setCompanyUserType(item){
    this.selectedPartyType = item.name;
    this.companyUser.type = item.value
    switch (item.value) {
      case 'customer':
        this.getCustomFieldList('client_party');
        break;
      case 'employee':
        this.getCustomFieldList('staff_party');
        break;
      case 'labour':
        this.getCustomFieldList('labour_party');
        break;
      default:
       this.getCustomFieldList('vendor_party');
    }
  }

  getCustomFieldList(partyType:string){
    this.customFieldLoader = true;
    this.customFieldTypes = partyType;
    let params = new HttpParams();
    params = params.append('company_id', this.authService.session.company.id);
    params = params.append('custom_field_type', partyType);
    if(this.parent.companyUser?.id){
      params = params.append('id', this.parent.companyUser.id);
    }
    const service = this.adminService;
    service.getCustomFieldList(params).subscribe({
      next:(res:{data:CustomField[]}) => {
        if(this.companyUser.id && this.companyUser.custom_fields?.length){
          this.companyUser.custom_fields.map(i=>i['is_used']=true);
          const mergedArray = [...this.companyUser.custom_fields, ...res.data].reduce((acc, current) => {
            // acc.set(current.id, current); // If id already exists, it'll be overwritten
            if(current.is_used){
              acc.set(current.id, current);
            }else{
              if(!acc.has(current.id)){
                acc.set(current.id, current);
              }
            }
            return acc;
          }, new Map());
          this.customFields = Array.from(mergedArray.values());
        }else{
          this.customFields = res.data;
        }
        // const old_deprecated_custom_fields = [];
        // this.customFields = res.data.map(customField => {
        //   if(this.companyUser.id && this.companyUser.custom_fields?.length){
        //     const indexOfOldCustomField = this.companyUser.custom_fields.findIndex(i=>i.id==customField.id);
        //     if(indexOfOldCustomField>=0){
        //       customField = this.companyUser.custom_fields[indexOfOldCustomField];
        //     }else{
        //       old_deprecated_custom_fields.push(this.companyUser.custom_fields[indexOfOldCustomField]);
        //     }
        //   }else if(!customField.default_value_enabled){
        //     customField.default_value = '';
        //   }
        //   return customField
        // });
        // this.customFields.concat(old_deprecated_custom_fields);
        this.customFieldLoader = false;
      },
      
      error:(err)=>{
      
        console.error(err);
      }
    }); 
  }

  openCustomFieldsList(){
    const ngbOffcanvasRef = this.ngbOffcanvas.open(PartyCustomFieldsListComponent,{
      ariaLabelledBy: "offcanvas-basic-title",
      position: "end",
      backdrop: "static",
      panelClass: "offcanvas-sm",
    });

    ngbOffcanvasRef.componentInstance.source = {customFields:this.customFields};
    
    ngbOffcanvasRef.result.then((onFullfilled) => {
     this.customFields = onFullfilled;
    },(onReject)=>{
      //
    });
  }

  editCompanyUserCustomField(companyUserId:string){
  const data = {id:companyUserId, custom_fields:this.customFields};
   this.adminService.editPartyCustomField(data).subscribe({
    next:(res)=>{
      this.customFields = res;
    },
    error:(err)=>{
      console.error(err);
    }
   })
  }


  openAddAddress(address?:Address){
    const offcanvasRef = this.ngbOffcanvas.open(AddAddressComponent,{
      ariaLabelledBy:"offcanvas-basic-title",
      position:'end',
      panelClass:'offcanvas-sm bank-canvas',
      backdropClass:'bank-canvas-backdrop'
    });

    offcanvasRef.componentInstance.parent = {
      type: 'party',
      party_company_user_id: this.companyUser.id,
      address:address
    }


    offcanvasRef.result.then(res=>{
      this.listPartyAddress();
    }, rej=>{console.log(rej)})
  }

  deleteAddress(address:Address){
    if (address.primary == 1) {
      this.notificationServices.initiate({ title: "Primary address can not be deleted", type: toastTypes.error });
      return
    }
    const confirmModal = this.ngbModal.open(ConfirmModalComponent);
    confirmModal.componentInstance.data = { modalTitle: 'Delete Confirmation', modalContent: 'Are you sure?' };
    confirmModal.result.then(onFilled=>{
      this.adminService.deleteAddress(address.id).subscribe({
        next:res=>{
          this.listPartyAddress();
        },
        error:err=>{
          console.log(err)
        }
      })
    },onReject=>{
      console.log(onReject)
    })
  }
  listPartyAddress(){
    let qp = new HttpParams();
    qp = qp.set('company_user_id', this.companyUser.id || this.parent.companyUser?.id);
    qp = qp.set('company_id', this.company.id);
    this.adminService.listCompanyUserAddress(qp).subscribe(res => {
      this.partyAddress = res.address_list || [];
      this.valueStack = res;
    }, err => console.log(err))
  }
  setPrimaryAddress(item: Address){
    if(item.primary == 1) return this.notificationServices.initiate({title:'Address already set as primary', type:toastTypes.error});
    else{
      this.loading = true;
      this.adminService.setPrimaryAddress({id:item.id}).subscribe({
        next:(res)=>{
          this.notificationServices.initiate({title:'Address set as primary', type:toastTypes.success});
          this.listPartyAddress();
          this.loading = false;
        },
        error:(err)=>{
          this.loading = false;
          console.log(err);
          this.notificationServices.initiate({title:'Unable to set address as primary', type:toastTypes.error});
        }
      })
    }
  }
  openPartyPrefixSequence(){
    const ngbModalRef = this.ngbModal.open(PartyPrefixSequenceComponent,{
      size:'md',
      backdrop:'static'
    })

    ngbModalRef.componentInstance.data = {partyCompanyUser: this.companyUser};

    ngbModalRef.result.then((onFulfilled) => {
      this.companyUser.prefix = onFulfilled.prefix;
      this.companyUser.sequence = onFulfilled.sequence;
      this.companyUser.party_id = onFulfilled.party_id;
    }, (onReject)=>{
      //
    })
  }
}
