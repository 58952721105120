import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { DeductionItem } from 'src/app/interfaces/interfaces';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
import { ForReductionDeduction } from 'src/app/enum/enum';
import { CreateNewRetentionDeductionComponent } from 'src/app/sales-invoice/components/create-new-retention-deduction/create-new-retention-deduction.component';

interface Parent {
  project_id?: string;
  salesorder_invoice_id?: string;
  old_deductionList?: DeductionItem[];
  invoice_total_amount?: number;
  invoice_subtotal_amount?: number;
  totalDeductionAmount?: number;
  is_preTax?: boolean;
  party_company_user_id?: string;
  feature_id?: string;
  feature_type?: string;
}

interface AddDeductionEntry {
  deduction_item_id?: string;
  amount?: number;
  salesorder_invoice_id?: string;
  feature_id?: string;
  project_id?: string;
}

interface EditDeductionEntry {
  id?: string;
  amount?: number;
  delete?: number;
}

@Component({
  selector: "app-add-edit-deduction",
  templateUrl: "./add-edit-deduction.component.html",
  styleUrls: ["./add-edit-deduction.component.css"],
})
export class AddEditDeductionComponent implements OnInit {
  @Input() parent = {} as Parent;
  private companyId: string;
  public deductionList: DeductionItem[] = [];
  public selectedDeductionItem: DeductionItem[] = [];
  public addDeductionItemList: DeductionItem[] = [];
  public editDeductionItemList: DeductionItem[] = [];
  public isChecked = false;
  public totalDeductionAmount = 0;
  public busy = false;
  public valueStack = { page: { next_url: "" } };
  private project_id: string;
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    private ngbOffcanvas: NgbOffcanvas,
    private adminService: AdminService,
    public authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private changeDetector: ChangeDetectorRef,
    public validatorService: ValidatorService,
    public utilService: UtilService,
    private notificationService: NotificationsService
  ) {
    this.companyId = this.authService.session.company.id;
  }

  ngOnInit(): void {
    this.project_id =
      this.parent.project_id || this.authService.session.project.id;
    if (this.parent.salesorder_invoice_id) {
      this.listSalesInvoiceDeductionEntries();
    } else if (this.parent.feature_id) {
      this.listGenericDeductionEntries();
    } else {
      this.getDeductionsList();
    }
  }

  toggleUnCheckbox(item: DeductionItem): void {
    const selectedListIndex = this.selectedDeductionItem.findIndex(
      (ded) => ded.id === item.id
    );
    if (selectedListIndex !== -1) {
      this.selectedDeductionItem.splice(selectedListIndex, 1);
    }

    if (item.monkey_patch_deduction_entry.id) {
      item.monkey_patch_deduction_entry.delete = 1;
      this.editDeductionItemList.push(item);
    } else {
      const index = this.addDeductionItemList.findIndex(
        (ded) => ded.id === item.id
      );
      if (index !== -1) this.addDeductionItemList.splice(index, 1);
    }

    item.monkey_patch_deduction_entry.amount = 0;
    this.calculateTotalDeductionAmount();
  }

  openAddNewDeductionItem(): void {
    const ngbOffcanvasRef = this.ngbOffcanvas.open(
      CreateNewRetentionDeductionComponent,
      {
        position: "end",
        panelClass: "offcanvas-sm",
        backdrop: "static",
      }
    );

    ngbOffcanvasRef.componentInstance.componentData = {
      from: ForReductionDeduction.DEDUCTION,
    };

    ngbOffcanvasRef.result.then(
      (res: DeductionItem) => {
        this.deductionList.unshift(res);
      },
      () => {
        //
      }
    );
  }

  getDeductionsList(): void {
    let params = new HttpParams().set("company_id", this.companyId);
    params = params.set("pre_tax", this.parent.is_preTax ? "1" : "0");

    this.adminService.listDeductions(params).subscribe((res) => {
      this.updateDeductionListWithOldItems(res.deductionItems);
      this.valueStack = res;
      this.deductionList = res.deductionItems;
      this.selectedDeductionItem = this.deductionList.filter(
        (item) => item.monkey_patch_deduction_entry.amount > 0
      );
      this.calculateTotalDeductionAmount();
    });
  }

  listGenericDeductionEntries(): void {
    const params = new HttpParams()
      .set("feature_id", this.parent.feature_id)
      .set("feature_type", this.parent.feature_type)
      .set("company_id", this.companyId)
      .set("pre_tax", this.parent.is_preTax ? "1" : "0");

    this.adminService
      .listGenericDeductionItemsEntries(params)
      .subscribe((res: { deductionItems: DeductionItem[]; page: any }) => {
        this.updateDeductionListWithOldItems(res.deductionItems);
        this.valueStack = res;
        this.deductionList = res.deductionItems;
        this.selectedDeductionItem = this.deductionList.filter(
          (item) => item.monkey_patch_deduction_entry.amount > 0
        );
        this.calculateTotalDeductionAmount();
      });
  }

  listSalesInvoiceDeductionEntries(): void {
    const params = new HttpParams()
      .set("company_id", this.companyId)
      .set("salesorder_invoice_id", this.parent.salesorder_invoice_id || "")
      .set("pre_tax", this.parent.is_preTax ? "1" : "0");

    this.adminService
      .listSalesInvoiceDeductionEntries(params)
      .subscribe((res: { deductionItems: DeductionItem[]; page: any }) => {
        this.updateDeductionListWithOldItems(res.deductionItems);
        this.valueStack = res;
        this.deductionList = res.deductionItems;
        this.selectedDeductionItem = this.deductionList.filter(
          (item) => item.monkey_patch_deduction_entry.amount > 0
        );
        this.calculateTotalDeductionAmount();
      });
  }

  numberValidate(e: any): void {
    this.changeDetector.detectChanges();
  }

  onValueChange(e: any, item: DeductionItem): void {
    const formattedValue = this.validatorService.numberFormat(e, {
      type: "string",
      decimal: true,
      fieldType: IPOP_FIELD_TYPE.Price,
    });
    item.monkey_patch_deduction_entry.amount = parseFloat(formattedValue) || 0;

    if (item.monkey_patch_deduction_entry.id) {
      if (item.monkey_patch_deduction_entry.amount === 0) {
        item.monkey_patch_deduction_entry.delete = 1;
      } else {
        item.monkey_patch_deduction_entry.delete = 0;
      }
      this.updateDeductionItemList(this.editDeductionItemList, item);
    } else {
      this.updateDeductionItemList(this.addDeductionItemList, item);
    }

    this.updateDeductionItemList(this.selectedDeductionItem, item);
    this.calculateTotalDeductionAmount();
  }

  updateDeductionItemList(list: DeductionItem[], item: DeductionItem): void {
    const index = list.findIndex((ded) => ded.id === item.id);
    if (index === -1) {
      list.push(item);
    } else {
      list[index] = item;
    }
  }

  save(): void {
    if (this.validateAmount()) {
      return;
    }
    const deductionAddEntryObjectList: AddDeductionEntry[] =
      this.addDeductionItemList.map((item) => ({
        deduction_item_id: item.id,
        amount: item.monkey_patch_deduction_entry.amount,
        salesorder_invoice_id: this.parent.salesorder_invoice_id,
        feature_id: this.parent.feature_id,
        feature_type: this.parent.feature_type,
        project_id: this.project_id,
        pre_tax: this.parent.is_preTax ? 1 : 0,
      }));

    console.log(deductionAddEntryObjectList);

    const deductionEditEntryObjectList: EditDeductionEntry[] =
      this.editDeductionItemList.map((item) => ({
        id: item.monkey_patch_deduction_entry.id,
        amount: item.monkey_patch_deduction_entry.amount,
        delete: item.monkey_patch_deduction_entry.delete,
        pre_tax: this.parent.is_preTax ? 1 : 0,
      }));

        console.log(deductionEditEntryObjectList);

    if (!this.parent.salesorder_invoice_id && !this.parent.feature_id) {
      this.ngbActiveOffcanvas.close({
        totalDeductionAmount: this.totalDeductionAmount,
        deductionList: this.addDeductionItemList,
      });
    } else {
      if (deductionAddEntryObjectList.length) {
        this.addBulkDeduction(deductionAddEntryObjectList);
      }
      if (deductionEditEntryObjectList.length) {
        this.editBulkDeduction(deductionEditEntryObjectList);
      }
    }

    this.ngbActiveOffcanvas.close({
      totalDeductionAmount: this.totalDeductionAmount,
    });
  }

  addBulkDeduction(itemList: AddDeductionEntry[]): void {
    const payload = {
      deduction_entry_data: itemList,
      party_company_user_id: this.parent.party_company_user_id,
      project_id:
        this.parent.project_id || this.authService.session.project.id,
      feature_id: this.parent.feature_id,
      feature_type: this.parent.feature_type,
    };
    this.adminService.genericAddBulkDeductionEntry(payload).subscribe({
      next: () =>
        this.ngbActiveOffcanvas.dismiss({
          totalDeductionAmount: this.totalDeductionAmount,
        }),
      error: (err) => console.error(err),
    });
  }

  editBulkDeduction(itemList: EditDeductionEntry[]): void {
    this.adminService.genericEditBulkDeductionEntry(itemList).subscribe({
      next: () =>
        this.ngbActiveOffcanvas.dismiss({
          totalDeductionAmount: this.totalDeductionAmount,
        }),
      error: (err) => console.error(err),
    });
  }

  calculateTotalDeductionAmount(): void {
    this.totalDeductionAmount = this.selectedDeductionItem.reduce(
      (total, item) => {
        return total + (item.monkey_patch_deduction_entry.amount || 0);
      },
      0
    );
  }

  onScroll(): void {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe({
        next: (res) => {
          this.valueStack = res;
          this.updateDeductionListWithOldItems(res.deductionItems);
          this.deductionList = this.deductionList.concat(res.deductionItems);
          this.selectedDeductionItem = this.deductionList.filter(
            (item) => item.monkey_patch_deduction_entry.amount > 0
          );
          this.calculateTotalDeductionAmount();
          this.busy = false;
        },
        error: () => {
          this.busy = false;
        },
      });
    }
  }

  private updateDeductionListWithOldItems(
    newDeductionItems: DeductionItem[]
  ): void {
    if (this.parent.old_deductionList?.length) {
      const itemsToRemove = [];

      for (let i = 0; i < this.parent.old_deductionList.length; i++) {
        const oldItem = this.parent.old_deductionList[i];

        for (let j = 0; j < newDeductionItems.length; j++) {
          const newItem = newDeductionItems[j];

          if (oldItem.id === newItem.id) {
            newItem.monkey_patch_deduction_entry.amount =
              oldItem.monkey_patch_deduction_entry.amount;
            this.addDeductionItemList.push(newItem);
            itemsToRemove.push(i);
          }
        }
      }

      // Remove items from old_deductionList in reverse order
      for (let i = itemsToRemove.length - 1; i >= 0; i--) {
        this.parent.old_deductionList.splice(itemsToRemove[i], 1);
      }
    }
  }

  goBack() {
    if (this.parent.salesorder_invoice_id || this.parent.feature_id) {
      this.ngbActiveOffcanvas.close({
        totalDeductionAmount: this.parent.totalDeductionAmount,
        deductionList: this.parent.old_deductionList,
      });
    } else {
      this.ngbActiveOffcanvas.close({
        totalDeductionAmount: this.totalDeductionAmount,
        deductionList: this.addDeductionItemList,
      });
    }
  }

  validateAmount(): boolean {
    if (this.parent.is_preTax) {
      if (this.parent.invoice_subtotal_amount < this.totalDeductionAmount) {
        this.notificationService.initiate({
          title: "Total amount should be greater than deduction amount",
          type: toastTypes.error,
        });
        this.addDeductionItemList = [];
        this.totalDeductionAmount = 0;

        return true;
      }
    } else {
      if (this.parent.invoice_total_amount < this.totalDeductionAmount) {
        this.notificationService.initiate({
          title: "Total amount should be greater than deduction amount",
          type: toastTypes.error,
        });
        this.addDeductionItemList = [];
        this.totalDeductionAmount = 0;
        return true;
      }
    }

    return false;
  }
}
