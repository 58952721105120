import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicNumber'
})
export class DynamicNumberPipe implements PipeTransform {
  transform(value: number, maxDigitCount: number): string {
   
    if (typeof value !== 'number') {
      console.error('Value must be a number');
      return '';
    }
  
    return value.toLocaleString(undefined, {
      maximumFractionDigits: maxDigitCount
    });
  }
  
}

