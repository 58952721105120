<div id="chart">
    <ng-container *ngIf="chartOptions">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [yaxis]="chartOptions.yaxis"
          [xaxis]="chartOptions.xaxis"
          [legend]="chartOptions.legend"
          [colors]="chartOptions.colors"
          [grid]="chartOptions.grid"
          [tooltip]="chartOptions.toolTip"
        ></apx-chart>
      </ng-container>
    
</div>