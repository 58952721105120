export enum CompanyUserPolicy {
  Add_album = "add_album", //verified
  Edit_album = "edit_album", //verified
  Add_i_paid = "add_i_paid", //verified
  Add_i_received = "add_i_received", //verified
  Add_material_item = "add_material_item", //verified
  Add_material_used = "add_material_used", //verified
  Add_material_return = "add_material_return", //verified
  EDIT_MATERIAL_RETURN = "edit_material_return", //verified
  Add_new_party = "add_new_party", //verified
  Add_new_project = "add_new_project", //verified
  Add_other_expense = "add_other_expense", //verified
  Add_payment_in = "add_payment_in", //verified
  Add_payment_out = "add_payment_out", //verified
  Add_petty_expesne = "add_petty_expesne", //to_be_implemented_like_mobile
  Add_photo = "add_photo", //verified
  Add_project_team = "add_project_team", //verified
  Add_purchase = "add_purchase", //verified
  Add_quotation = "add_quotation", //verified
  Add_received = "add_received", //verified
  
  Add_sales_invoice = "add_sales_invoice", //verified
  EDIT_SALES_INVOICE = "edit_sales_invoice", //verified
  Approval = "approval", //to_be_implemented_like_mobile
  Approve_payment_in = "approve_payment_in", //to_be_implemented_like_mobile
  Approve_payment_out = "approve_payment_out", //to_be_implemented_like_mobile
  Approve_petty_expense = "approve_petty_expense", //to_be_implemented_like_mobile
  Approve_purchase = "approve_purchase", //to_be_implemented_like_mobile
  Approve_update_progress = "approve_update_progress", //to_be_implemented_like_mobile
  APPROVE_PARTY_PAYMENT = "approve_party_payment", //to_be_implemented_like_mobile
  APPROVE_MATERIAL_SALES = "approve_material_sales", //to_be_implemented_like_mobile
  APPROVE_MATERIAL_RETURN = "approve_material_return", //to_be_implemented_like_mobile
  APPROVE_SUBCON_EXPENSE = "approve_subcon_expense", //to_be_implemented_like_mobile
  Comapny_details = "comapny_details", //replacement
  Edit_bank_details = "edit_bank_details", //verify_later
  Edit_company_team_member = "edit_company_team_member", //verified
  Edit_material_item_detail = "edit_material_item_detail", //verified
  Edit_material_received = "edit_material_received", //verified
  Edit_material_used = "edit_material_used", //verified
  Edit_other_expense = "edit_other_expense", //verified
  Edit_party_detail = "edit_party_detail",
  Edit_payment_in = "edit_payment_in", //to_be_implemented_like_mobile
  Edit_payment_out = "edit_payment_out", //to_be_implemented_like_mobile
  Edit_petty_expense = "edit_petty_expense", //verified
  Edit_photo = "edit_photo", //verified
  EDIT_PROJECT_DETAILS = "edit_project_details", //mobile
  Edit_purchase = "edit_purchase", //verified
  Edit_quotation = "edit_quotation", //verified
  Edit_staff_payment = "edit_staff_payment", //verified
  Material_received = "material_received", //verified
  Other_expense = "other_expense", //verified
  Remove_project_team = "remove_project_team", //mobile
  Task_and_photos = "task_and_photos",
  View_all_task = "view_all_task", //verified
  View_my_task = "view_my_task", //verified
  Add_task = "add_task", //verified
  Add_task_progress = "add_task_progress", //verified
  Add_task_comment = 'add_task_comment', //verified
  Assign_task_to_user = "assign_task_to_user", //verified
  Edit_task_status = "edit_task_status", //verified
  Edit_task = "edit_task", //verified
  Edit_task_progress = "edit_task_progress", //verified
  View_all_transactions = "view_all_transactions", //verified
  View_company_team = "view_company_team", //mobile
  View_material = "view_material", //verified
  View_material_library = "view_material_library",
  View_my_transactions = "view_my_transactions",
  View_other_expense = "view_other_expense", //not used in web
  View_party_library = "view_party_library", //verified
  View_photos = "view_photos", //verified
  View_project_party_balance = "view_project_party_balance", //verified
  View_project_party_balance_detail = "view_project_party_balance_detail", //mobile
  View_company_party_ledger = "view_company_party_ledger", //to_be_implemented_like_mobile
  Download_company_reports = "download_company_reports",
  View_company_dashboard = "view_company_dashboard",
  VIEW_TRADE_LIBRARY = "view_trade_library", //verified
  Add_new_trade = "add_new_trade", //verified
  Edit_trade = "edit_trade", //verified
  View_salary_amount = "view_salary_amount", //verified
  Add_material_request = "add_material_request", //verified
  EDIT_MATERIAL_REQUEST = "edit_material_request", //verified
  View_material_request = "view_material_request", //verified
  View_project_dashboard = "view_project_dashboard", //verified
  View_Equipment = "view_equipment", //verified
  View_Equipment_Library = "view_equipment_library", //verified
  Add_Equipment = "add_equipment", //verified
  Edit_Equipment = "edit_equipment", //verified
  Delete_Equipment = "delete_equipment", //verified
  Add_Equipment_Stock = "add_equipment_stock", //verified
  Edit_Equipment_Stock = "edit_equipment_stock", //verified
  Add_Equipment_Used_Entry = "add_equipment_used_entry",
  Edit_Equipment_Used_Entry = "edit_equipment_used_entry",
  Company_Dashboard = "company_dashboard",
  Company_Reports = "company_reports",
  Transaction_Summary = "transaction_summary", //verified
  Project_Overview = "project_overview", //verified
  EDIT_MATERIAL_REQUEST_STATUS = "edit_material_request_status", //verified
  View_Subcon = "view_subcon", //verified
  View_My_Subcon = "view_my_subcon", //verified
  Add_Subcon_Workorder = "add_subcon_workorder", //verified
  Edit_Subcon_Workorder = "edit_subcon_workorder", //verified
  View_Subcon_Expense = "view_subcon_expense", //verified
  Add_Subcon_Expense = "add_subcon_expense", //verified
  Edit_Subcon_Expense = "edit_subcon_expense", // verified
  Add_Subcon_Workorder_Progress = "add_subcon_workorder_progress", //verified
  Edit_Subcon_Workorder_Progress = "edit_subcon_workorder_progress", //verified
  Add_Subcon_Billing_Activity = "add_subcon_billing_activity", // verified
  Edit_Subcon_Billing_Activity = "edit_subcon_billing_activity",// verified
  Add_subcon_comment = 'add_subcon_comment', // verified

  Procurement = "procurement",
  View_Purchase_Order_List = "view_purchase_order_list",
  Edit_Purchase_Order = "edit_purchase_order", //verified
  Mark_Close_Purchase_Order = "mark_close_purchase_order",
  Edit_Purchase_Order_Status = "edit_purchase_order_status",
  Add_Purchase_Order = "add_purchase_order", //verified

  VIEW_ASSET = "view_asset",
  ADD_ASSET = "add_asset",
  EDIT_ASSET="edit_asset",
  ADD_ASSET_ALLOCATION="add_asset_allocation",
  EDIT_ASSET_ALLOCATION="edit_asset_allocation",
  VIEW_ASSET_ALLOCATION="view_asset_allocation",
  View_Project_Balances="view_project_balances", //verified
  
  VIEW_ALL_TODO = "view_all_todo", //verified
  VIEW_MY_TODO = "view_my_todo", //verified
  ADD_TODO = "add_todo",
  EDIT_TODO = "edit_todo", //verified
  EDIT_TODO_STATUS = "edit_todo_status", //verified
  ADD_TODO_COMMENT = "add_todo_comment", //verified

  Add_Deduction_Item = "add_deduction_item", //to_be_implemented_like_mobile
  Edit_Deduction_Item = "edit_deduction_item",//to_be_implemented_like_mobile
  View_Deduction_Library = "view_deduction_library",//to_be_implemented_like_mobile

  VIEW_TODO_TYPE_LIBRARY = "view_todo_type_library",
  ADD_TODO_TYPE = "add_todo_type",
  EDIT_TODO_TYPE = "edit_todo_type",

  ADD_RETENTION_ITEM = "add_retention_item",//to_be_implemented_like_mobile
  EDIT_RETENTION_ITEM = "edit_retention_item",//to_be_implemented_like_mobile
  VIEW_RETENTION_LIBRARY = "view_retention_library",//to_be_implemented_like_mobile

  ADD_DEBIT_NOTE ="add_debit_note", // verified
  EDIT_DEBIT_NOTE ="edit_debit_note", // verified

  ADD_PARTY_TO_PARTY_PAYMENT = "add_party_to_party_payment", // verified

  VIEW_SITE_PHOTOS = "view_site_photos", // verified
  VIEW_SUBCON_SUMMARY = "view_subcon_summary", // verified

  VIEW_MY_QUOTATION = "view_my_quotation", // verified
  VIEW_QUOTATION = "view_quotation", //mobile

  service_rate_library = "service_rate_library",
  view_list_service_rate = "view_list_service_rate",
  add_service_rate_library = "add_service_rate_library",
  edit_service_rate_library = "edit_service_rate_library",

  estimate = "estimate",
  view_my_boq = "view_my_boq",
  view_all_boq = "view_all_boq",
  add_new_boq = "add_new_boq",
  edit_boq = "edit_boq",
  view_budget = "view_budget",
  ADD_ZOHO_LOGIN = "add_zoho_login",
  VIEW_THIRD_PARTY_INTEGRATIONS = "view_third_party_integrations",

	ADD_MATERIAL_SALES = "add_material_sales", // verified
	EDIT_MATERIAL_SALES = "edit_material_sales", // verified

	VIEW_ATTENDANCE = "view_attendance", //verified
	VIEW_STAFF_ATTENDANCE = "view_staff_attendance",
	VIEW_LABOUR_ATTENDANCE = "view_labour_attendance",

  ADD_ATTENDANCE = "add_attendence",
	EDIT_ATTENDANCE = "edit_attendence",
	ADD_LABOUR_ATTENDANCE = "add_labour_attendance", // verified
	EDIT_LABOUR_ATTENDANCE = "edit_labour_attendance", // verified
	ADD_STAFF_ATTENDANCE = "add_staff_attendance", // verified
	EDIT_STAFF_ATTENDANCE = "edit_staff_attendance", // verified
  
  VIEW_PAYROLL_PEOPLE = "view_payroll_people", //verified
	ADD_WORKFORCE = "add_workforce", //verified //need_to_be_disscussed
	EDIT_WORKFORCE = "edit_workforce", //verified
	VIEW_WORKFORCE_LIBRARY = "view_workforce_library", //verified
	ADD_STAFF_PAYROLL = "add_staff_payroll", //verified
	EDIT_STAFF_PAYROLL = "edit_staff_payroll", //verified
	ADD_LABOUR_PAYROLL = "add_labour_payroll", //verified
	EDIT_LABOUR_PAYROLL = "edit_labour_payroll", //verified
	VIEW_LABOUR_ATTENDANCE_PROJECT = "view_labour_attendance_project", //verified
  ASSOCIATE_PAYROLL_PROJECT = "associate_payroll_project", //verified
  EDIT_PUNCH_SETTINGS = "edit_punch_settings", //verified
  VIEW_PUNCH_SETTINGS = "view_punch_settings", //verified
  VIEW_COMPANY_TRANSACTIONS = "view_company_transactions", //verified
  EDIT_COMPANY_TERMS = "edit_company_terms", //verified
  ADD_COMPANY_ROLE = "add_company_role", //verified
  EDIT_COMPANY_ROLE = "edit_company_role", //verified

  ADD_MATERIAL_TRANSFER = "add_material_transfer", //verified
  EDIT_MATERIAL_TRANSFER = "edit_material_transfer", //verified
  FILTER_IN_REPORTS = "filter_in_reports", //to_be_implemented_like_mobile
  ADD_SALARY_BREAKUP_COMPONENT = "add_salary_breakup_component", //verified
  EDIT_SALARY_BREAKUP_COMPONENT = "edit_salary_breakup_component", //verified
  VIEW_ASSET_LIBRARY = "view_asset_library", //backend_todo
  GENERATOR_DPR = "generator_dpr", //verified
  
  VIEW_MATERIAL_CATEGORY_LIBRARY = "view_material_category_library", //verified //backend_todo
  ADD_MATERIAL_CATEGORY = "add_material_category", //verified //backend_todo
  EDIT_MATERIAL_CATEGORY = "edit_material_category", //verified //backend_todo
}
