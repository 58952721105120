import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {
  @Input() is_dirty?: Boolean;
  constructor(
    public ngbActiveOffcanvas: NgbActiveOffcanvas
  ) { }

  ngOnInit(): void {
    //
  }

}
