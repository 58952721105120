import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { AddEditSubconExpense, CompanyUser, WorkOrder } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { AttachmentViewComponent } from '../../attachment-view/attachment-view.component';

interface CreateWorkOrder {
  id?: string,
  name?:string,
  creator_company_id?:string,
  client_project_id?:string,
  client_company_vendor_cu_id?: string,
  prefix?: string,
  sequence?: string,
  invoiced_amount?: number,
  terms?:string,
  photos?:string[]
}
interface Source {
  fromPage?: 'add-subcon-workorder' | 'edit-subcon-workorder' | 'edit-subcon-expense-prefix';
  subconWorkorder?: CreateWorkOrder
  date?: Date;
}
@Component({
  selector: 'app-subcon-workorder-modal',
  templateUrl: './subcon-workorder-modal.component.html',
  styleUrls: ['./subcon-workorder-modal.component.css']
})
export class SubconWorkorderModalComponent implements OnInit {
  @Input() source = {} as Source;
  @ViewChild('child') attachmentView: AttachmentViewComponent;

  public subConWorkOrder = {} as CreateWorkOrder;
  public model: NgbDateStruct;
  public today: NgbDateStruct;
  public tncToggle = false;
  public tnc:string;
  // public photos: string[];
  constructor(
    public activeModal: NgbActiveModal,
    public adminService:AdminService,
    public authService: AuthService,
    private calendar: NgbCalendar,
    private utilService: UtilService,
    private validatorService: ValidatorService,
    private notificationService:NotificationsService,
    private changeDetectorRef:ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.model =  this.calendar.getToday();
    this.today = this.calendar.getToday();
    this.subConWorkOrder = this.source.subconWorkorder || {};
    if(this.subConWorkOrder.id && this.subConWorkOrder.terms){
      this.tncToggle = true;
      this.tnc = this.subConWorkOrder.terms;
    }

   

   
    if (this.subConWorkOrder.client_company_vendor_cu_id) this.utilService.sendPartyId(this.subConWorkOrder.client_company_vendor_cu_id);
    if (this.source.date) {
      this.model = {
        day: new Date(this.source.date).getUTCDate(),
        month: new Date(this.source.date).getUTCMonth() + 1,
        year: new Date(this.source.date).getUTCFullYear(),
      };
    }
  }

  ngAfterViewInit(){
    if(this.subConWorkOrder.id && this.subConWorkOrder.photos.length){
      this.attachmentView?.getFiles(this.subConWorkOrder.photos); 
    }
    this.changeDetectorRef.detectChanges();
  }
  
  ngOnDestroy(): void {
    this.utilService.sendPartyId(null);
  }

  
  
  selectedUser(user: CompanyUser) {
    if(user) this.subConWorkOrder.client_company_vendor_cu_id = user.id;
  }
  changeSubconExpenseInvoiceNumber() {
    const returnData = {
      date: this.validatorService.dateToUtc(
        this.model.day,
        this.model.month,
        this.model.year
      ),
      prefix: this.subConWorkOrder.prefix,
      sequence: this.subConWorkOrder.sequence
    }
    if (!this.subConWorkOrder?.id) {
      return this.activeModal.close(returnData);
    }
    this.adminService.editSubConExpenseInvoiceNumber({ id: this.subConWorkOrder.id, prefix: this.subConWorkOrder.prefix, sequence: this.subConWorkOrder.sequence, terms:this.subConWorkOrder.terms, photos:this.subConWorkOrder.photos }).subscribe({
      next: (res: any) => {
        this.activeModal.close(returnData);
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  changeSubconQutaionNumber() {
    this.adminService.editSubConWorkOrderQutaionNumber({ id: this.subConWorkOrder.id, prefix: this.subConWorkOrder.prefix, sequence: this.subConWorkOrder.sequence, terms:this.subConWorkOrder.terms, photos:this.subConWorkOrder.photos}).subscribe({
      next: (res: any) => {
        // 
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  updateWorkOrder(){
    if(!this.subConWorkOrder.client_company_vendor_cu_id){
      this.notificationService.initiate({title:'Select Party!!', type:toastTypes.warning});
      return
    }
    this.subConWorkOrder.client_project_id = this.authService.session.project.id;
    this.subConWorkOrder.creator_company_id = this.authService.session.company.id;
    this.subConWorkOrder.terms = this.tnc;
    let updateSubscriber;
    switch (this.source.fromPage) {
      case 'add-subcon-workorder':
        this.subConWorkOrder.photos = this.attachmentView.filesFromChild();
        updateSubscriber = this.adminService.addSubConWorkOrder(this.subConWorkOrder);
        break;
      case 'edit-subcon-workorder':
        this.subConWorkOrder.photos = this.attachmentView.filesFromChild();
        updateSubscriber = this.adminService.editSubConWorkOrder(this.subConWorkOrder);
        this.changeSubconQutaionNumber();
        break;
      case 'edit-subcon-expense-prefix':
        this.changeSubconExpenseInvoiceNumber();
        break;
    }
    if (updateSubscriber) {
      updateSubscriber.subscribe({
        next: (res: any) => {
          this.activeModal.close(res);
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }
  
}
