<div class="expenses">
    <!-- <div class="expenses-header">
        <span class="f-14 px-2">Expense Analysis</span>
    </div>   -->

   <div class="expense-body">
    <div class="custom-chart" id="chart">
        <ng-container *ngIf="chartOptions">
          <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [labels]="chartOptions.labels"
          [responsive]="chartOptions.responsive"
          [legend]="chartOptions.legend "
          [dataLabels]="chartOptions.dataLabels"
          [colors]="chartOptions.colors"
        ></apx-chart>
        </ng-container>
      </div>

      <div  class="w-100 d-flex flex-column gap-3">
        <div  class="custom-legend w-100">
          <ng-container *ngFor="let item of labels.slice(0, 5); let j = index">
            <div class="legend-tile d-flex justify-content-between align-items-center w-100">
              <div class="leading d-flex align-items-center gap-2 ">
                <div [style.background-color]="colors[j]" class="indicator-dot "></div>
                <span class="f-12 legend-label">{{labels[j]}}</span>
              </div>
              <span class="f-12 w-20 value">{{values[j]}}</span> 
            </div>
          </ng-container>          
           </div>
           <span (click)="openExpense()" *ngIf="labels.length>4" class="f-12 disabled-font mx-2 cursor">view more</span>
      </div>
   </div>
  
</div>