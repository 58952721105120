import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Profile, Token } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent  {
  constructor (
    public authService: AuthService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {

    this.verifyToken();
    if (this.authService.session.isActive) {
      this.authService.getUserByToken().subscribe(data => {
        this.authService.session.profile = data.user as Profile;
        this.authService.saveProfile(this.authService.session.profile);
        let companyId = this.authService.session.profile.current_company_id;
        if (!companyId) {
          if (this.authService.session?.profile?.monkey_patch_company_user?.length > 0) {
            companyId = this.authService.session.profile.monkey_patch_company_user[0].company_id;
          }else{
            this.logout();
          }
        }
        if (companyId) {
          this.router.navigate([`/c/${companyId}/d/home`]);
          console.log('Welcome to Onsite! Your company id is', companyId);
          // this.router.navigate([`/c/${companyId}`]);
        } else {
          console.log('Company Id not found')
        }
      }, error => {
        console.log('i am', error);
      });
    } else {
      this.router.navigate([`/login`]);
    }
  }
  verifyToken() {
    const nowDate = new Date();
    const userToken = this.localStorageService.get('token') as Token || {};
    if (userToken) {
      const tokenExpDate = new Date(userToken.expire);
      const diff = (tokenExpDate.getTime() - nowDate.getTime()) / (1000 * 3600 * 24);
      if (diff < 5 && diff > 0) {
        this.authService.refreshToken().subscribe(res => {
          this.authService.saveToken(res);
        }, () => {
          this.logout();
        });
      } else if (diff <= 0) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }
  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }
}
