import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { HttpParams } from '@angular/common/http';
import { Company, CompanyUser, Project } from 'src/app/interfaces/interfaces';

interface Filteration {
  project?: Project,
  company?: Company,
  filterTypes?: any[]
}
// Types of filterTypes
// 'date'
// 'project'
// 'party'

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.css'],
})
export class FilterModalComponent implements OnInit {
  @Input() companyReport = {} as Filteration;
  public select_what: string;
  public select_option_right = [];
  public select_option_left = [];

  public queryParams = new HttpParams();

  // selection / filtreration key

  // for project
  public projects = [] as Project[];
  public project = {} as Project;

  // for company users || party
  public companyUsers = [] as CompanyUser[];
  public companyUser = {} as CompanyUser;

  // for date
  public startDate?: Date;
  public endDate?: Date;
  public report_duration_title?: string = "All Time Date";


  public selectedFilterSwitch = 'date';
  public dateRange = "";
  public dateSelector = [
    { key: "all", value: "All" },
    { key: "today", value: "Today" },
    { key: "lastWeek", value: "Last Week (Mon - Sun)" },
    { key: "lastMonth", value: "Last Month" },
    { key: "thisWeek", value: "This Week (Mon - Today)" },
    { key: "thisMonth", value: "This Month" }
  ];

  constructor(
    private ngbCarouselConfig: NgbCarouselConfig,
    public activeModal: NgbActiveModal,
    private adminService: AdminService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    if (this.companyReport.filterTypes.length <= 0) {
      alert('no filter found')
    }
    this.getProjects();
    this.getCompanyUsers();
    // console.log(this.project);
    // this.project;
    // this.getPartyMemberList();

    if(this.companyReport.project){
      this.project = this.companyReport.project;
    }
  }

  dismiss(str) {
    this.activeModal.dismiss('Hi');
  }

  materialEntries() {
    this.select_what = 'Material';
    let materialEntries = [];
    materialEntries = this.localStorageService.get('materialEntries') || [];
    const temp = new Set(materialEntries.map(i => i.monkey_patch_materialitem.name));
    for (const i of temp) {
      this.select_option_right.push(i);
    }
  }

  // getPartyMemberList() {
  //   this.select_what = 'Party Member';

  //   // qp = qp.set('project_id', this.project.id)
  //   this.adminService.getPartyMemberList(qp).subscribe(res => this.select_option_left = res.data || [])
  // }

  save() {
    if(!this.project.id) return alert('Project Required')
    //this will for tommorow
    // if(this.project.id){
    //   this.queryParams = this.queryParams.set('project_id', this.project.id);
    // }
    if(this.startDate){
      this.queryParams = this.queryParams.set('start_date', this.startDate.toISOString())
    }
    if(this.endDate){
      this.queryParams = this.queryParams.set('end_date', this.endDate.toISOString())
    }
    if(this.companyUser){
      this.queryParams = this.queryParams.set('party_company_user_id', this.companyUser.id)
    }
    this.activeModal.close({qp: this.queryParams, project: this.project});
  }


  dateRangeSet(e) {
    this.dateRange = e;

    const cDate = new Date();

    if (e == "all") {
      this.report_duration_title = "All Time";
      this.startDate = null;
      this.endDate = null;
    }
    this.endDate = null;
    if (e == "today") {
      this.startDate = cDate;
      this.endDate = cDate;
      this.report_duration_title = "Today";
    }
    if (e == "lastWeek") {
      this.report_duration_title = "Last Week (Mon-Sun)";
      const prevMonday = new Date();
      prevMonday.setDate(
        prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
      );
      const d = new Date().getDate() - prevMonday.getDate();
      d < 7 ? prevMonday.setDate(prevMonday.getDate() - 7) : prevMonday;
      this.startDate = prevMonday;
      this.endDate = new Date();
      this.endDate.setDate(prevMonday.getDate() + 6);
    }
    if (e == "lastMonth") {
      this.report_duration_title = "Last Month";
      const date = new Date();
      this.startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      this.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
    }

    if (e == "thisWeek") {
      this.report_duration_title = "This Week";
      const main = new Date();
      const d = main.getDay();
      const mon = new Date(main);
      // this.startDate = new Date(mon.setDate(mon.getDate() - d + 1));
      this.startDate = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
      this.endDate = new Date();
    }

    if (e == "thisMonth") {
      this.report_duration_title = "This Month";
      const date = new Date();
      this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.endDate = new Date();
    }

    if (this.startDate) {
      const date = new Date(this.startDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (this.endDate) {
      const date = new Date(this.endDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }

  selectOption(item: string) {
    this.selectedFilterSwitch = item;
  }

  clearFilter() {
    this.project = {} as Project;
    this.companyUser = {} as CompanyUser;

    this.dateRange = null;
    this.startDate = null;
    this.endDate = null;
    this.report_duration_title = 'All Time';
  }

  getProjects() {
    this.projects = this.localStorageService.get('projects') || [];
  }
  setProject(item: Project) {
    this.project = item || {};
  }

  getCompanyUsers() {
    this.companyUsers = this.localStorageService.get('companyUsers') || []
  }
  setCompanyUser(item: CompanyUser) {
    this.companyUser = item || {};
  }
}
