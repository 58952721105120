import {Component, OnInit, Input, AfterContentInit, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {NgbDatepickerConfig, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-util-components',
  templateUrl: './util-components.component.html',
  styleUrls: ['./util-components.component.css']
})
export class UtilComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
@Component({
  selector: 'mydatepicker',
  outputs: ['result'],
  template: '<input [ngModel] ="model" placeholder="{{placeholder}}" ngbDatepicker #dp="ngbDatepicker" (click)="dp.toggle()" (ngModelChange)="onDateChange($event)">'
})
export class MyDatePickerComponent implements AfterContentInit, OnChanges {
  @Input() data: any;
  @Input() placeholder: string;
  model: any;
  ph: string;
  result = new EventEmitter();
  constructor(
    public config: NgbDatepickerConfig
  ) {
    // customize default values of datepickers used by this component tree
    this.config.minDate = {year: 1980, month: 1, day: 1};
    this.config.maxDate = {year: 2030, month: 12, day: 31};
    /*config.outsideDays = 'hidden';*/
  }
  onDateChange(date: NgbDateStruct) {
    const output = new Date(date.year, date.month - 1, date.day);
    this.result.emit(output.toISOString());
  }
  ngAfterContentInit() {
    const inputDate = new Date(this.data);
    this.model = { year: inputDate.getFullYear(), month: inputDate.getMonth() + 1, day: inputDate.getDate()};
  }
  ngOnChanges(change: SimpleChanges) {
    const inputDate = new Date(this.data);
    this.model = { year: inputDate.getFullYear(), month: inputDate.getMonth() + 1, day: inputDate.getDate()};
  }
}
