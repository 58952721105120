/**
 * Created by tarun on 21/7/17.
 */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()
export class Config {
  // public socketAddress = 'http://127.0.0.1:7777';
  public socketAddress = environment.socketAddress;
  public apiV = 'v3';
  public userEndpoint = this.socketAddress + '/apis/' + this.apiV;
  // public reportEndpoint = 'http://127.0.0.1:7999/apis';
  public reportEndpoint = 'https://report.onsiteteams.com/apis';
  // public WSSocket = 'http://127.0.0.1:4000';
  public WSSocket = 'https://testweb.onsiteteams.com';
}
