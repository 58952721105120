<!-- <div class="bills">
  
   <div>
    <div class="px-2">
        <ng-container *ngFor="let item of billsData">
            <div class="d-flex flex-column my-3">
               <div class="d-flex justify-content-between align-items-center">
                <span class="f-10">
                    {{item.label}}
                </span>
        
                <div class="d-flex gap-3">
                 <div class="d-flex flex-column align-items-end">
                    <span class="disabled-font" style="font-size: 6px;">Paid</span>
                    <span class="f-10">{{item.paid}}</span>
                 </div>
                 <div class="d-flex flex-column align-items-end">
                    <span class="disabled-font" style="font-size: 6px;">Total</span>
                    <span class="f-10">{{item.total}}</span>
                 </div>
                </div>
               </div>
               <div class="bar">
                <div [style.width.%]="item.total?(item.paid / item.total) * 100:0" class="value-indicator"  ></div>
               </div>
            </div>
            </ng-container>
    </div>
   </div>
</div> -->

<ng-container *ngIf="!loading">
   <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [legend]="chartOptions.legend"
        [colors]="chartOptions.colors"
        [grid]="chartOptions.grid"
        [tooltip]="chartOptions.toolTip"
      ></apx-chart>
    </div>
</ng-container>

<ng-container *ngIf="loading">
   <div class="h-100 w-100 d-flex justify-content-center align-items-center">
      <span class="loader"></span>
   </div>
</ng-container>
 
