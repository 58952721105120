import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';

interface SequenceString {
  prefix:string;
  sequence:number;
}

@Component({
  selector: 'app-party-sequence-modal',
  templateUrl: './party-sequence-modal.component.html',
  styleUrls: ['./party-sequence-modal.component.css']
})
export class PartySequenceModalComponent {
  @Input() party_id:string
  @Input() partySequence = {} as SequenceString;

  constructor(
    private adminService:AdminService,
    public ngbActiveModal:NgbActiveModal
  ){
    //
  }
  

  save(){
   if(this.party_id){
    this.editSequence()
   }else{
    this.ngbActiveModal.close(this.partySequence)
   }
  }

  editSequence(){
     const data = {
      id:this.party_id,
      prefix:this.partySequence.prefix,
      sequence:this.partySequence.sequence
     }

     this.adminService.editPartyId(data).subscribe({
      next:(res)=>{
        this.ngbActiveModal.close(res)
      },
      error:(error)=>{
        console.error(error)
      }
     })
  }

}
