import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { CompanyUser } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-party-prefix-sequence',
  templateUrl: './party-prefix-sequence.component.html',
  styleUrls: ['./party-prefix-sequence.component.css']
})
export class PartyPrefixSequenceComponent {
  @Input() data: any;
  public partyCompanyUser = {} as CompanyUser;
  public loading = false;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private adminService: AdminService,
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    public notificationService: NotificationsService
  ) {
  }

  ngOnInit(): void {
    this.partyCompanyUser = {...this.data?.partyCompanyUser} || {};
  }

  closePopUp() {
    this.ngbActiveModal.close();
  }

  save() {
    if(!this.partyCompanyUser.prefix || !this.partyCompanyUser.sequence){
      return this.notificationService.initiate({title:'Please enter prefix and number', type:toastTypes.error});
    }
    const payload = {
      id: this.partyCompanyUser.id,
      prefix: this.partyCompanyUser.prefix,
      sequence: this.partyCompanyUser.sequence
    }
    this.adminService.editPartyId(payload).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.activeModal.close(res);
      },
      error: (err) => {
        this.loading = false;
      }
    })
  }
}