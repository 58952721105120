import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { EquipmentStock } from 'src/app/interfaces/interfaces';
import { DynamicNumberPipe } from './dynamic-number.pipe';
@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'readingFormatter'
})
export class ReadingFormatterPipe implements PipeTransform {
  constructor(
    public authService:AuthService,
    private dynamicNumber:DynamicNumberPipe
  ){

  }

  transform(equipmentStock: EquipmentStock, ...args: unknown[]): unknown {
    let unit = equipmentStock.monkey_patch_equipment.unit || '';
    let measurement_type = equipmentStock.monkey_patch_equipment.measurement_type;
    let equipmentUsed = equipmentStock.monkey_patch_recent_equipment_used_entry;
    if(equipmentUsed.id){
      switch(measurement_type){
        case 'meterreading':
          if(equipmentUsed.total_used >=0 && equipmentUsed.reading_measurement.stop_at){
            return `${this.dynamicNumber.transform(equipmentUsed.reading_measurement.start_at, {digit:6} )} ${unit} - ${this.dynamicNumber.transform(equipmentUsed.reading_measurement.stop_at || equipmentUsed.reading_measurement.start_at, {digit:6} )} ${unit}`;
          }else{
            return `${equipmentUsed.reading_measurement.start_at} ${unit} - Running`;
          }
        case 'clockbased': 
          if(equipmentUsed.total_used >= 0 && equipmentUsed.time_measurement.stop_time !== '0001-01-01T00:00:00Z'){
            return `${new Date(equipmentUsed.time_measurement.start_time).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})} - ${new Date(equipmentUsed.time_measurement.stop_time).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})}`;
          }else{
            return `${new Date(equipmentUsed.time_measurement.start_time).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})} - Running`;
          }
        case 'workbased':
          return `${this.dynamicNumber.transform(equipmentStock.monkey_patch_used, {digit:6} )} ${unit}`;
        default:
          return '--';
      }
    }else{
      return '--';
    }
  }

}
