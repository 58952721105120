<div class="server-down-modal-bg">
  <div class="server-down-modal-card">
    <ng-container *ngIf="!loading">
      <div class="server-card-header">
        <img
          height="150px"
          src="../../../../../assets/images/server-down.jpg"
          alt="server"
        />
      </div>
      <span class="server-card-title">Server is under maintenance</span>
      <span class="server-card-body">Please try after 8am...</span>
      <button (click)="handleRefresh()" class="retry-btn">Retry ></button>
    </ng-container>
    <ng-container *ngIf="loading">
      <span class="loader"></span>
    </ng-container>
  </div>
</div>
