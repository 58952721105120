import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorService } from 'src/app/shared/services/validator.service';

@Component({
  selector: 'app-custom-date-modal',
  templateUrl: './custom-date-modal.component.html',
  styleUrls: ['./custom-date-modal.component.css']
})
export class CustomDateModalComponent {
  @Input() source: string;
  @ViewChild('dp') datepicker: NgbDatepicker;
  public model: NgbDateStruct;

  constructor(
    private ngbActiveModal:NgbActiveModal,
    private validatorService: ValidatorService,
    private cdr: ChangeDetectorRef
  ){
    //
  }
  ngOnInit(): void {
    if(this.source && this.source !== '0001-01-01T00:00:00Z'){
      const newData = new Date(this.source);
      this.model = new NgbDate(newData.getFullYear(), newData.getMonth() + 1, newData.getDate());
    }
  }

  ngAfterViewInit(){
    if(this.source ){
      this.datepicker.navigateTo({
        year: this.model.year,
        month: this.model.month
      });
      this.cdr.detectChanges();
    }
  }

 

  setDate(){
    this.datepicker.navigateTo({
      year: this.model.year,
      month: this.model.month
    });
    const selectedDate = this.validatorService.dateToUtc(this.model.day, this.model.month, this.model.year, 23, 59, 59, 999);
    this.ngbActiveModal.close({selectedDate})
  }
}
