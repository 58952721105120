<div class="modal-wrapper px-3 py-3 w-100">
 <div style="margin-bottom: 16px; padding-bottom: 6px;" class="w-100 d-flex justify-content-between align-items-center  border-bottom">
    <span class="f-14">
       {{this.data.purchaseOrder?.id ?'Update Purchase Order': ' Create Purchase Order'}}
    </span>

    <button (click)="activeModal.dismiss()" type="button" class="btn icon-btn">
        <div>
            <ng-icon name="featherX"></ng-icon>
        </div> 
    </button>
 </div>
 <div class="d-flex flex-column gap-3">
     <div class="d-flex justify-content-end">
        <div class="f-14 datepicker-hide-input calender d-flex align-items-center justify-items-center">
            <svg (click)="d.toggle()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                    stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3 10H21" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <input class="pointer" style="background-color: #fff" placeholder="Select Date" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" />
            <svg (click)="d.toggle()" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
    <app-party-input (onCompanyUserSelect)="setCompanyUser($event)" [parentComponent]="{pageFrom:'purchase-order'}" [required]="true" #companyUser></app-party-input>
    <app-project-input (onCompanyProjectSelect)="setCompanyProject($event)"  [required]="true" #companyProject></app-project-input>
    <div class="d-flex justify-content-end">
        <button (click)="save()" type="button" class="save btn f-14">{{this.data.purchaseOrder?.id?'Update':'Create'}}</button>
    </div>
 </div>

 

</div>