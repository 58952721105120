import { Component, Input } from '@angular/core';
export interface ButtonState{
  text:string,
  smText: string
}
@Component({
  selector: 'app-hero-add-button',
  templateUrl: './hero-add-button.component.html',
  styleUrls: ['./hero-add-button.component.css']
})
export class HeroAddButtonComponent {
@Input() buttonState = {} as ButtonState;
}
