import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalLimit]'
})
export class DecimalLimitDirective {
  @Input() decimalLimit = 2; // Default to allow 2 digits after the decimal point

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    console.log("onKeyDown ", event.key, value);
    console.log("onKeyDown", inputElement.selectionStart, inputElement.selectionEnd);
    // const regex = new RegExp(`^\\d*(\\.\\d{0,${this.decimalLimit}})?$`);

    const numberStrWithoutLeadingZero = parseFloat(value).toString();
    const parts = numberStrWithoutLeadingZero.split(".");
    const decimalLength = parts[1] ? parts[1].length : 0;
  
    if(event.key !== 'Backspace' && (decimalLength === this.decimalLimit && inputElement.selectionStart >= numberStrWithoutLeadingZero.indexOf(".")) && inputElement.selectionStart === inputElement.selectionEnd){
        event.preventDefault();
    }
  }
}
