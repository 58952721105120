<app-logo-loader [state]="loading"></app-logo-loader>
<div class="w-100 p-3">
    <div class="d-flex justify-content-between align-items-center border-bottom">
        <span style="font-weight: 500;" class="f-16">Edit Party ID</span>
         <button (click)="closePopUp()" class="btn disabled-font outline-btn">
            <div>
                <ng-icon name="featherX" ></ng-icon>
            </div>
         </button>
    </div>
    <div class="w-100 d-flex my-3 gap-2">
        <div class="custom-input w-50">
            <label for="date">Prefix</label>
            <input [(ngModel)]="partyCompanyUser.prefix" type="text">
        </div>
        <div class="custom-input w-50">
            <label for="date">Number</label>
            <input [(ngModel)]="partyCompanyUser.sequence"  type="number">
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button (click)="save()" class="btn solid-btn f-14">Save</button>
    </div>
</div>
