<div style="height: 100dvh;" class="canvas-heigh">
  <app-logo-loader [state]="loading"></app-logo-loader>
  <div class="offcanvas-header d-flex align-items-center">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <div class="pointer m-0 p-0" (click)="this.canvas.dismiss('cancel')">
        <app-back-button></app-back-button>
      </div>
      <div class="canvas-title">
        <span>Debit Note</span>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="canvas-cancel-btn" (click)="this.canvas.dismiss('Cancel')">
        Cancel
      </div>
      <div class="canvas-save-btn" (click)="save()">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div style=" height: calc(100% - 66px); overflow-y: auto;
        scroll-behavior: smooth;
        overscroll-behavior: contain;" class="canvas-body ">
    <div class="h-100" style="width: 100%;background-color: #fff;">
      <div class="d-flex flex-column gap-3" style="padding: 32px 32px 0px 32px">
        <div class="d-flex align-items-center">
        <div style="flex: 1"></div>
        <div class="f-14 datepicker-hide-input calender">
          <svg (click)="d.toggle()" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
              stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 2V6" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 10H21" stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <input class="pointer" style="background-color: #fff" placeholder="Select Date" [(ngModel)]="model"
            ngbDatepicker #d="ngbDatepicker" />
          <svg (click)="d.toggle()" width="14" height="8" viewBox="0 0 14 8" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>

      <app-party-input [parentComponent]="{ pageFrom: 'materialPurchase' }"
        (onCompanyUserSelect)="setCompanyUser($event)" #companyUser></app-party-input>
      <div class="d-flex w-100 justify-content-end align-self-center my-2 f-14" [ngClass]="{'justify-content-between': debitNoteItemCount > 0}">
        <span class="disabled-font" *ngIf="debitNoteItemCount > 0">Items({{debitNoteItemCount}})</span>
        <span (click)="addDebiteNoteItem()" class="pointer text-end" style="color: #5F44CE;">+ New Item</span>
      </div>
      </div>
      <div class="item-section" *ngIf="debitNoteItemCount > 0">
      <div class="item-section-body ">
          <div class="items-cards d-flex flex-column justify-content-between">
            <div>
              <ng-container *ngFor="let debitNoteItem of debitNoteItems; let i = index">
                <div class="item-card" *ngIf="!debitNoteItem.delete">
                  <div style="width: 100%;">
                    <div style="color: #1A0C52;" class="d-flex justify-content-between my-1">
                     <div class="d-flex justify-content-center align-items-center gap-2">
                      <div (click)="addDebiteNoteItem(i)"><ng-icon name="featherEdit"></ng-icon></div>
                      <span class="f-14">{{debitNoteItem.name}}</span>
                     </div>
                     
                      <div class="d-flex flex-column align-items-end">
                        <span class="f-14">{{multiply(debitNoteItem.unit_price,debitNoteItem.quantity) | dynamicNumber: { currency:1} }}</span>
                        <span style="color: rgb(148, 148, 148);" class="fs-8">{{authService.session.country.tax_value_display_name?authService.session.country.tax_value_display_name:'Tax'}} +{{debitNoteItem.gst_percent}}%</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between gap-4 my-2">
                        <div class="custom-input w-50">
                          <input [(ngModel)]="debitNoteItem.unit_price" type="text" id="unit-rate" (input)="debitNoteItem.unit_price = validatorService.numberFormat($event, { type: 'number', decimal: true, digit: 6}); updateDebitTotals()">
                          <label for="unit-rate">unit rate ({{authService.session.company.currency || '₹'}})</label>
                        </div>
                        
                      <div class="custom-input w-50">
                        <input [(ngModel)]="debitNoteItem.quantity" type="text" id="invoice-qnt" (input)="debitNoteItem.quantity = validatorService.numberFormat($event, { type: 'number', decimal: true, digit: 6}); updateDebitTotals()">
                        <label for="invoice-qnt">Invoice Qty. ({{debitNoteItem.unit}})</label>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
      </div>
      </div>
      <div class="d-flex flex-column gap-3" style="padding: 32px">
      <div class="d-flex" *ngIf="debitNoteItemCount == 0">
        <div class="flex-shrink-1 align-self-center f-16">Total Amount</div>
        <div class="custom-input ms-auto" style="position: relative; width: 40% !important">
          <label>Amount</label>
          <input type="text" name="total_amount" [ngModel]="debitNote.amount | dynamicNumber: {digit: 6}" (input)="debitNote.amount = validatorService.numberFormat($event, { type: 'number', decimal: true, digit: 6});" />
        </div>
      </div>
      <ng-container *ngIf="debitNoteItemCount > 0">
        <div class="d-flex justify-content-between">
          <span class="f-14">Item Subtotal</span>
          <span class="f-14">{{debitNote.work_amount | dynamicNumber: {currency: 1} }}</span>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <span class="f-14">Tax</span>
          <span class="f-14">{{debitNote.gst_amount | dynamicNumber: {currency: 1}  }}</span>
        </div>

        <div class="d-flex justify-content-between border-top border-bottom py-3">
          <span class="f-14">Total Amount</span>
          <span class="f-14">{{debitNote.amount | dynamicNumber: {currency: 1}  }}</span>
        </div>
      </ng-container>


      <div *ngIf="addReference" class="d-flex align-items-center gap-2">
        <div (click)="addReference = false; debitNote.reference_number = ''" class="close-circle-btn pointer">
          <img src="../../../../assets/svg/cross.svg" alt="cross-btn" />
        </div>

        <div class="custom-input">
          <input type="text" id="hsn" [(ngModel)]="debitNote.reference_number" />
          <label for="hsn">Reference No</label>
        </div>
      </div>

      <div [ngClass]="addNote ? 'd-flex' : 'd-none'" style="position: relative; margin-top: 20px;">
        <textarea class="note_component" [(ngModel)]="debitNote.notes">
          </textarea>
        <div class="note_component_abs_title">Note</div>
      </div>
      <div [ngClass]="addNote ? 'd-flex' : 'd-none'" class="hstack align-items-center gap-2">
        <span class="pointer" (click)="addNote = false">
          <ng-icon name="featherXCircle" class="disabled-font"></ng-icon>
        </span>
        <div class="flex-shrink-1 align-self-center f-16 pointer">Remove</div>
      </div>
      <div style="margin-top: 16px; color: #5F44CE;" class="d-flex flex-column align-items-end gap-3 pointer">
        <span (click)="addReference = true" *ngIf="!addReference" class="f-12 text-buttons">+ Reference No</span>
        <span (click)="addNote = true" *ngIf="!addNote" class="f-12 text-buttons">+ Notes</span>
      </div>
      <app-attachment-view folderName="material" #child></app-attachment-view>
      </div>
    </div>
  </div>
</div>