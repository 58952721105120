import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Profile, Member, Project } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from '../../services/util.service';
import { HttpParams } from "@angular/common/http";
@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit, OnDestroy {
  public project = {} as Project;
  public primaryProfile = {} as Profile;
  public member = [] as Member[];

  @Input() config = {
    text: 'Home',
    url: '/'
  } as any;
  public topBarLevel = '';
  constructor(
    private _location: Location,
    private authService: AuthService,
    public utilService : UtilService,
  ) {
    // this.topBarLevel = 'company';
    // this.utilService.topBarLevel.subscribe
    // this.utilService.topBarLevel.subscribe(res => {
    //   this.topBarLevel=res;
    //   console.log(this.topBarLevel)
    // });
    this.primaryProfile = this.authService.getPrimaryProfile() as Profile || {} as Profile;
   }

  ngOnInit() {
    this.project = this.authService.session.project || ({} as Project);
    let qp = new HttpParams();
      qp = qp.set("project_id", this.project.id);
      // this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      //   this.member = res.data || ([] as Member);
      // });
  }
  ngOnDestroy() {
    this.config = {
      text: 'Home',
      url: '/'
    } as any;
  }
  goBack() {
    this._location.back();
  }
}
