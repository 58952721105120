<!-- <div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{data.modalTitle}}</h4>
</div> -->

<div class="modal-body d-flex justify-content-center align-items-center py-5 px-3">
  <p class="m-0 f-18" style="font-weight:400;  color: #61636F; text-align:center ">{{data.modalContent}}</p>
</div>
<div class="modal-footer d-flex justify-content-center align-items-center py-3">
  <!-- <button type="button" class="btn btn-outline-secondary" (click)="clickMe()">dncjn</button> -->
  <button style="border:none" type="button" class="btn btn-outline-secondary modal-cancel-button py-3"
    (click)="cancelModal()">Cancel</button>
  <button style="border-radius: 8px; width: 157px; background-color: #DC1717;" type="button" ngbAutofocus
    class="btn btn-danger" (click)="goNext()">Ok</button>
</div>