import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Socket, io } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupChatService {
  private socket: Socket;
  private company_user_id: string;
  private selectedChatGroupId: string;
  public unreadMessageCountMap = new Map<string, number>()
  public unreadMessageCountSubject = new BehaviorSubject<boolean>(false);
  public unreadMessageCountObserver = this.unreadMessageCountSubject.asObservable();
  public newMessageObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
  ) {
    // this.socket = io('http://127.0.0.1:8080');
    
    this.initilizeSocket();
    this.authService.sessionObserver.subscribe(res=>{
      this.initilizeSocket();
    })
  }

  initilizeSocket() {
    if (!this.socket && this.authService.session.token 
      && this.authService.session.company.monkey_patch_my_company_user.id 
      && this.company_user_id != this.authService.session.company.monkey_patch_my_company_user.id) {
        this.closeSocket();
        this.socket = io(environment.chatSocketServer, {
          path: "/socket.io/",
          transports: ["websocket"],
        });
        this.company_user_id = this.authService.session.company.monkey_patch_my_company_user.id;
        this.registerUser(this.company_user_id, () => {
          this.startListening();
        }
      );
    }
  }

  public startListening() {
      this.socket.on("message", (message) => {
        if ((message?.sender_type === "user") && (message.chat_group_id !== this.selectedChatGroupId) && (message.creator_company_user_id !== this.company_user_id)) {
          if (this.unreadMessageCountMap.has(message.chat_group_id)) {
            this.unreadMessageCountMap.set(message.chat_group_id, this.unreadMessageCountMap.get(message.chat_group_id) + 1);
          } else {
            this.unreadMessageCountMap.set(message.chat_group_id, 1);
          }
          this.unreadMessageCountSubject.next(true);
        }
        this.newMessageObservable.next(message);
      });
  }

  public stopListening() {}

  public getNewMessageObservable(): Observable<any> {
    return this.newMessageObservable.asObservable();
  }

  public getUnreadMessageCountObserver(): Observable<boolean> {
    return this.unreadMessageCountObserver;
  }

  // Register user with socket
  public registerUser(company_user_id: string, callback: () => void): void {
    this.socket.emit("register", { company_user_id }, callback);
  }

  // Disconnect from socket
  public closeSocket(): void {
    if (!this.socket) return;
    this.company_user_id = "";
    this.unreadMessageCountMap.clear();
    this.socket.disconnect();
    this.socket = null;
  }

  public setSelectedGroup(group_id?: string): void {
    this.selectedChatGroupId = group_id;
    this.unreadMessageCountMap.set(group_id, 0);
    this.unreadMessageCountSubject.next(true);
  }

  public getUnreadMessageCount(group_id?: string): number {
    if (!group_id) {
      var returnValue = 0;
      this.unreadMessageCountMap.forEach((value, key) => {
        returnValue += value;
      });
      return returnValue;
    } else if (this.unreadMessageCountMap.has(group_id)) {
      return this.unreadMessageCountMap.get(group_id);
    }
    
    return 0;
  }

  // Emit events to the server
  // public emit(eventName: string, data: any, callback?: Function): void {
  //   this.socket.emit(eventName, data, (response) => {
  //     if (callback) {
  //       callback(response);
  //     }
  //   });
  // }

  // Join a specific chat group
  // public joinRoom(chat_group_id: string, chat_type: string, company_user_id: string): void {
  //   this.socket.emit("joinRoom", { chat_group_id, chat_type, company_user_id });
  // }
}