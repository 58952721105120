import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum toastTypes {
  error,
  success,
  delete,
  warning
}

export interface ToastData {
  title: string;
  content?: string;
  show?: boolean;
  type: toastTypes;
}
@Injectable()
export class NotificationsService {
  // constructor() { }
  public data: ToastData;
  public open = new Subject<ToastData>();

  initiate(new_data: ToastData) {
    this.data = { ...new_data, show: true }
    this.open.next(this.data)
  }

  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data)
  }


  // public toasterBehavior = new BehaviorSubject({});
  // public toasterObservable = this.toasterBehavior.asObservable();
}
