import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/shared/services/util.service';

declare var download;

interface parent  {
  imagesArray?:string[],
  imageIndex?:number
}
@Component({
  selector: 'app-image-slider-modal',
  templateUrl: './image-slider-modal.component.html',
  styleUrls: ['./image-slider-modal.component.css']
})
export class ImageSliderModalComponent implements OnInit {
  @Input() public parent: parent;
  public images:string[];
  public index:number;
  constructor(
    private ngbCarouselConfig: NgbCarouselConfig,
    public activeModal: NgbActiveModal,
    private utilService:UtilService
  ) {
    this.ngbCarouselConfig.showNavigationArrows = true;
    this.ngbCarouselConfig.showNavigationIndicators = true;
  }
  ngOnInit() {
  this.images = this.parent?.imagesArray;
  this.index = this.parent?.imageIndex;
 
  }
  dismiss(str) {
    this.activeModal.dismiss('Hi');
  }
  downloadFile(url:string) {
    
    if (url) {
      const request = new XMLHttpRequest();
      request.open('GET', url);
      request.send();
      request.responseType = 'blob';
      request.onreadystatechange = function(){
       if(request.readyState==4 && request.status == 200){
        const imgUrl = window.URL.createObjectURL(request.response);
        const anchor = document.createElement("a");
        anchor.href = imgUrl;
        anchor.download = imgUrl.split('/').pop()+'.'+ url.split('.').pop();
        document.body.appendChild(anchor);
        anchor.click();
       }
      }
    }
  }

  previousImage(i:number){
    if(i>0){
      this.index = this.index - 1;
    }
  }

  nextImage(i:number){
    if(i < this.images.length - 1){
     this.index = this.index + 1
    }
  }

  fileExtensionExtract(fileName){
    const srcPath = '../../../../../assets/svg/';
    if(this.utilService.fileExtensionExtract(fileName).isImage){
      return this.utilService.fileExtensionExtract(fileName).file;
    }else{
      return srcPath+this.utilService.fileExtensionExtract(fileName).file
    }
  }

  isImage(fileName){
    if(this.utilService.fileExtensionExtract(fileName).isImage){
      return true;
    }else{
      return false;
    }
  }

  onImageError(img_with_index): void {
    // Check if the URL is already using the CDN to avoid infinite loops
    if (!img_with_index('.cdn')) {
      img_with_index = this.utilService.addCdnInDomain(img_with_index)
    }
  }
}
