import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { BankAccount, CompanyBank } from 'src/app/interfaces/interfaces';
import { UtilService } from '../../services/util.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AddNewAccountComponent } from 'src/app/company/components/add-new-account/add-new-account.component';
@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css']
})
export class BankAccountsComponent implements OnInit {
  @Input() company_id:string;
  @Input() bankAccount:BankAccount;
  @Output() bankAccountUpdated = new EventEmitter<CompanyBank>();
  @Output() bankAccountRemoved = new EventEmitter<any>();

  public busy = false;
  public companyBankAccounts = [] as CompanyBank[];
  public companyBankAccount = {} as BankAccount;
  public valueStack = {
    page: { next_url: "" },
  };
  constructor(
    private authService:AuthService,
    private adminService:AdminService,
    private utilService:UtilService,
    private ngbOffCanvasService:NgbOffcanvas
  ){

  }

  ngOnInit(): void {
    this.getBanksList();
    this.companyBankAccount = this.bankAccount;
    if (!this.companyBankAccount.monkey_patch_logo_url && this.companyBankAccount?.bank_code) {
      const bankCode = this.companyBankAccount?.bank_code.substring(0, 4);
      this.companyBankAccount.monkey_patch_logo_url = `https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/${bankCode.toUpperCase()}_LOGO.jpg`;
    }
  }


  getBanksList() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.authService.session.company.id);
    this.adminService.listCompanyBank(qp).subscribe({
      next: (res) => {
        this.valueStack = res;
        this.companyBankAccounts = res.data || [] as CompanyBank[];
        // const company_BankAccount = this.companyBankAccounts.filter(obj=>obj.id === this.bankAccount_id);
        // this.companyBankAccount = company_BankAccount[0];
      },
      error: (err) => {
        console.error(err)
      }
    })
  }



  selectBankAccount(bankAccount:CompanyBank){
     this.companyBankAccount = bankAccount.monkey_patch_bank_account;
     this.bankAccountUpdated.emit(bankAccount);
  }

  removeBankAccount(){
    this.companyBankAccount = {};
    this.bankAccountRemoved.emit({});
  }

  AddNewCompanyBankAccount(){
    
    const ngbOffCanvasRef = this.ngbOffCanvasService.open(AddNewAccountComponent,{
      position:'end',
      panelClass: "offcanvas-sm",
    });
    
    ngbOffCanvasRef.componentInstance.parent ={
      hasRequestType: 'add',
    }

    ngbOffCanvasRef.result.then(onFullfilled=>{
      this.selectBankAccount(onFullfilled);
      this.getBanksList();
    }, onReject=>{
      //
    })

  }


  onScroll() {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
          this.valueStack = res;
          this.companyBankAccounts = this.companyBankAccounts.concat(res.data);
          this.busy = false;
        },
        (err) => {
        
          this.busy = false;
          this.valueStack = {
            page: { next_url: "" },
          };
        }
      );
    }
  }


}
