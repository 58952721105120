<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <app-back-button></app-back-button>
      <div class="canvas-title">
        <span style="font-weight: 700; font-size: 14px; line-height: 21px">Add Cost Code</span>
      </div>
    </div>
    <div class="d-flex">
      <div (click)="saveNewCostCode()" class="canvas-save-btn">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
  <div class="canvas-body gap-3">

    <div class="custom-input">
      <input id="cost-code-input" type="text" [(ngModel)]="subCategory.text_en" placeholder="Enter Cost Code">
    </div>

    <div class="d-flex align-items-center gap-3">
      <div><input type="checkbox" (change)="toggleCheckbox($event)" [checked]="subCostCode"> </div>
      <span class="f-10">Make this a sub-cost code</span>
    </div>

    <ng-container *ngIf="subCostCode">
      <div (click)="selectParentCostCode()" style="cursor: pointer;" class="custom-div mt-3">
        <label for="cost-code-parent">Parent</label>
        <div style="width: 100%;" class="d-flex justify-content-between align-items-center">
          <span class="f-14 disabled-font">{{parentSubCategory.text_en?parentSubCategory.text_en:'Select Cost
            Code'}}</span>
          <div style="margin-right: 4px;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 9L12 15L18 9" stroke="black" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>