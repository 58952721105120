<div class="top-bar-box" >
  <div class="row m-0 p-0">
    <div class="col-3 d-flex align-items-center p-0">
      <svg class="me-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 5.49967L7 0.833008L13 5.49967V12.833C13 13.1866 12.8595 13.5258 12.6095 13.7758C12.3594 14.0259 12.0203 14.1663 11.6667 14.1663H2.33333C1.97971 14.1663 1.64057 14.0259 1.39052 13.7758C1.14048 13.5258 1 13.1866 1 12.833V5.49967Z"
          stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span class="breadcrumb-text" routerLink="/../company/dashboard/home">
        Home
      </span>
    </div>
    <div class="col-2 d-flex justify-content-between align-items-center">
      <!-- <div class="avatars">
        <ng-container *ngFor="let item of member; let i = index">
          <ng-containe *ngIf="item.role == 'manager' || item.role == 'admin'">
            <ng-template #i>{{item.monkey_patch_company_user.name}}</ng-template>
            <a *ngIf="item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
              <img class="avatar" [src]="item.monkey_patch_company_user.profile_pic">
            </a>
            <a *ngIf="!item.monkey_patch_company_user.profile_pic" [ngbTooltip]="i">
              <div class="no-avatar">{{item.monkey_patch_company_user.name.charAt(0)}}</div>
            </a>
          </ng-containe>
        </ng-container>
      </div> -->
    </div>
    <div class="col-7">
      <app-user></app-user>
    </div>
  </div>
</div>

<!--
<div class="top-bar-box" >
  <div class="row m-0 p-0">
    <div class="col-3 d-flex align-items-center p-0">
      <svg class="me-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 5.49967L7 0.833008L13 5.49967V12.833C13 13.1866 12.8595 13.5258 12.6095 13.7758C12.3594 14.0259 12.0203 14.1663 11.6667 14.1663H2.33333C1.97971 14.1663 1.64057 14.0259 1.39052 13.7758C1.14048 13.5258 1 13.1866 1 12.833V5.49967Z"
          stroke="#090226" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span class="breadcrumb-text">
        Project
      </span>
    </div>
    <div class="col-2 d-flex justify-content-between align-items-center">
    </div>
    <div class="col-7">
      <app-user></app-user>
    </div>
  </div>
</div> -->
