<div class="b_slideBox" *ngIf="bannerData.show==true">
  <div class="b_slideImageContainer">
    <img class="b_slideImage" src="{{bannerData.img}}" />
  </div>
  <div class="b_slideContent">
    <div class="b_slideTitle">
      <div class="hidden-xs" [innerHTML]="bannerData.title"></div>
      <img class="show-xs b_slideImage" src="{{bannerData.img}}" />
    </div>
    <div class="b_slideSubTitle text-centre">
        <div [innerHTML]="bannerData.desc"></div>
    </div>
    <div>
      <div class="b_slideTimer">
       <app-timer [config]="bannerData"></app-timer>
      </div>
    </div>
  </div>
</div>
