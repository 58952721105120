
  <div

    class="toast-body"
    [@openClose]="
      notificationService.data && notificationStatus ? 'open' : 'closed'
    "
  >
  <ng-container *ngIf="notificationStatus">
    <div class="hstack">
      <div *ngIf="notificationType === '1'" class="toast-svg">
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49 29C49 34.4509 46.8194 39.3925 43.2829 43C39.653 46.7027 34.5948 49 29 49C23.8776 49 19.205 47.0743 15.6667 43.9074C11.575 40.2452 9 34.9233 9 29C9 23.7491 11.0235 18.9709 14.3333 15.4025C17.986 11.4645 23.2052 9 29 9C34.2849 9 39.091 11.0498 42.6667 14.3978C46.5644 18.0472 49 23.2392 49 29Z"
            fill="#248B00"
          />
          <path
            opacity="0.2"
            d="M54 29C54 35.8136 51.2742 41.9906 46.8536 46.5C42.3163 51.1284 35.9935 54 29 54C22.597 54 16.7563 51.5929 12.3333 47.6342C7.21876 43.0565 4 36.4042 4 29C4 22.4364 6.52942 16.4636 10.6667 12.0031C15.2325 7.08059 21.7565 4 29 4C35.6061 4 41.6137 6.56227 46.0833 10.7472C50.9554 15.3091 54 21.799 54 29Z"
            fill="#248B00"
          />
          <path
            opacity="0.2"
            d="M58 29C58 36.9038 54.8381 44.0691 49.7102 49.3C44.4469 54.6689 37.1124 58 29 58C21.5726 58 14.7973 55.2077 9.66667 50.6157C3.73376 45.3056 0 37.5888 0 29C0 21.3862 2.93412 14.4577 7.73333 9.2836C13.0297 3.57349 20.5975 0 29 0C36.6631 0 43.6319 2.97224 48.8167 7.82678C54.4683 13.1185 58 20.6468 58 29Z"
            fill="#248B00"
          />
          <path
            d="M35.6654 24L26.4987 33.1667L22.332 29"
            stroke="#FCFCFC"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div *ngIf="notificationType === '0' || notificationType === '3'" class="toast-svg">
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49 29C49 34.4509 46.8194 39.3925 43.2829 43C39.653 46.7027 34.5948 49 29 49C23.8776 49 19.205 47.0743 15.6667 43.9074C11.575 40.2452 9 34.9233 9 29C9 23.7491 11.0235 18.9709 14.3333 15.4025C17.986 11.4645 23.2052 9 29 9C34.2849 9 39.091 11.0498 42.6667 14.3978C46.5644 18.0472 49 23.2392 49 29Z"
            fill="#E5B300"
          />
          <path
            opacity="0.2"
            d="M54 29C54 35.8136 51.2742 41.9906 46.8536 46.5C42.3163 51.1284 35.9935 54 29 54C22.597 54 16.7563 51.5929 12.3333 47.6342C7.21876 43.0565 4 36.4042 4 29C4 22.4364 6.52942 16.4636 10.6667 12.0031C15.2325 7.08059 21.7565 4 29 4C35.6061 4 41.6137 6.56227 46.0833 10.7472C50.9554 15.309 54 21.799 54 29Z"
            fill="#E5B300"
          />
          <path
            opacity="0.2"
            d="M58 29C58 36.9038 54.8381 44.0691 49.7102 49.3C44.4469 54.6689 37.1124 58 29 58C21.5726 58 14.7973 55.2077 9.66667 50.6157C3.73376 45.3056 0 37.5888 0 29C0 21.3862 2.93412 14.4577 7.73333 9.2836C13.0297 3.57349 20.5975 0 29 0C36.6631 0 43.6319 2.97224 48.8167 7.82678C54.4683 13.1185 58 20.6468 58 29Z"
            fill="#E5B300"
          />
          <g clip-path="url(#clip0_3709_266979)">
            <path
              d="M29.0013 37.3332C33.6037 37.3332 37.3346 33.6022 37.3346 28.9998C37.3346 24.3975 33.6037 20.6665 29.0013 20.6665C24.3989 20.6665 20.668 24.3975 20.668 28.9998C20.668 33.6022 24.3989 37.3332 29.0013 37.3332Z"
              stroke="#685100"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.3346 32.3332C32.3346 32.3332 31.0846 30.6665 29.0013 30.6665C26.918 30.6665 25.668 32.3332 25.668 32.3332"
              stroke="#685100"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M26.5 26.5H26.5083"
              stroke="#685100"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.5 26.5H31.5083"
              stroke="#685100"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3709_266979">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(19 19)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div *ngIf="notificationType === '2'" class="toast-svg">
        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M49 29C49 34.4509 46.8194 39.3925 43.2829 43C39.653 46.7027 34.5948 49 29 49C23.8776 49 19.205 47.0743 15.6667 43.9074C11.575 40.2452 9 34.9233 9 29C9 23.7491 11.0235 18.9709 14.3333 15.4025C17.986 11.4645 23.2052 9 29 9C34.2849 9 39.091 11.0498 42.6667 14.3978C46.5644 18.0472 49 23.2392 49 29Z" fill="#248B00"/>
          <path opacity="0.2" d="M54 29C54 35.8136 51.2742 41.9906 46.8536 46.5C42.3163 51.1284 35.9935 54 29 54C22.597 54 16.7563 51.5929 12.3333 47.6342C7.21876 43.0565 4 36.4042 4 29C4 22.4364 6.52942 16.4636 10.6667 12.0031C15.2325 7.08059 21.7565 4 29 4C35.6061 4 41.6137 6.56227 46.0833 10.7472C50.9554 15.309 54 21.799 54 29Z" fill="#248B00"/>
          <path opacity="0.2" d="M58 29C58 36.9038 54.8381 44.0691 49.7102 49.3C44.4469 54.6689 37.1124 58 29 58C21.5726 58 14.7973 55.2077 9.66667 50.6157C3.73376 45.3056 0 37.5888 0 29C0 21.3862 2.93412 14.4577 7.73333 9.2836C13.0297 3.57349 20.5975 0 29 0C36.6631 0 43.6319 2.97224 48.8167 7.82678C54.4683 13.1185 58 20.6468 58 29Z" fill="#248B00"/>
          <path d="M21.5 24H23.1667H36.5" stroke="#FCFCFC" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25.668 23.9998V22.3332C25.668 21.8911 25.8436 21.4672 26.1561 21.1547C26.4687 20.8421 26.8926 20.6665 27.3346 20.6665H30.668C31.11 20.6665 31.5339 20.8421 31.8465 21.1547C32.159 21.4672 32.3346 21.8911 32.3346 22.3332V23.9998M34.8346 23.9998V35.6665C34.8346 36.1085 34.659 36.5325 34.3465 36.845C34.0339 37.1576 33.61 37.3332 33.168 37.3332H24.8346C24.3926 37.3332 23.9687 37.1576 23.6561 36.845C23.3436 36.5325 23.168 36.1085 23.168 35.6665V23.9998H34.8346Z" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M27.332 28.1665V33.1665" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.668 28.1665V33.1665" stroke="#FCFCFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

      </div>
      <div class="toast-content">
        <div class="vstack">
          <div *ngIf="notificationSub" class="sub-alert">{{notificationSub}}</div>
        <ng-container *ngIf="notificationSub">
          <hr />
        </ng-container>
          <div class="main-alert">{{notificationTitle}}</div>
        </div>
      </div>
    </div>
  </ng-container>
  </div>

